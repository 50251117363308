import Cookies from 'js-cookie'

const TokenKey = 'Auth-Token'
const UserCodeKey = 'Auth-User'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function getUserCode() {
  return Cookies.get(UserCodeKey) || ''
}

export function setUserCode(userCode) {
  return Cookies.set(UserCodeKey, userCode)
}

export function removeToken() {
  Cookies.remove(UserCodeKey)
  return Cookies.remove(TokenKey)
}
