<!--
  @文件名:    须知
  @file:    notice
  @author:
  @date:    2021/4/29 0029
  @time:    10:43
  @require:
-->
<template>
  <div class="Notice">
    <app-breadcrumb/>
    <div>
      <el-form style="padding: 0 20px" label-position="right"
               label-width="200px">
        <h2 class="h2right">须知 </h2>
        <hr style="border: 1px dashed ; width: 100%;height: 1px;"/>
        <h1>公司行为规范</h1>
        <div id="CorporateCodeofConduct" style="text-align: left;height: 600px;overflow: auto" @scroll="scrollEvent">
          <h2>行为规范</h2>
          <h3>第一部分：总则</h3>
          <h4>一．为创建规范、良好的市场秩序，保护和营造健康有序的经营环境，确保营销人员和顾客的合规行为所享有的公平公正的机会与礼遇， 促进营销人员、顾客各自及相互之间的和谐，制定本行为规范。</h4>
          <h4>二．本行为规范适用于公司顾客、销售代表、经销商及其经营者/法定代表人以及配偶之间的关系，同时也适用于公司顾客、销售代表、经销商各自及相互之间的关系。</h4>
          <h4>三. 效力：</h4>
          <p>1.本行为规范是公司相关业务合同的有效组成部分，是基本行为规范。</p>
          <p>2.公司保留对本行为规范的解释权、变更权：</p>
          <p>- 公司根据实际需要对本行为规范的各项内容所做出的解释、补充、修订等，均具有同等效力；</p>
          <p>- 公司可根据实际需要，以各种“规定”、“细则”等形式作为本行为规范的补充。</p>
          <p>3.本行为规范自公布之日起生效。</p>
          <h4>四．公司在法律范围内，将根据不时公布的法律、法规以及公司的实际需要修订本行为规范的有关条款，相关修订内容将通过公司官方渠道公布并实施。</h4>
          <h3>第二部分：名词解释</h3>
          <p>公司产品：由公司提供的所有产品和辅销资料。</p>
          <p>营销人员：是指与公司签署合同/协议，从事公司业务的人员，包括销售代表、经销商、微凤凰品质生活馆馆长。</p>
          <p>顾客：按照公司要求完整、详细、真实填写相关信息进行注册、并经公司审核同意的自然人，包括普通顾客和优惠顾客。</p>
          <p>销售代表：《直销管理条例》中规定的“直销员”，与公司签订“直销员推销合同”， 获得“直销员证”，在获得直销经营许可地区的固定营业场所之外，直接向最终消费者推销产品的人员。</p>
          <p>经销商：与公司签订经销商合同，按照公司的需求为公司产品/品牌/文化，提供营销、推广、咨询、服务的商事主体， 包括拥有专营店铺（含微凤凰品质生活馆）进行销售、推广、咨询、服务的经销商和仅进行推广、咨询、服务的经销商。</p>
          <h3>第三部分：资格的申请与存续</h3>
          <h4>一. 资格的申请</h4>
          <p>1.成为顾客：</p>
          <p>（1）年满18周岁的任何人士，具有民事行为能力且自愿申请。</p>
          <p>（2）公司只接受自然人申请。</p>
          <p>（3）按照公司要求完整、详细、真实填写相关注册信息。</p>
          <p>（4）经公司审核同意。</p>
          <p>2.成为经销商：</p>
          <p>（1） 年满22周岁具有完全民事行为能力，且申请人不得为： 全日制在校学生、公务员、现役军人、有刑事犯罪记录人员、国家禁止的任何邪教及非法组织成员以及国家法律、行政法规规定不得经商的人员。</p>
          <p>（2）有意向及能力在固定经营地点推广公司产品。</p>
          <p>（3）对公司的品牌和产品有一定了解，理解并愿意遵守公司制定的行为规范、业务政策等所有相关管理制度、规则。</p>
          <p>（4）按照公司要求完整、详细、真实填写相关信息并提交相关申请资料。</p>
          <p>（5）签署经销商合同。</p>
          <p>（6）经公司审核通过。</p>
          <p>3.成为销售代表：</p>
          <p>（1）年满22周岁的中国公民，具有完全民事行为能力，且申请人不得为： 全日制在校学生、教师、医务人员、公务员、现役军人、直销企业的正式员工、境外人员、中国政府禁止的任何邪教和非法组织人员、法律、行政法规规定不得从事兼职的人员。</p>
          <p>（2）对公司的品牌和产品有一定了解，理解并愿意遵守公司制定的行为规范、业务政策等所有相关管理制度、规则。</p>
          <p>（3）按照公司要求完整、详细、真实填写相关信息。</p>
          <p>（4）参加公司的培训，并通过考试。</p>
          <p>（5）与公司签订推销合同，领取直销员证。</p>
          <h4>二．资格的要求与存续</h4>
          <p>1.公司有权决定是否接受申请人的资格申请。</p>
          <p>2.申请人有责任保证向公司提供资料的真实性、合法性，不得借用、冒用他人名义、提供虚假、违法的证明材料或使用其他不正当方法欺骗公司， 否则公司保留一经发现即对其做出处理的权利。</p>
          <p>3. 任何人不得重复申请资格，以任何形式拥有二个及以上资格的，公司将视同后获得资格无效。</p>
          <p>4.不得擅自为他人代为申请资格或冒充他人资格开展营销推广活动，也不得委托他人利用自己的资格开展营销推广活动。</p>
          <p>5.不得诱导、胁迫他人违规申请资格，或者接纳他人违规申请的资格。</p>
          <p>6.不得擅自转让或接受资格，个人之间私自转让资格的行为无效，亦不得以资格为条件与他人实施合作、合伙、合资等经营行为，由行为人自行承担由此引发的一切法律责任及损害后果。</p>
          <p>7.个人地址、电话等信息变更的，应及时进行更新，否则自行承担信息不准确而导致的后果。</p>
          <p>8.资格有效期以在线提交申请之日起计算；资格有效期内，按照公司要求续约通过的，资格有效期会自动延续。</p>
          <p>9.在资格有效期内未达到续约条件或存在违法/违规以及公司认为不适合续约的情况，公司有权拒绝续约。</p>
          <p>10.资格无论因何种原因失效，自资格失效之日起180日后方可重新申请；此规定亦适用于夫妻一方的资格失效后，另一方另行申请新的资格。</p>
          <h3>第四部分：行为规范</h3>
          <h4>一.基本规范</h4>
          <p>1.遵守国家法律、法规及各项规章制度等规范性法律文件。</p>
          <p>2.尊重社会公德和公序良俗，履行社会责任、传播正能量。</p>
          <p>3.充分了解并遵守本行为规范以及公司的各项规章、制度、政策以及对前述各类文件不定期补充、修正、变更的内容。</p>
          <p>4.自觉尊重、维护公司品牌、形象、市场秩序以及他人的合法权益：</p>
          <p>- 不得侵害公司、产品、商标、品牌名誉及形象；</p>
          <p>- 不得在营销推广过程中有任何涉及政治、宗教、迷信、传销及其他不正当或不道德内容的言行；</p>
          <p>- 不得以任何方式贬低、诋毁、诽谤、攻击公司、公司员工以及其他职业、行业、公司、品牌、商品及其从业者的声誉；</p>
          <p>- 不得威胁、恐吓或侵害公司、公司员工以及公司相关其他营销推广人员的人身及财产安全；</p>
          <p>- 不得以任何形式扰乱公司会议、公司及相关联公司、分支机构、服务网点或其他与公司相关的活动场所的公共秩序；</p>
          <p>- 不得干扰公司、公司员工、公司相关营销推广人员的正常推广活动的开展；</p>
          <p>- 不得强行推广或利用其他不正当的方式（包括但不限于阻拦路人、沿街兜售、登门叫卖、滥发传真、派发传单、张贴海报、大量邮寄信件、 向非特定对象广泛散发名片或其他类似传播方法）进行公司、产品、业务等推广或推销；</p>
          <p>- 亦不得利用他人从事上述行为。</p>
          <h4>二. 产品推广规范</h4>
          <p>1.在推广产品时，应严格按照公司提供的产品资料介绍和推广产品，不得对产品的用途、 性能、功效、用法、用量、成分、规格、有效期、产地、资质、证书、荣誉等进行任何夸大、失实或引人误解的虚假宣传及解释，更不得欺骗、误导顾客。</p>
          <p>2.在推广产品时，不得以任何方式明示或暗示产品具有治疗作用，或承诺、保证使用效果。</p>
          <p>3.不得通过零售店、网购、邮购、商城、直播等公众场所或媒介，变价（包括但不限于打折、变相打折等）推广公司产品及辅销品。</p>
          <p>4.不可重新包装公司产品或调换产品内容；不可破坏、更改、涂销、伪造公司产品包装或各种标志。</p>
          <p>5. 倡导理性消费，维护顾客的权益，引导合理订购产品，不得以游说、误导、诱导、蒙骗、诱导借贷等方式让顾客购买明显超过其合理消费量的产品。</p>
          <p>6.不得向任何从事转售或不正当竞争行为的人员或商家等第三方，销售或提供公司产品及赠品/礼品。</p>
          <p>7.详细介绍公司的退换货制度，让顾客充分了解产品售后的退换事宜，正确对待顾客对产品反映的问题； 以诚恳礼貌的态度迅速处理顾客对产品的投诉，及时、快速反应、同步给公司，并配合、跟进。</p>
          <h4>三. 市场推广规范</h4>
          <p>1.认真学习、了解公司情况，实事求是介绍公司，不得对公司实力、背景、荣誉等做夸大、失实的宣传。</p>
          <p>2.不得散布任何未经公司认可的业务、政策、营运等信息，或对公司公布的各类信息作失实传播或宣导。</p>
          <p>3.不得以曲解公司等不正当方式进行市场推广：</p>
          <p>（1）不得歪曲、编造、曲解、隐瞒公司业务计划、政策、信息；</p>
          <p>（2）不得误导顾客购买其所不需要的产品；</p>
          <p>（3）不得擅自以公司、分支机构、公司内部职能、员工身份以及不适宜身份、称谓进行推广，亦不得以使人误解或曲解的身份、关系进行推广；</p>
          <p>（4）不得以任何形式（包括但不限于给付利益、贬低他人、威胁强迫、滋扰他人生活等）扰乱经营秩序或抢夺他人客源，进行不正当竞争；</p>
          <p>（5）不得夸大财富收入、公司实力，不得宣称公司业务为一夜暴富、不劳而获、少劳多得、轻易成功的机会；</p>
          <p>（6）不得宣扬仅通过个人消费或不当的产品使用方法就可以获得事业成功；</p>
          <p>（7）不得以公司经营前景、市场发展潜力或个人能力等向他人承诺高额回报；</p>
          <p>（8）不得给付利益的方式利用他人进行推广，或自行为他人计算并发放报酬；</p>
          <p>（9）不得利用与公司业务相关之场合、资源或关系进行融资、集资、募捐等活动，也不得诱导他人进行任何投资、贷款和证券交易等金融和证券类活动。</p>
          <p>4.不得利用公司资源（包括但不限于经营场所、推广渠道、推广工具等）展示、推广、销售任何非公司的产品、业务、活动、 服务及相关宣传资料、票券，亦不得从事与公司存在竞争关系、利益冲突或有悖于商德法规的其他产品或服务的营销活动。</p>
          <p>5.未经公司同意，不得在任何媒体上（包括但不限于互联网、电视媒体、手机移动端、纸媒等平台）刊登有关公司、产品、业务的广告或进行公司、 产品、业务的推广，亦不得通过其他渠道传播、散发与公司、产品、业务相关的广告或宣传资料。</p>
          <p>6.未经公司批准，不得就与公司产品或业务有关的话题擅自接受新闻、网站等媒体、出版单位或个人的采访、演讲、授课、经验分享等。</p>
          <p>7.不得自行举办任何形式的与公司业务、产品等相关的会议、培训、活动；亦不得借用其他个人、团体、组织、公司名义进行上述活动。</p>
          <p>8.除上述情况外，不得从事公司不定期公示的、公司认为恶意或不正当的推广行为。</p>
          <h4>四．大众传媒规范</h4>
          <p>1.只可使用公司指定的公司官方网站、文字、影音等辅销用品（资料、器具等）或业务辅助手段进行营销推广活动， 不得使用任何未经公司核准的辅销用品（资料、器具等）或业务辅助手段（包括但不限于自行设立网站等）来进行公司业务的宣传、推广和联络等活动。 因使用未经公司核准的任何形式的辅销用品（资料、器具等）或业务辅助手段进行营销推广活动的，当事人自行承担由此产生的一切法律责任， 因此给公司造成损失的，公司有权向其依法追索。</p>
          <p>2.未经公司核准，不得自行或通过其他单位或个人制作、出版、发行、传播任何与公司相关（包括但不限于涉及公司、产品、业务等相关领域）的印刷品及视听资料。</p>
          <p>3. 不得以任何形式（包括但不限于视频、照片、文字、录音等）将公司未公布的业务信息、讲义、教育内容等信息、 数据、资料等进行公开、张贴或通过自媒体进行私下发布（如微博、微信朋友圈、微信公众平台、视频或直播平台等）。</p>
          <p>4. 不得擅自印刷、使用非公司提供的统一格式的名片。</p>
          <h4>五．公司名称、商标及著作权使用规范</h4>
          <p>未经公司书面许可，任何人不得进行以下行为：</p>
          <p>1.擅自全部或部分使用、复制、修改、传播公司及其关联公司/机构的商标、商号、 企业名称、企业标志、服务标识、活动名称等相同或近似的文字、图案。</p>
          <p>2．擅自申请、注册或持有公司及其关联公司/机构的商标、商号、企业名称、企业标志、服务标识、 活动名称相同或近似的文字、拼音、图案等的商标、域名、网站名称、网店名称、通用网址、无线网址、电脑应用软件、手机应用软件、网络用户名等。</p>
          <p>3．生产、获取、使用、推广任何非公司提供的带有与公司及其关联公司/机构商标、商号、企业名称、 企业标志、服务标识、活动名称、产品包装等相同或近似的标志、包装装潢的产品。</p>
          <p>4.将公司及其关联公司/机构的商标、企业标志、活动名称、产品包装设计以相同或近似的方式使用于任何包装、装潢、广告、网站、宣传资料。</p>
          <p>5．以任何方式使用以公司及其关联公司/机构名义制作、出版的文字、美术、摄影图片、动画、音乐、录像等资料， 使用方式包括但不限于复制、转载、发布、传播、在网络上设置链接等。</p>
          <h4>六. 监督与处分</h4>
          <p>1.发现权益受到侵害时，投诉人可以书面形式向公司提出投诉及维权要求，为保障投诉举证、调查核实过程的合理性， 自侵权行为发生之日起180日之内未向公司提出的，公司保留不予受理的权利。</p>
          <p>2.公司有权针对投诉进行调查，被调查人应当配合，在此过程中主动配合调查，尽到如实告知义务，积极纠正违规行为。</p>
          <p>3.为全面、客观地了解、核实投诉的整体情况，公司会通知被投诉人，被投诉人应自接到通知之日起7工作日内及时将书面陈述或相关资料提交公司， 公司会对于事实清楚的事件，直接做出处理决定。</p>
          <p>4.被投诉人接到公司的处理通知后，如有意申请复查，须自处理决定公布之日起10个工作日内，以书面形式向公司申请复查，同时应公司要求提供相关的证据资料， 逾期公司保留不予受理的权利；复查期间，原处理决定依然有效。</p>
          <p>5.在营销推广过程中，若违反相关国家法律、法规及公司制定的各项规定、业务政策而危害到公司权益的，公司依法保留追究和索赔的权利。</p>
          <p>6.若因被投诉人及其关联人自身或第三方原因，或任何其他不可归责于公司的原因，导致人身伤害或财产损失的，应当由被投诉人及其关联人自行承担责任或要求相关责任方赔偿， 公司不承担任何责任；因被投诉人及其关联人无理要求影响公司经营或导致公司损失的，公司有权对被投诉人到期不予续约并保留追究其法律责任的权利。</p>
          <p>7.对于违反本行为规范的行为，公司根据违规情节轻重追究相关人员的责任，其措施包括但不限于：</p>
          <p>（1）口头警告；</p>
          <p>（2）书面警告；</p>
          <p>（3）暂停发放推广服务费；</p>
          <p>（4）暂停资格；</p>
          <p>（5）到期不予续约。</p>
          <p>营销人员在从事公司营销推广业务过程中，出现违法或违规的情形，在以上措施的基础上，如情节严重，公司可立即解除合同。</p>
          <p>8.对于被投诉人的违规行为，公司当时未能追究责任或未能追究全部责任的，不视为公司放弃追究，公司保留再予追究的权利。</p>
          <h3>本《行为规范》的最终解释权归天津和治友德制药有限公司所有。 以上内容如有与国家现行法律、法规相冲突的，以国家现行法律、法规为准。</h3>
        </div>
        <el-checkbox :disabled="checked1dis" v-model="checked1" style="margin: 15px auto"><span style="color: red;font-weight: 700;font-size: 20px">我已阅读并同意此协议</span></el-checkbox>
        <hr style="border: 1px dashed ; width: 100%;height: 1px;"/>
        <h1>经销商须知</h1>
        <div id="DistributorNotice" style="text-align: left;height: 600px;overflow: auto" @scroll="scrollEvent">
          <h2>经销商须知</h2>
          <h3>一、经销商定义</h3>
          <p>经销商：与公司签订经销商合同，按照公司的需求为公司产品/品牌/文化，提供营销、推广、咨询、服务的商事主体， 包括拥有专营店铺（含微凤凰品质生活馆）进行销售、推广、咨询、服务的经销商和仅进行推广、咨询、服务的经销商。</p>
          <h3>二、经销商资格</h3>
          <p>1. 申请条件</p>
          <p>（1）主体条件</p>
          <p>- 年满22周岁具有完全民事行为能力，且申请人不得为：全日制在校学生、公务员、现役军人、有刑事犯罪记录人员、国家禁止的任何邪教及非法组织成员以及国家法律、行政法规规定不得经商的人员。</p>
          <p>- 有意向及能力在固定经营地点推广公司产品。</p>
          <p>- 对公司的品牌和产品有一定了解，理解并愿意遵守公司制定的行为规范、业务政策等所有相关管理制度、规则。</p>
          <p>（2）考核条件</p>
          <p>遵守公司制定的行为规范及其他相关政策、制度、规定，无重大违法/违规行为。</p>
          <p>（3）证照条件</p>
          <p>具有真实、合法、有效的工商营业执照以及经营中其他所需的证照，对于营业执照的要求如下：</p>
          <p>- 营业执照企业名称不可为空白，不可与公司所有的商号、商标相同或相似。</p>
          <p>- 营业执照经营者姓名应是申请者本人。</p>
          <p>- 营业执照的经营范围：提供咨询服务（或提供市场营销策划咨询、企业形象策划服务、市场调研服务、公关活动策划服务、售前售后服务及咨询等）。</p>
          <p>（4）通过公司的审核</p>
          <p>2. 申请手续</p>
          <p>符合国家法律、法规要求和公司规定，并有意从事公司营销推广、咨询、服务，可登录公司网站，在线办理申请手续， 按照公司要求完整、详细、真实填写相关注册信息并提交相关申请资料，签署经销商合同，经公司审核通过，其经销商身份方可生效。</p>
          <p>3. 经销商资格的有效期</p>
          <p>（1）经销商资格的有效期以经销商合同上约定的有效期为准。</p>
          <p>（2）经销商合同有效期最长为一年，具体起止时间以合同上的约定为准。</p>
          <p>（3）经销商需保证其用于公司经销商业务的证照，在经销商合同有效期内（包括新的续约周期内）必须真实、有效、合法且内容符合公司的相关规定。</p>
          <p>4. 经销商资格的续约</p>
          <p>（1）续约条件：</p>
          <p>- 持有有效的、符合公司要求的证照。</p>
          <p>- 经销商合同有效期内，遵守国家法律、法规和公司制定的行为规范以及其他相关规定、规则，无违法/违规行为。</p>
          <p>- 经销商合同届满任何一方未提出书面异议。</p>
          <p>（2）经销商合同有效期届满前，经销商需在线完成经销商合同续期手续； 完成续期手续后，经销商资格有效期自动延续一年；经销商合同有效期届满，若未完成续期手续，经销商资格自动失效。</p>
          <p>（3）在经销商资格有效期内，若经销商有违法行为或违反公司相关规定危害公司利益的行为，公司有权立即终止经销商合同，并保留进一步追究经销商相关责任的权利。</p>
          <p>5. 合同终止</p>
          <p>经销商如欲终止经销商合同，需提前30日向公司提出书面申请，同时必须与公司结清履行相关合同所产生的债权债务。</p>
          <h3>三、经销商权益</h3>
          <p>1. 享有对推广公司产品的自主选择决定权。</p>
          <p>2. 享受优惠价格、折扣订购公司产品。</p>
          <p>3. 参与公司促销活动、优惠计划。</p>
          <p>4. 在经营范围内，对公司/产品/品牌/文化，进行营销推广、咨询、服务：</p>
          <p>（1）为顾客提供售前咨询、售后服务，包括但不限于提供产品介绍、使用示范、性能讲解、订货服务、送货上门等，并及时反馈顾客意见。</p>
          <p>（2）在经营场所内陈列公司产品、展示/播放公司制作、发布的宣传影像资料（如张贴海报、播放宣传片等），提升公司/产品/品牌/文化的知名度和美誉度。</p>
          <p>（3）配合公司营销推广方案，安排、组织营销推广活动，包括但不限于产品展销会、产品体验日等。</p>
          <p>（4）在对市场信息进行收集、整理、分析的基础上，按照公司的要求，对公司的发展提出咨询、建议。</p>
          <p>5. 享有通过个人申请、经公司评估同意、获邀为公司进行有偿营销推广、咨询、服务，并领取约定额度的推广服务费的机会。</p>
          <h3>四、其他</h3>
          <p>1. 如欲申请开设公司的微凤凰品质生活馆，则申请人在符合本须知相关要求的前提下，还需满足微凤凰品质生活馆相关管理规定。</p>
          <p>2. 公司制定的市场支持方案、业务促销政策等相关规则内容若与本须知冲突，以公司制定的市场支持方案、业务促销政策等相关规则内容为准。</p>
          <p>3. 申请人不得从事任何违反国家法律、法规的活动，造成公司负面影响或损失的，公司有权进行追责。</p>
          <p>4. 本须知自公布之日起生效施行。</p>
          <p>5. 公司在法律范围内，可根据实际需要修订本须知的有关条款，相关修订内容将通过公司官方渠道公布，相关条款一经公布即生效并实施。</p>
          <p>6. 本《经销商须知》的最终解释权归天津和治友德制药有限公司所有。</p>
          <h3>以上内容如有与国家现行法律、法规相冲突的，以国家现行法律、法规为准。</h3>
        </div>
        <el-checkbox :disabled="checked2dis" v-model="checked2" style="margin: 15px auto"><span style="color: red;font-weight: 700;font-size: 20px">我已阅读并同意此协议</span></el-checkbox>
        <hr style="border: 1px dashed ; width: 100%;height: 1px;"/>
        <h1>经销商合同</h1>
        <div id="DealerContract" style="height: 600px;overflow: auto" @scroll="scrollEvent">
          <div style="text-align: left">
            <h3>申请成为公司经销商，须登录本公司订单系统真实、完整、准确提交相关申请资料、在线确认、签署本合同，并通过公司审核通过后，经销商资格生效。</h3>
            <h3>本合同一经“确认同意”即在经销商和公司之间具有法律效力，因此公司有义务在此特别提醒： 请务必认真阅读、充分理解本合同各条款，特别是其中所涉及的免除或限制公司责任的条款、排除或限制经销商权益的条款等。 请审慎阅读并选择接受或不接受本合同。除非接受本合同所有条款，否则无权获得经销商资格及公司于本合同下所提供的服务内容。</h3>
            <h3>无论您事实上是否在“确认同意”之前认真阅读了本合同，只要您最终点击了“本人自愿申请成为公司经销商，同意《经销商合同》并确认签署”按钮， 您的行为即表示您同意并签署了本合同。</h3>
          </div>
          <h1>经销商合同</h1>
          <div style="text-align: left">
            <h3>甲方：天津和治友德制药有限公司</h3>
            <h3>地址：天津市宝坻区经济开发区天中路16号</h3>
            <h3>乙方（商户名称）：</h3>
            <h3>法定代表人/合伙人/经营者姓名：</h3>
            <h3>经营地址：</h3>
            <h3>联系电话：</h3>
            <p>甲方为依照中华人民共和国法律组成并有效存续的中国企业法人，乙方为依法领取相关营业执照，符合甲方关于经销商的相关要求， 自愿申请按照甲方的需求为甲方公司、品牌、产品进行营销推广、咨询、服务的独立经营者。</p>
            <p>甲方同意授权乙方作为甲方的经销商，双方本着自愿、平等、诚信的原则，经协商一致，达成以下条款，以明确双方权利和义务并共同遵守履行。</p>
          </div>
          <div style="text-align: left">
            <h4>一．声明与承诺</h4>
            <p>1．甲方保证其为依法存在，有权签订本合同的法人组织。</p>
            <p>2. 甲方具备相应产品的生产、经营资质；保证产品质量符合国家强制性标准及法律规定。</p>
            <p>3. 甲方提供给乙方的任何运营资料、信息、技术信息等商业秘密以及乙方提供给甲方的个人身份、银行账号信息，双方均负有保密义务，不得向第三人泄露。</p>
            <p>4. 乙方确保向甲方提供的个人信息和资质资料信息真实、准确、有效及完整，并有责任维持并更新个人信息或资料， 若乙方提供任何错误、虚假、过时或不完整的信息或资料，或者甲方依其独立判断怀疑信息或资料为错误、虚假、过时或不完整， 甲方有权采取包括但不限于暂停或取消乙方经销商资格，在此情况下，甲方不承担任何责任。</p>
            <p>5. 乙方保证具备从事营销推广的经营资质，保证在本合同有效期内（包括本合同续约新周期内）依法需办理的证照均合法、有效、完备，并符合甲方的要求。</p>
            <p>6. 乙方是为顾客就甲方产品提供咨询、服务，开展市场推广的独立经营者；乙方作为独立法人或个体工商户，实行自主经营、自负盈亏、独立核算、自行完税， 乙方承诺依法经营；乙方作为独立经营实体，乙方不属于甲方的分支机构、职能部门，甲乙双方不存在投资、雇佣、承包、代表、代理等关系，在经营活动中各自独立承担法律责任。</p>
            <p>7. 乙方保证甲方无须为乙方与任何第三人之间存在的任何关系承担任何责任。</p>
            <p>8. 乙方同意，甲方有权随时对本合同内容进行单方面的变更，并以公告的方式予以公布实施，无需另行单独通知乙方； 若乙方在本合同内容公告变更后继续使用本合同服务的，表示乙方已充分阅读、理解并接受修改后的合同内容，也将遵循修改后的合同内容使用本合同服务； 若乙方不同意修改后的合同内容，视为乙方单方面解除本合同。</p>
            <p>9. 乙方确认，在签署本合同之前，已充分阅读、理解并接受本合同的全部内容，以及查阅了甲方与本合同相关的全部规章制度并同意予以遵守。</p>
            <h4>二．甲方的权利与义务</h4>
            <p>1. 为乙方提供营销推广支援、咨询支持、资料讯息支持等服务，并组织开展相关业务培训。</p>
            <p>2. 对乙方的营销推广活动实施指导、监督和表彰。</p>
            <p>3. 组织、实施品牌、企业文化、健康理念、产品宣传等推广活动。</p>
            <p>4. 适时制定、完善、公布实施相应营运细则和规章制度，为乙方规范推广行为提供支持和指引。</p>
            <p>5. 有权在各类宣传资料（包括文字和音像资料）使用乙方及其相关人员（包括但不限于法 定代表人/合伙人/经营者及其配偶）的肖像等图文资料，而无须另行协商和付费，乙方保证甲方取得所需要的授权或许可。</p>
            <p>6. 根据市场管理需要，向乙方收集、调取业务信息。</p>
            <p>7. 依照甲方相应规章制度、业务规范等对乙方违约/违规行为进行调查、处理。</p>
            <h4>三．乙方的权利与义务</h4>
            <p>1. 从事营销推广活动应遵守国家法律、法规，诚信经营，照章纳税。</p>
            <p>2. 了解并遵守甲方制定的《行为规范》以及甲方发布的所有关于经销商的管理制度、规章规则，并愿意接受甲方制定的行为规范及其他相关制度、规章的管理。</p>
            <p>3. 乙方应在本合同期间内，积极营销推广甲方公司、产品、品牌，以合法方式从事营销推广的商业活动，乙方自主经营、自负盈亏、经营中的风险自担。</p>
            <p>4. 乙方在营销推广甲方产品过程中，应保持良好商业信誉，维持甲方品牌和产品形象，不得以任何借口和理由做出有损品牌和产品形象的行为。</p>
            <p>5. 在本合同有效期内，乙方营销推广工作指标必须符合甲方统一规定的标准。</p>
            <p>6. 乙方仅被甲方授权营销推广甲方品牌的产品，乙方应尊重、保护甲方品牌和产品的知识产权， 乙方不得从事除营销推广甲方品牌的产品之外的其他损害或侵犯甲方品牌知识产权的活动。</p>
            <p>7. 积极配合由甲方或甲方指定人员就规范营销推广和规范管理所展开的调查、检查和处理，并落实整改意见。</p>
            <p>8. 乙方同意并授权甲方在各类宣传资料（包括文字和音像资料）免费使用乙方及其相关人员（包括但不限于法定代表人/合伙人/经营者及其配偶）的肖像等图文资料， 并配合甲方出具相关的手续。</p>
            <h4>四．乙方的权益</h4>
            <p>1. 享有对推广甲方产品的自主选择决定权。</p>
            <p>2. 享受优惠价格、折扣订购甲方产品。</p>
            <p>3. 参与甲方促销活动、优惠计划。</p>
            <p>4. 在经营范围内，对甲方公司/产品/品牌/文化，进行营销推广、咨询、服务：</p>
            <p>（1）为甲方顾客提供售前咨询、售后服务，包括但不限于提供产品介绍、使用示范、性能讲解、订货服务、送货上门等，并及时反馈顾客意见。</p>
            <p>（2）在经营场所内陈列甲方产品、展示/播放甲方制作、发布的宣传影像资料（如张贴海报、播放宣传片等），提升甲方公司/产品/品牌/文化的知名度和美誉度。</p>
            <p>（3）配合甲方的营销推广方案，安排、组织营销推广活动，包括但不限于产品展销会、产品体验日等。</p>
            <p>（4）在对市场信息进行收集、整理、分析的基础上，按照甲方的要求，对甲方的发展提出咨询、建议。</p>
            <p>5. 享有通过个人申请、经甲方评估同意、获邀为甲方进行有偿营销推广、咨询、服务，并领取约定额度的推广服务费的机会。</p>
            <h4>五. 乙方的收益与支付</h4>
            <p>1. 乙方从甲方获得的收益统称为服务费；甲方将根据乙方提供服务的质量、规模、效果、市场影响等计算服务费。</p>
            <p>2．乙方的服务费统一由甲方支付。</p>
            <p>3. 乙方的服务费全部以人民币支付，每月结算一次。</p>
            <p>4. 乙方应及时提供合格的发票 ，甲方自签收合格发票之日起10个工作日之内将乙方的服务费通过银行转账的方式支付至乙方提供的银行账户内。乙方对指定银行账户的行为承担法律责任。</p>
            <h4>六. 税务责任</h4>
            <p>乙方在推广甲方产品时发生的所有税费均由乙方自行承担，乙方须自行办理报税、缴税等事项。</p>
            <h4>七. 合同的解除</h4>
            <p>1. 本合同期满，双方不再续约的，本合同自动终止。</p>
            <p>2．乙方欲提前终止合同的，需提前30日向甲方提出申请，同时乙方应与甲方结清因履行本合同而产生的债权债务。</p>
            <p>3. 乙方违反国家法律、法规或严重违反甲方相关管理制度和规章，甲方有权立即解除本合同、取消乙方经销商资格，甲方不承担任何责任。</p>
            <p>4. 其他不可抗力原因。</p>
            <h4>八．合同期限、效力及延续</h4>
            <p>1. 本合同为电子合同，在线确认签署之后，则本合同当日自动生效；乙方不得以不知晓本合同内容为由，拒绝履行本合同任何内容。</p>
            <p>2. 本合同的有效期限为一年，自乙方在线确认签署之日起开始计算，到期自动失效；如无争议，乙方可根据甲方关于经销商续约的规则对本合同进行续约。</p>
            <h4>九．纠纷的解决和法律适用</h4>
            <p>因本合同或履行本合同产生的纠纷，双方应友好协商，协商不成，应向甲方住所地人民法院起诉。</p>
            <h4>十．提示条款</h4>
            <p>本合同一经乙方在线“确认同意”即在甲方和乙方之间具有法律效力，因此甲方有义务在此特别提醒乙方，认真阅读、充分理解本合同各条款， 特别是其中所涉及的免除或限制甲方责任的条款、排除或限制乙方权利的条款等。乙方须审慎阅读并选择是否接受本合同之全部，除非接受本合同所有条款， 否则乙方无权获得经销商资格及甲方于本合同下所提供的服务内容。</p>
            <h4>十一．其他</h4>
            <p>甲方制定的《行为规范》及其他相关全部规范及规章制度均作为本合同的附件，与本合同具有同等法律效力。</p>
          </div>
        </div>
        <el-checkbox :disabled="checked3dis" v-model="checked3" style="margin: 15px auto"><span style="color: red;font-weight: 700;font-size: 20px">我已阅读并同意此协议</span></el-checkbox>
        <hr style="border: 1px dashed ; width: 100%;height: 1px;"/>
      </el-form>
      <el-button class="el-icon-check" type="success" @click="toNext" style="margin-top: 40px;margin-bottom: 20px">我已知悉并同意以上协议</el-button>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  name: "Notice",
  directives: {},
  components: {},
  props: [],
  data() {
    return {
      checked1dis: true,
      checked2dis: true,
      checked3dis: true,
      checked1: false,
      checked2: false,
      checked3: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  created() {

  },
  mounted() {

  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {
    scrollEvent () {
      if (this.$el.querySelector('#CorporateCodeofConduct').scrollTop > 3400) {
        this.checked1dis = false
      }
      if (this.$el.querySelector('#DistributorNotice').scrollTop > 800) {
        this.checked2dis = false
      }
      if (this.$el.querySelector('#DealerContract').scrollTop > 1850) {
        this.checked3dis = false
      }
    },
    toNext() {
      if (!this.checked1) {
        return this.$message.error('请同意公司行为规范!')
      }
      if (!this.checked2) {
        return this.$message.error('请同意经销商须知!')
      }
      if (!this.checked3) {
        return this.$message.error('请同意经销商合同!')
      }
      this.$router.push('/member/bussiness-viewedit')
    }
  }
}
</script>
<style scoped>
.h2right {
  float: left;
  color: #F39F43;
}
</style>
