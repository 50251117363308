const getters = {
  language: state => state.app.language,
  token: state => state.user.token,
  userCode: state => state.user.userCode,
  username: state => state.user.username,
  companyCode: state => state.user.companyCode,
  isAgent: state => state.user.isAgent,
  roles: state => state.user.roles,
  modules: state => state.user.modules,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes
}
export default getters
