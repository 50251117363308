<template>
  <div>
    <app-breadcrumb />
    <div class="page-container member-bussiness-list">
    <!--      表格-->
      <el-table
                v-loading="listLoading"
                class="tablemin"
                :data="list"
                border
                fit
                highlight-current-row
                @selection-change="handleSelectionChange">
        <!--        <el-table-column type="selection" width="55" />-->
        <el-table-column
                label="操作"
                align="center"
                fixed
                width="80">
          <template slot-scope="scope">
            <el-button title="查看" size="mini" icon="el-icon-search" circle @click="viewbtn(scope.row)" />
            <el-button title="编辑" size="mini" v-show="checkState" icon="el-icon-edit" type="warning" circle @click="editbtn(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column width="100" label="顾客编号" prop="memberNo" align="center"/>
        <el-table-column width="130" label="营业执照名称" prop="memberName" align="center"/>
        <el-table-column width="100" label="申请人" prop="actualOperator" align="center"/>
        <el-table-column width="120" label="联系电话" prop="tel" align="center"/>
        <el-table-column width="120" label="统一社会信用代码" prop="businessLicenseNo" align="center"/>
        <el-table-column width="120" label="有效期起始时间" prop="startTermDate" align="center"/>
        <el-table-column width="120" label="有效期截止时间" prop="endTermDate" align="center"/>
        <el-table-column min-width="70" label="审核结果" align="center">
          <template slot-scope="scope">
            {{ $lt(initDict.checkState, scope.row.checkState) }}
          </template>
        </el-table-column>
        <el-table-column min-width="50" label="备注" prop="remark" align="center"/>
      </el-table>
    </div>
  </div>
</template>

<script>
  import { checkMemberApplyIsExist } from '@/api/member/bussiness'
export default {
  name: 'MemberBussinessList',
  data() {
    return {
      checkState: false,
      multipleSelection: [],
      lsitLoding: false,
      list: [], // 表格
      listQuery: {},
      // $ll----------------------------------------
      initDict: {
        checkState: 'sysaftersale.examineresult',
      }
    }
  },
  computed: {
    checkStates() {
      return this.$ll(this.initDict.checkState)
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      checkMemberApplyIsExist().then(res => {
        if (!res.data.length){
          this.zeropush()
          this.listLoading = false
          return false
        }
        this.list = res.data
        if(res.data[0].checkState !== 3){
          this.checkState = true
        }
        this.listLoading = false
      })
    },
    // 新增
    zeropush() {
      this.$router.push('/member/bussiness-notice')
    },
    // 查看
    viewbtn(row) {
      this.$router.push({path: '/member/bussiness-viewedit', query:{id: row.id, view: 'view'}})
    },
    // 编辑
    editbtn(row) {
      this.$router.push({path: '/member/bussiness-viewedit', query:{id: row.id}})
    },
    // 表格
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  }
}
</script>

<style scoped lang="scss">
.tablemin {
  font-size: 12px;
}
</style>
