import request from '@/utils/request'

export function fetchTransfers(query) {
  return request({
    url: '/account/transfer/list',
    method: 'get',
    params: query
  })
}

export function applyTransfer(data) {
  return request({
    url: '/account/transfer/apply',
    method: 'post',
    data
  })
}
export function search1(query) {
  return request({
    url: '/agent/member/search1',
    method: 'get',
    params: query
  })
}

