<template>
  <div>
    <app-breadcrumb />
    <div class="page-container widthdraw-apply">
      <el-form ref="applyForm" :model="applyForm" :rules="rules" label-width="140px" size="small">
        <div class="pane-title">账户信息<span class="tips">请认真核对您的银行信息，如有错误或为空，请联系公司修改。</span></div>
        <el-divider></el-divider>
        <el-form-item label="开户人姓名:" style="margin-bottom:0;">
          <span>{{ bankAccount.accountName }}</span>
        </el-form-item>
        <el-form-item label="开户银行:" style="margin-bottom:0;">
          <span>{{ bankAccount.accountBank }}</span>
        </el-form-item>
        <el-form-item label="银行账号:" style="margin-bottom:0;">
          <span>{{ bankAccount.accountCode }}</span>
        </el-form-item>
        <div class="pane-title">账户操作<span class="tips">请按照<em>实发金额</em>提供发票</span></div>
        <el-divider></el-divider>
        <el-form-item label="申领金额:" prop="money">
          <el-input v-model.trim="applyForm.money" placeholder="请填写申领金额" style="width:280px;" />
        </el-form-item>
        <el-form-item label="综合管理费:" style="margin-bottom:5px;">
          <span class="money">{{ fee }}</span>
        </el-form-item>
        <el-form-item label="实发金额:" style="margin-bottom:5px;">
          <span class="money">{{ sendMoney }}</span>
        </el-form-item>
        <div class="pane-title">支付验证<span class="tips">如有其他事项说明可在摘要中填写</span></div>
        <el-divider></el-divider>
        <el-form-item label="支付密码:" prop="password">
          <el-input v-model.trim="applyForm.password" placeholder="请填写您的支付密码" style="width:280px;" show-password />
        </el-form-item>
        <el-form-item label="摘要:">
          <el-input v-model="applyForm.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100" show-word-limit style="width: 640px"/>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" type="primary" @click="onSubmit">保存</el-button>
          <el-button type="default" @click="onBack">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { fetchAccount, applyWithdraw } from '@/api/account/withdraw'
export default {
  name: 'AccountWithdrawApply',
  data() {
    return {
      loading: false,
      bankAccount: {
        accountBank: undefined,
        accountName: undefined,
        accountCode: undefined,
        feeRate: undefined,
        balance: undefined
      },
      applyForm: {
        money: undefined,
        password: undefined,
        remark: undefined
      },
      rules: {
        money: [
          { required: true, message: '请填写申领金额', trigger: 'blur' },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请填写正确的格式，可保留两位小数', trigger: 'blur'}
        ],
        password: [{ required: true, message: '请选择支付密码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    fee() {
      if (this.applyForm.money && !isNaN(this.applyForm.money)) {
        if (this.bankAccount.feeRate) {
          return (this.applyForm.money * this.bankAccount.feeRate).toFixed(2)
        }
        return 0
      }
      return undefined
    },
    sendMoney() {
      if (this.applyForm.money && !isNaN(this.applyForm.money)) {
        return (this.applyForm.money - this.fee).toFixed(2)
      }
      return undefined
    }
  },
  mounted() {
    this.getAccount()
  },
  methods: {
    getAccount() {
      fetchAccount().then(res => {
        this.bankAccount = res.data
      })
    },
    onBack() {
      this.$router.go(-1)
    },
    onSubmit() {
      if (!this.bankAccount.accountBank || !this.bankAccount.accountName || !this.bankAccount.accountCode) {
        this.$message.error('账户信息为空，请联系公司修改')
        return
      }
      this.$refs['applyForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          applyWithdraw(this.applyForm).then(() => {
            this.$message.success('申请成功，请等待管理员审核')
            this.$router.replace('/account/withdraw')
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.widthdraw-apply {
  .money {
    color: #FF0000;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
