import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { login, logout, getInfo } from '@/api/auth'
import { getDictList } from '@/api/auth'
import JSEncrypt from 'jsencrypt/bin/jsencrypt'


const state = {
  token: getToken(),
  userCode: undefined,
  username: '',
  companyCode: '',
  isAgent: false,
  roles: [],
  modules: [],
  permissions: [],
  dict: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERCODE: (state, userCode) => {
    state.userCode = userCode
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_COMPANYCODE: (state, companyCode) => {
    state.companyCode = companyCode
  },
  SET_ISAGENT: (state, isAgent) => {
    state.isAgent = isAgent
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MODULES: (state, modules) => {
    state.modules = modules
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_DICT: (state, dict) => {
    state.dict = dict
  }
}

const actions = {
  login({ commit }, loginData) {
    const { userCode, password, captcha, captchaKey, smsCode, isAgent } = loginData
    return new Promise((resolve, reject) => {
      const encryptor = new JSEncrypt() // 新建JSEncrypt对象
      const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCD0bQilADmzAUy7yX+M7f+R3Z7fLdaokeXb61+F8+VVUXuZWl5Ql' +
          'PKzL9umSTKmM4N9t7pJPhgFSm98cgquPUbfuRCOLUOSvdrDpOfTM5DiasTlpPhztut8xAAmfOyOxis+ExyHb49fnUVvruWVkyxn5hNw3vF' +
          'L6uF3dbuiBPS0QIDAQAB'
      encryptor.setPublicKey(publicKey) // 设置公钥

      // eslint-disable-next-line no-unused-vars
      const userCoderes = encryptor.encrypt(userCode.trim()) // 对需要加密的数据进行加密
      // eslint-disable-next-line no-unused-vars
      const passwordres = encryptor.encrypt(password.trim()) // 对需要加密的数据进行加密
      login({
        // userCode: userCoderes,
        // password: passwordres,
        userCode: userCode.trim(),
        password: password.trim(),
        captcha: captcha,
        captchaKey: captchaKey,
        smsCode: smsCode,
        agent: isAgent
      }).then(res => {
        const { data } = res
        if (!data.first) {
          commit('SET_TOKEN', data.token)
          setToken(data.token)
        }
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo().then(res => {
        const { data } = res
        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { userCode, userName, companyCode, roles, modules, permissions, agent } = data
        if (!modules || modules.length <= 0) {
          reject('getInfo: modules must be a non-null array!')
        }
        commit('SET_USERCODE', userCode)
        commit('SET_USERNAME', userName)
        commit('SET_COMPANYCODE', companyCode)
        commit('SET_ISAGENT', agent)
        commit('SET_ROLES', roles)
        commit('SET_MODULES', modules)
        commit('SET_PERMISSIONS', permissions)
        resolve(data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  getDict({ commit, state}, code) {
    return new Promise((resolve, reject) => {
      getDictList({ code }).then(response => {
        if (response.data) {
          let dict = [ ...state.dict ]
          let index = dict.findIndex(ele => ele.code === code)
          if (index >= 0) {
            dict[index] = response.data
          } else {
            dict.push(response.data)
          }
          commit('SET_DICT', dict)
        }
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', undefined)
        commit('SET_USERCODE', undefined)
        commit('SET_USERNAME', '')
        commit('SET_COMPANYCODE', '')
        commit('SET_ISAGENT', false)
        commit('SET_ROLES', [])
        commit('SET_MODULES', [])
        commit('SET_PERMISSIONS', [])
        removeToken()
        resetRouter()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken()
      commit('SET_TOKEN', undefined)
      commit('SET_USERCODE', undefined)
      commit('SET_USERNAME', '')
      commit('SET_COMPANYCODE', '')
      commit('SET_ISAGENT', false)
      commit('SET_ROLES', [])
      commit('SET_MODULES', [])
      commit('SET_PERMISSIONS', [])
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
