import request from '@/utils/request'

// getLIst
export function checkMemberApplyIsExist(query) {
    return request({
        url: '/agent/checkMemberApplyIsExist',
        method: 'GET',
        params: query
    })
}

// Viewedit保存按钮
export function registeredReseller(data) {
    return request({
        url: '/agent/registeredReseller',
        method: 'POST',
        data
    })
}

// 删除图片
export function dropImages(data) {
    return request({
        url: '/agent/dropImages',
        method: 'POST',
        data
    })
}

// 图片请求
export function getImages(query) {
    return request({
        url: '/agent/getImages',
        method: 'GET',
        params: query
    })
}

// 获取信息
export function getMemberInfo(query) {
    return request({
        url: '/agent/getMemberInfo',
        method: 'GET',
        params: query
    })
}

