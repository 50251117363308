import request from '@/utils/request'

export function fetchOrders(query) {
  return request({
    url: '/order/list',
    method: 'get',
    params: query
  })
}

export function deleteOrder(query) {
  return request({
    url: '/order/delete',
    method: 'get',
    params: query
  })
}
export function payByOrderNo(query) {
  return request({
    url: 'order/payByOrderNo',
    method: 'get',
    params: query
  })
}

export function fetchOrderDetail(query) {
  return request({
    url: '/order/detail',
    method: 'get',
    params: query
  })
}


export function accountRechargeSaoma(data) {
  return request({
    url: 'account/recharge/saoma',
    method: 'post',
    data
  })
}