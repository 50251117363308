<!--
  @文件名:    出库单管理
  @file:    OrderOutManager
  @author:
  @date:    2021/4/16 0016
  @time:    16:23
  @require:
-->
<template>
  <div class="OrderOutManager">
    <app-breadcrumb/>
    <div class="page-container orders-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="100px" size="mini">
          <el-form-item label="出库单号:">
            <el-input v-model.trim="listQuery.outNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="订单号:">
            <el-input v-model.trim="listQuery.orderNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="发货状态:">
            <el-select v-model="listQuery.sendStatus" clearable style="width:170px;">
              <el-option
                  v-for="item in sendStatus"
                  :key="item.code"
                  :label="$lt(initDict.sendStatus, item.code)"
                  :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="出库时间:">
            <el-date-picker
                v-model="listQuery.fromDate"
                type="date"
                style="width: 150px;"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <el-date-picker
                v-model="listQuery.toDate"
                type="date"
                style="width: 150px;"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" plain icon="el-icon-search" :loading="btnloading" @click="handleFilter">查询</el-button>
            <el-button type="danger" plain icon="el-icon-delete" :loading="btnloading" @click="cancelOut">取消出库</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
          v-loading="loading"
          :data="list"
          border
          fit
          size="mini"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            type="selection"
            align="center"
            :selectable = "(row, index) => {return (row.sendStatus) !== 0 && (row.generationMethod) !==0}
"
        />
        <!--        :selectable="(row, index) => {return row.status === '2'}"-->
        <el-table-column label="操作" width="60" header-align="center" align="center">
          <template slot-scope="{row}">
            <div class="opts">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <i class="button el-icon-search" @click="handleView(row)"/>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="出库单号" prop="outNo" align="center"/>
<!--        <el-table-column label="原出库单号" prop="oldOutNo" align="center"/>-->
        <el-table-column label="收货人姓名" prop="recName" align="center"/>
        <el-table-column label="联系电话" prop="recPhone" align="center"/>
        <el-table-column label="收货地址" prop="recAddr" align="center">
          <template slot-scope="{row}">
            <div v-if="row.recType === '3'">
              <div v-if="row.pickCompany === '1'">自提：天津分公司</div>
              <div v-else-if="row.pickCompany === '2'">自提：中山分公司</div>
              <div v-else>{{row.pickCompany}}</div>
            </div>
            <div v-else>
              {{row.recAddr}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="金额" prop="totalAmount" align="center"/>
        <el-table-column label="消费指数" prop="totalFv" align="center"/>
        <el-table-column label="换货积分" prop="totalpointa0" align="center"/>
        <el-table-column label="运费" prop="sendFee" align="center"/>
        <el-table-column label="发货状态" prop="sendStatus" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.sendStatus, row.sendStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出库时间" prop="createTime" align="center"/>
        <!--        <el-table-column label="单据类型" align="center">
                  <template slot-scope="{row}">
                    <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
                  </template>
                </el-table-column>-->
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import {mapGetters} from 'vuex'
import Pagination from '@/components/Pagination'
import {cancelPoOut, fetchPoOuts} from "@/api/order/order-out"

export default {
  name: 'OrderOutManager',
  directives: {},
  components: {Pagination},
  props: [],
  data() {
    return {
      btnloading:false,
      loading: false,
      list: [],
      multipleSelection: [],
      total: 0,
      listQuery: {
        outNo: null,
        oldOutNo: null,
        sendStatus: null,
        fromDate: null,
        toDate: null,
        page: 1,
        limit: 10
      },
      initDict: {
        sendStatus: 'status.po.sendstatus',
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapGetters([
      'isAgent'
    ]),
    sendStatus() {
      return this.$ll(this.initDict.sendStatus)
    }
  },
  // 监控data中的数据变化
  watch: {},
  created() {
    // this.getList()
  },
  mounted() {
  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getList() {
      this.btnloading = true
      this.loading = true
      fetchPoOuts(this.listQuery).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.loading = false
        this.btnloading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
        this.btnloading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleView(row) {
      this.$router.push(`/order/outordermdetail?id=${row.id}`)
    },
    cancelOut() {
      if (this.multipleSelection.length) {
        const idlist = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          idlist.push(this.multipleSelection[i].id)
        }
        this.$confirm('确定取消出库?', '取消出库', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.btnloading = true
          cancelPoOut(idlist).then(() => {
            this.$notify({
              title: '成功',
              message: '操作成功!',
              type: 'success'
            })
            this.getList()
            this.btnloading = false
          }).catch(err => {
            this.btnloading = false
            console.error(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '操作取消'
          })
        })
      } else {
        this.$message.error('请先勾选!')
      }
    }
  }
}
</script>
<style scoped>

</style>
