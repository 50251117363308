// 海外旅游奖励
import request from '@/utils/request'

// getLIst
export function bdPointRecommends(query) {
    return request({
        url: '/agent/bdPointRecommends',
        method: 'GET',
        params: query
    })
}
