import request from '@/utils/request'

export function fetchBalance(query) {
  return request({
    url: '/account/balance',
    method: 'get',
    params: query
  })
}

export function fetchBalanceItems(query) {
  return request({
    url: '/account/list',
    method: 'get',
    params: query
  })
}
