<template>
  <div>
    <app-breadcrumb />
    <div class="page-container order-detail-container">
      <el-form label-position="right" label-width="100px">
        <el-row :gutter="40">
          <el-col :span="12">
            <div class="pane-title">基本信息</div>
            <el-divider></el-divider>
            <el-form-item label="订单编号:" style="margin-bottom: 0;">
              <span>{{ order.orderNo }}</span>
            </el-form-item>
            <el-form-item label="会员编号:" style="margin-bottom: 0;">
              <span>{{ order.memberNo }}</span>
            </el-form-item>
            <el-form-item label="单据类型:" style="margin-bottom: 0;">
              <span>{{ $lt(initDict.orderType, order.orderType) }}</span>
            </el-form-item>
            <el-form-item label="创建时间:" style="margin-bottom: 0;">
              <span>{{ order.createdTime }}</span>
            </el-form-item>
            <el-form-item label="审核时间:" style="margin-bottom: 0;">
              <span>{{ order.checkTime }}</span>
            </el-form-item>
            <el-form-item label="状态:" style="margin-bottom: 0;">
              <span>{{ $lt(initDict.orderStatus, order.status) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="pane-title">付款方式</div>
            <el-divider></el-divider>
            <el-form-item label="商品总金额:" style="margin-bottom: 0;">
              <span>{{ order.totalAmount.toFixed(2) }}</span>
            </el-form-item>
            <el-form-item v-if="order.orderType==='27'" label="换货积分:" style="margin-bottom: 0;">
              <span>{{ order.totalpointa0.toFixed(2) }}</span>
            </el-form-item>
            <el-form-item v-else label="消费指数:" style="margin-bottom: 0;">
              <span>{{ order.totalFv.toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="物流费:" style="margin-bottom: 0;">
              <span>0</span>
            </el-form-item>
            <el-form-item label="合计:" style="margin-bottom: 0;">
              <span>{{ order.totalAmount.toFixed(2) }}</span>
            </el-form-item>
            <el-form-item label="付款方式:" style="margin-bottom: 0;">
              <span>{{ $lt(initDict.payType, order.paymentMethod) }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="pane-title">商品清单</div>
      <el-table :data="order.details" v-loading="loading" stripe border style="margin: 14px 0;">
        <el-table-column label="商品编号" prop="goodsCode" align="center" />
        <el-table-column label="商品名称" prop="goodsName" header-align="center" />
        <el-table-column label="单价" prop="f$" width="100" align="center" />
        <el-table-column label="消费指数" prop="fv" width="100" align="center" />
        <el-table-column label="兑换积分" prop="pointa0" width="100" align="center" />
        <el-table-column label="订购数量" prop="quantity" width="80" align="center" />
        <el-table-column label="是否赠品" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.isGift, row.isGive) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin:20px 0;text-align:center;">
        <el-button type="primary" plain size="small" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchOrderDetail } from '@/api/order/index'
export default {
  name: 'OrderDetail',
  data() {
    return {
      id: undefined,
      loading: false,
      order: {
        id: undefined,
        orderNo: undefined,
        companyCode: undefined,
        orderType: undefined,
        memberNo: undefined,
        memberName: undefined,
        agentNo: undefined,
        totalAmount: undefined,
        totalpointa0: undefined,
        checkCode: undefined,
        checkTime: undefined,
        periodWeek: undefined,
        status: undefined,
        cancelOrderNo: undefined,
        createdTime: undefined,
        paymentMethod: undefined,
        details: []
      },
      initDict: {
        orderType: 'po.allordertype.cn',
        orderStatus: 'po.order.status',
        payType: 'po.order.payment.cn',
        isGift: 'po.order.isgive'
      }
    }
  },
  mounted() {
    const id = this.$route.query.id
    if (!id) {
      this.$router.go(-1)
    } else {
      this.id = id
      this.getOrderDetail()
    }
  },
  methods: {
    getOrderDetail() {
      this.loading = true
      fetchOrderDetail({ id: this.id }).then(res => {
        this.order = res.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">

</style>
