import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/auth',
        method: 'post',
        data
    })
}

// 登录奖金查询模块
export function authWebSelect(data) {
    return request({
        url: '/authWebSelect',
        method: 'post',
        data
    })
}

export function getInfo(query) {
    return request({
        url: '/auth-info',
        method: 'get',
        params: query
    })
}

export function logout(query) {
    return request({
        url: '/logout',
        method: 'get',
        params: query
    })
}

export function getDictList(query) {
    return request({
        url: '/dict',
        method: 'get',
        params: query
    })
}

export function fetchLanguage(query) {
    return request({
        url: '/language',
        method: 'get',
        params: query
    })
}

export function resetLoginPassword(data) {
    return request({
        url: '/resetLoginPassword',
        method: 'post',
        data
    })
}

export function resetLoginPasswordSave(data) {
    return request({
        url: '/resetLoginPasswordSave',
        method: 'post',
        data
    })
}

// 经销商第一次登录手机号验证 发送验证码
export function memberFirstLoginCode(data) {
    return request({
        url: 'member/firstLoginCode',
        method: 'post',
        data
    })
}

export function memberOneLoginConfirm(data) {
    return request({
        url: 'member/oneLoginConfirm',
        method: 'post',
        data
    })
}

// 经销商第一次登录 顾客须知
export function authSaveMemberTime(data) {
    return request({
        url: '/saveMemberTime',
        method: 'post',
        data
    })
}


// 经销商验证是否点击须知
export function getMemberTime(query) {
    return request({
        url: '/getMemberTime',
        method: 'get',
        params: query
    })
}

// 经销商首页点击须知
export function saveMemberTime(data) {
    return request({
        url: '/saveMemberTime',
        method: 'post',
        data
    })
}
