import request from '@/utils/request'

export function editPassword(data) {
  return request({
    url: '/member/password',
    method: 'post',
    data
  })
}

export function editAdvPassword(data) {
  return request({
    url: '/member/adv-password',
    method: 'post',
    data
  })
}

export function sendSmsCode(query) {
  return request({
    url: '/member/sms',
    method: 'get',
    params: query
  })
}
export function findadvpassword(data) {
  return request({
    url: '/member/findadv-password',
    method: 'post',
    data
  })
}
