<!--
  @文件名: 忘记密码
  @file:   ForgetPassword
  @author:
  @date:    2021/8/24
  @time:    15:10
  @require:
-->
<template>
  <div class="ForgetPassword app-login" :class="agentBgClass">
    <div class="login-wrapper">
      <el-form ref="loginForm" class="login-form" label-position="left">
        <p :class="agentFGClass" style="background-color: #EFE2C8;margin: 0;line-height: 40px;font-size: 18px;letter-spacing: 10px;font-weight: 600;color: #9A7B29;">忘记密码</p>
        <div v-show="step===1">
          <el-form-item>
            <el-input v-model.trim="userInfo.userCode" placeholder="请输入忘记密码的用户名">
              <span slot="prefix" class="ipt-label">{{ $t('用户名') }}</span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="userInfo.paperNo" placeholder="请输入预留的证件号">
              <span slot="prefix" class="ipt-label">{{ $t('证件号') }}</span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model.trim="userInfo.mobile" placeholder="请输入预留的手机号">
              <span slot="prefix" class="ipt-label">{{ $t('手机号') }}</span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" :loading="Loading" @click="getRes">{{ $t('下一步') }}</el-button>
          </el-form-item>
        </div>
        <div v-show="step===2">
          <el-form-item style="margin-bottom: 5px">
            <el-input v-model.trim="userInfo.smsCode" placeholder="请输入短信验证码">
              <span slot="prefix" class="ipt-label">{{ $t('验证码') }}</span>
            </el-input>
            <p style="color: red;margin: 0 0 0 5px;line-height: 18px;text-align: left">验证码已发送,请注意查收,有效期5分钟</p>
          </el-form-item>
          <el-form-item style="margin-bottom: 5px">
            <el-input v-model.trim="userInfo.newPassword" placeholder="请输入新密码">
              <span slot="prefix" class="ipt-label">{{ $t('新密码') }}</span>
            </el-input>
            <p style="color: red;margin: 0 0 0 5px;line-height: 18px;text-align: left">密码长度为6-16位且包含大写字母、小写字母、数字、特殊符号其中两种</p>
          </el-form-item>
          <el-form-item style="margin-bottom: 5px">
            <el-input v-model.trim="userInfo.newPassword2" placeholder="请再次新密码">
              <span slot="prefix" class="ipt-label">{{ $t('重复密码') }}</span>
            </el-input>
            <p style="color: red;margin: 0 0 0 5px;line-height: 18px;text-align: left">请勿以自己的生日、身份证号码或其它简单的数字做为密码</p>
          </el-form-item>
          <el-form-item>
            <el-button class="btn" :loading="Loading" @click="changePassword">{{ $t('修改密码') }}</el-button>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button class="btn" :loading="Loading" @click="toLogin">{{ $t('返回登录') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import {resetLoginPassword, resetLoginPasswordSave} from "@/api/auth";

export default {
  name: 'ForgetPassword',
  directives: {},
  components: {},
  props: [],
  data() {
    return {
      userInfo: {
        userCode: '',
        paperNo: '',
        mobile: '',
        smsCode: '',
        newPassword: '',
        newPassword2: ''
      },
      Loading: false,
      step: 1,
    }
  },
  // 监听属性 类似于data概念
  computed: {
    agentBgClass() {
      return process.env.VUE_APP_AGENT === 'true' ? 'app-login-agent' : ''
    },
    agentFGClass() {
      return process.env.VUE_APP_AGENT === 'true' ? 'agentFGTI' : ''
    }
  },
  // 监控data中的数据变化
  watch: {},
  created() {

  },
  mounted() {

  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {
    getRes() {
      if (!this.userInfo.userCode) {
        return this.$message.error('用户名不能为空')
      }
      if (!this.userInfo.paperNo) {
        return this.$message.error('证件号不能为空')
      }
      if (!this.userInfo.mobile) {
        return this.$message.error('手机号不能为空')
      }
      this.userInfo.userCode = this.userInfo.userCode.toUpperCase()
      this.userInfo.paperNo = this.userInfo.paperNo.toUpperCase()
      this.Loading = true
      resetLoginPassword({...this.userInfo}).then((res) => {
        if (res.success) {
          this.$notify({
            title: '成功',
            message: '短信验证码发送成功!',
            type: 'success'
          })
          this.step = 2
        }
        this.Loading = false
      }).catch(err => {
        console.warn(err)
        this.Loading = false
      })
    },
    toLogin() {
      this.$router.push({path: '/'})
    },
    changePassword() {
      if (!this.userInfo.smsCode) {
        return this.$message.error('验证码不能为空')
      }
      if (this.userInfo.newPassword !== this.userInfo.newPassword2) {
        return this.$message.error('两次密码不一致')
      }
      this.Loading = true
      resetLoginPasswordSave({...this.userInfo}).then((res) => {
        if (res.success) {
          this.$notify({
            title: '成功',
            message: '操作成功!',
            type: 'success'
          })
          this.toLogin()
          this.Loading = false
        }
      }).catch(err => {
        console.warn(err)
        this.Loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .login-form {
  height: 330px !important;
  overflow: hidden;
  padding: 200px 20px 0 20px !important;
}

.agentFGTI {
  margin-top: 15px !important;
  background-color: #F0E7D7 !important;
  line-height: 30px !important;
}

</style>


