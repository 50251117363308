<template>
  <div>
    <app-breadcrumb />
    <div class="page-container register">
      <el-steps :active="activeIndex" finish-status="success" class="steps">
        <el-step title="填写服务商信息"></el-step>
        <el-step title="填写顾客信息"></el-step>
        <el-step title="确认顾客信息"></el-step>
        <el-step title="注册成功"></el-step>
      </el-steps>
      <el-form ref="registerForm" :model="register" :rules="rules" label-width="140px" size="small">
        <div class="step1" v-if="activeIndex===0">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info" style="margin-bottom:10px;" />
          <el-form-item label="销售商编号:" prop="recommendNo">
            <el-input style="max-width: 200px" v-model.trim="register.recommendNo"  clearable  @change="handleNoCheck1" />
            <span class="memberNoPromptcsss">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNoPrompt1 }}</span>
          </el-form-item>
          <el-form-item label="服务商编号:" prop="linkNo">
            <el-input style="max-width: 200px" v-model.trim="register.linkNo" clearable @change="handleNoCheck2" />
            <span class="memberNoPromptcsss">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNoPrompt2 }}</span>
            <p v-show="memberNoPrompt3show" class="memberNoPromptcsss">该网体下已有({{memberNoPrompt3}})个节点人</p>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onNext">下一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step2" v-if="activeIndex===1">
          <div class="pane-title">基本资料</div>
          <el-divider></el-divider>
          <el-form-item label="结构类型:" prop="type">
            <div class="types">
              <el-radio v-model="register.type" label="1" class="type-item">
                <div class="image" :class="{active:register.type==='1'}"><img :src="require('@/assets/images/agent/right_qty_1.png')"></div>
              </el-radio>
              <el-radio v-show="this.permissions.includes('zhuce/sandian')" v-model="register.type" label="3" class="type-item">
                <div class="image" :class="{active:register.type==='3'}"><img :src="require('@/assets/images/agent/right_qty_3.png')"></div>
              </el-radio>
              <!-- <el-radio v-model="register.type" label="4" class="type-item">
                <div class="image" :class="{active:register.type==='4'}"><img :src="require('@/assets/images/agent/right_qty_4.png')"></div>
              </el-radio>
              <el-radio v-model="register.type" label="5" class="type-item">
                <div class="image" :class="{active:register.type==='5'}"><img :src="require('@/assets/images/agent/right_qty_5.png')"></div>
              </el-radio> -->
            </div>
          </el-form-item>
          <el-form-item label="顾客姓名:" prop="membername">
            <el-input v-model.trim="register.membername" placeholder="请填写顾客姓名" style="width:280px;" />
          </el-form-item>
          <el-form-item label="昵称:" prop="nickname">
            <el-input v-model.trim="register.nickname" placeholder="请填写顾客昵称" style="width:280px;" />
          </el-form-item>
          <el-form-item label="证件类型:" prop="paperType">
            <el-select v-model="register.paperType" placeholder="请选择证件类型" style="width:280px;">
              <el-option v-for="item in paperTypeList" :key="item.value" :label="item.title" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="证件号码:" prop="paperNo">
            <el-input v-model.trim="register.paperNo" placeholder="请填写证件号码" style="width:280px;" />
          </el-form-item>
          <div class="pane-title">联系资料</div>
          <el-divider></el-divider>
          <!--          prop="regionCode"-->
          <el-form-item label="注册地区:" >
            <el-col :span="6">
              <el-select
                  v-model="countrymodel"
                  value-key="regionId"
                  placeholder="请选择国家"
                  clearable
                  @change="countrychange"
              >
                <el-option
                    v-for="item in addchoicecountry"
                    :key="item.regionId"
                    :label="item.regionName"
                    :value="item"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                  v-model="provincemodel"
                  value-key="regionId"
                  placeholder="请选择省份"
                  clearable
                  @change="provincechange"
              >
                <el-option
                    v-for="item in addchoiceprovince"
                    :key="item.regionId"
                    :label="item.regionName"
                    :value="item"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                  v-model="citymodel"
                  value-key="regionId"
                  placeholder="请选择市"
                  clearable
                  @change="citychange"
              >
                <el-option
                    v-for="item in addchoicecity"
                    :key="item.regionId"
                    :label="item.regionName"
                    :value="item"
                />
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-select
                  v-model="areamodel"
                  placeholder="请选择区/县"
                  clearable>
                <el-option
                    v-for="item in addchoicearea"
                    placeholder="请选择区/县"
                    :key="item.regionId"
                    :label="item.regionName"
                    :value="item.regionCode"
                />
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <el-input v-model.trim="register.address" placeholder="请填写详细地址" style="width:660px;" />
          </el-form-item>
          <el-form-item label="邮政编码:">
            <el-input v-model.trim="register.postalCode" placeholder="请填写邮政编码" style="width:280px;" />
          </el-form-item>
          <el-form-item label="手机号码:" prop="mobile">
            <el-input v-model.trim="register.mobile" placeholder="请填写手机号码" style="width:280px;" />
          </el-form-item>
          <div class="pane-title">密码设置</div>
          <el-divider></el-divider>
          <el-form-item label="设置密码:" prop="password">
            <el-input v-model.trim="register.password" show-password placeholder="请填写6~16位密码" style="width:280px;" />
          </el-form-item>
          <el-form-item label="重复密码:" prop="rePassword">
            <el-input v-model.trim="register.rePassword" show-password placeholder="请再次填写填写密码" style="width:280px;" />
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onComfirm">下一步</el-button>
            <el-button icon="el-icon-back" @click="onForward">上一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step3" v-if="activeIndex===2">
          <div class="pane-title">基本资料</div>
          <el-divider></el-divider>
          <el-form-item label="代办处编号:" style="margin-bottom:0;">{{ userCode }}</el-form-item>
          <el-form-item label="销售商编号:" style="margin-bottom:0;">{{ register.recommendNo }}</el-form-item>
          <el-form-item label="服务商编号:" style="margin-bottom:0;">{{ register.linkNo }}</el-form-item>
          <el-form-item label="结构类型:" style="margin-bottom:0;">{{ registerTypeName }}</el-form-item>
          <el-form-item label="顾客姓名:" style="margin-bottom:0;">{{ register.membername }}</el-form-item>
          <el-form-item label="昵称:" style="margin-bottom:0;">{{ register.nickname }}</el-form-item>
          <el-form-item label="证件类型:" style="margin-bottom:0;">{{ paperTypeName }}</el-form-item>
          <el-form-item label="证件号码:" style="margin-bottom:0;">{{ register.paperNo }}</el-form-item>
          <div class="pane-title">联系资料</div>
          <el-divider></el-divider>
          <el-form-item label="所在地区:" style="margin-bottom:0;">{{ regionFullName }}</el-form-item>
          <el-form-item label="详细地址:" style="margin-bottom:0;">{{ register.address }}</el-form-item>
          <el-form-item label="邮政编码:" style="margin-bottom:0;">{{ register.postalCode }}</el-form-item>
          <el-form-item label="手机号码:" style="margin-bottom:0;">{{ register.mobile }}</el-form-item>
          <el-form-item style="margin-top:20px;">
            <el-button :loading="loading" type="primary" icon="el-icon-document-add" @click="onSubmit">保存</el-button>
            <el-button icon="el-icon-back" @click="onForward2">上一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step4" v-if="activeIndex>=3">
          <div class="success">
            <img :src="require('@/assets/images/success.png')">
            <div class="success-txt">恭喜您，注册成功，顾客编号即登录账号，请牢记账号及密码。</div>
            <div class="success-txt">顾客编号：<span>{{ result }}</span></div>
            <el-row >
              <el-button size="small" icon="el-icon-shopping-cart-2" @click="onOrder">点我立即订货</el-button>
              <el-button size="small" icon="el-icon-shopping-cart-2" @click="onOrder1">继续注册</el-button>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {fetChildRegions, getAlRegionsIdList} from '@/api/home'
import { mapGetters } from 'vuex'
import { validateRecommendNo, registerMember, searchMember, getLinkNumber,validatePaperNoAndMobile } from '@/api/agent'
import { getChildRegionsTo, getAlRegionsCode } from '@/api/home'
export default {
  name: 'AgentRegister',
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请填写密码'))
      } else if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度为6~16位'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请再次填写密码'))
      } else if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度为6~16位'))
      } else if (value !== this.register.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    const validatePaperNo = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请填写证件号码'))
      } else if (this.register.paperType && this.register.paperType === '1') {
        const pattern = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
        if (!pattern.test(value)) {
          callback(new Error('身份证号码格式不正确'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      // ----------------------------------------------
      countrymodel2: '',// 国家要传code值
      provincemodel2: '',// 省要传code值
      citymodel2: '',// 市要传code值
      countrymodel: '', // 新增选择国家model
      provincemodel: '', // 新增选择省存储model
      citymodel: '', // 新增选择市存储model
      areamodel: '', // 新增选择县存储model
      // -----------------------------------------------------
      addchoicecountry: [], // 新增选择国家存储
      addchoiceprovince: [], // 新增选择省存储
      addchoicecity: [], // 新增选择市存储
      addchoicearea: [], // 新增选择县存储
      memberNoPrompt3show: '',
      memberNoPrompt3: '',
      memberNoPrompt2: '',
      memberNoPrompt1: '',
      activeIndex: 0,
      country: [],
      province: [],
      city: [],
      disabled: [],
      regionFullName: '',
      paperTypeList: [ { value: '1', title: '身份证' }, { value: '2', title: '护照' } ],
      registerTypeList: [{ value: '1', title: '一个点位' }, { value: '3', title: '三个点位' }, { value: '4', title: '四个点位' }, { value: '5', title: '五个点位' }],
      register: {
        recommendNo: undefined,
        linkNo: undefined,
        type: undefined,
        membername: undefined,
        nickname: undefined,
        paperType: undefined,
        paperNo: undefined,
        regionCode: undefined,
        address: undefined,
        postalCode: undefined,
        mobile: undefined,
        password: undefined,
        rePassword: undefined
      },
      rules: {
        recommendNo: [{ required: true, message: '请填写销售商编号', trigger: 'change' }],
        linkNo: [{ required: true, message: '请填写服务商编号', trigger: 'change' }],
        type: [{ required: true, message: '请选择结构类型', trigger: 'change' }],
        membername: [
          { required: true, message: '请填写顾客姓名', trigger: 'blur' },
          // { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '禁止输入特殊字符，可填写中文英文或数字', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '请填写顾客昵称', trigger: 'blur' },
          // { pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '禁止输入特殊字符，可填写中文英文或数字', trigger: 'blur' }
        ],
        paperType: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        paperNo: [{ required: true, validator: validatePaperNo, trigger: 'blur' }],
        regionCode: [{ required: true, message: '请选择所在地区', trigger: 'change' }],
        address: [{ required: true, message: '请填写详细地址', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请填写手机号码', trigger: 'blur' },
          { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: "手机号码格式不正确", trigger: 'blur' }
        ],
        password: [{ required: true, validator: validatePass, trigger: 'blur' }],
        rePassword: [{ required: true, validator: validatePass2, trigger: 'blur' }]
      },
      loading: false,
      result: '',
      results: '',
      results1: '',
    }
  },
  watch: {
    "register.recommendNo":function (val) {
      this.register.recommendNo = val.toUpperCase()
    },
    "register.linkNo":function (val) {
      this.register.linkNo = val.toUpperCase()
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'permissions'
    ]),
    paperTypeName() {
      const cur = this.paperTypeList.find(ele => ele.value === this.register.paperType)
      return cur ? cur.title : ''
    },
    registerTypeName() {
      const curType = this.registerTypeList.find(ele => ele.value === this.register.type)
      return curType ? curType.title : ''
    },
  },
  created() {
    this.getRegionData()
    this.zerochange()
  },
  mounted() {
  },
  methods: {
    // 所在地区,  需要created的
    zerochange() {
      fetChildRegions({'regionId': 0}).then(res => {
        this.addchoicecountry = res.data
      })
    },
    // 新增按钮 - 国家change事件
    countrychange(val) {
      // 选择国家后 省, 市, 区清空
      this.provincemodel = ''
      this.citymodel = ''
      this.areamodel = ''
      this.countrymodel2 = val.regionCode
      fetChildRegions({'regionId': val.regionId}).then(res => {
        this.addchoiceprovince = res.data
      }).catch(() => {
      })
    },
    // 新增按钮 - 省change事件
    provincechange(val) {
      // 选择省后  市, 区清空
      this.citymodel = ''
      this.areamodel = ''
      this.provincemodel2 = val.regionCode
      fetChildRegions({'regionId': val.regionId}).then(res => {
        this.addchoicecity = res.data
      }).catch(() => {
      })
    },
    // 新增按钮 - 市change事件,
    citychange(val) {
      // 选择市后 区清空
      this.areamodel = ''
      this.citymodel2 = val.regionCode
      fetChildRegions({'regionId': val.regionId}).then(res => {
        this.addchoicearea = res.data
      }).catch(() => {
      })
    },

    getRegionData() {
      getChildRegionsTo({ 'regionId': 0 }).then(res => {
        let regionData = res.data
        this.country = regionData
      })
    },
    regionChange(val) {
      getAlRegionsCode({ 'regionCode': val }).then(res => {
        let regionData = res.data
        if(regionData.length !== 0) {
          if (regionData[0].regionLevel === "2") {
            this.province = regionData
          } else if (regionData[0].regionLevel === "3") {
            this.city = regionData
          } else if (regionData[0].regionLevel === "4") {
            this.disabled = regionData
          }
        }
      })
    },
    onBack() {
      this.$router.go(-1)
    },
    onOrder() {
      if(this.register.type === '1'){
        this.results = this.result
      }
      this.$router.push({
        path:'/order/uplevel',
        query:{
          results:this.results},
      })
    },
    onOrder1() {
      this.$router.go(0)
      // this.activeIndex = 0
      // this.register.linkNo = '',
      //     this.register.recommendNo = ''
    },
    onForward() {
      this.activeIndex = 0
      this.$refs['registerForm'].clearValidate()
    },
    onForward2() {
      this.activeIndex = 1
      this.$refs['registerForm'].clearValidate()
    },
    onNext() {
      this.$refs['registerForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          validateRecommendNo({ recommendNo: this.register.recommendNo, linkNo: this.register.linkNo }).then(() => {
            this.activeIndex = 1
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onComfirm() {
      validatePaperNoAndMobile({...this.register}).then(res=>{
        console.log(res)
        if (this.areamodel) {
          getAlRegionsIdList({'regionCode': this.areamodel}).then(res => {
            this.regionFullName = res.data[3].regionName + res.data[2].regionName + res.data[1].regionName + res.data[0].regionName
          })
        } else if (this.citymodel.regionName) {
          this.regionFullName =  this.countrymodel.regionName + this.provincemodel.regionName + this.citymodel.regionName
        } else {
          return this.$message.error('地区最少填写到市(地区请最少填写到第三位)')
        }
        this.$refs['registerForm'].validate(async (valid) => {
          if (valid) {
            this.activeIndex = 2
          }
        })
      }).catch(err=>{
        console.warn(err)
      })
    },
    onSubmit() {
      this.loading = true
      if (this.areamodel) {
        this.register.regionCode = this.areamodel
      } else if (this.citymodel) {
        this.register.regionCode = this.citymodel.regionCode
      } else if (this.provincemodel) {
        this.register.regionCode = this.provincemodel.regionCode
      } else if (this.countrymodel) {
        this.register.regionCode = this.countrymodel.regionCode
      }
      registerMember(this.register).then(res => {
        this.result = res.data.join(' ')
        this.activeIndex = 4
        this.$refs['registerForm'].resetFields()
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    handleNoCheck1(val) {
      if (val) {
        searchMember({userCode: val.trim().toUpperCase()}).then(res => {
          this.memberNoPrompt1 = res.data[0].userName
        }).catch(() => {
          this.memberNoPrompt1 = '请输入正确的销售商编号'
        })
      } else {
        this.memberNoPrompt1 = ''
      }
    },
    handleNoCheck2(val) {
      if (val) {
        searchMember({userCode: val.trim().toUpperCase()}).then(res => {
          this.memberNoPrompt2 = res.data[0].userName
        }).catch(() => {
          this.memberNoPrompt2 = '请输入正确的服务商编号'
        })
        getLinkNumber({userCode: val.trim().toUpperCase()}).then (res => {
          this.memberNoPrompt3show = true
          this.memberNoPrompt3 = res.data
        }).catch(() => {
          this.memberNoPrompt3show = false
        })
      } else {
        this.memberNoPrompt2 = ''
        this.memberNoPrompt3show = false
      }
    }
  }
}
</script>

<style lang="scss">
.register {
  .steps {
    text-align: left;
    padding: 10px;
    .el-step__title {
      font-size: 14px;
    }
  }
  .types {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    .type-item {
      display: flex;
      width: 100px;
      margin: 0;
      padding-bottom: 6px;
      margin-right: 10px;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        display: flex;
        padding-left: 0 !important;
        img {
          width: 100px;
          height: 80px;
        }
      }
    }
    .image {
      position: relative;
      border: 2px solid #F2F2F2;
    }
    .active {
      border-color: #266AAD;
    }
    .active:after{
      bottom: 0px;
      right: 0px;
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 21px solid #266AAD;
      border-left: 21px solid transparent;
    }
    .active:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 5px;
      background: transparent;
      bottom: 4px;
      right: 0;
      border: 2px solid white;
      border-top: none;
      border-right: none;
      -webkit-transform: rotate(-55deg);
      -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
      z-index: 9;
    }
  }
  .success {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      max-height: 120px;
    }
    .success-txt {
      display: inline-block;
      padding: 10px 0;
      font-size: 14px;
      font-weight: bold;
      width: 420px;
      span {
        color: #FF0000;
        font-size: 16px;
      }
    }
    .el-button {
      margin-top: 20px;
    }
  }
  .memberNoPromptcsss {
    color: red;
  }
}
</style>
