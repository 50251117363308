<template>
  <div>
    <app-breadcrumb />
    <div class="page-container out-order-detail">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="90px" size="mini">
          <el-form-item label="顾客编号:" class="memberNo-css input-red">
            <el-input v-model.trim="listQuery.memberNo" style="width:160px;" clearable />
          </el-form-item>
          <el-form-item label="顾客姓名:" class="input-red">
            <el-input v-model="listQuery.memberName" style="width:160px;" />
          </el-form-item>
          <el-form-item label="荣衔:">
            <el-select v-model="listQuery.honor" clearable style="width:120px;">
              <el-option v-for="item in honors" :key="item.code" :label="$lt(initDict.honor, item.code)" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item label="级别:">
            <el-select v-model="listQuery.level" clearable style="width:120px;">
              <el-option v-for="item in levels" :key="item.code" :label="$lt(initDict.level, item.code)" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item label="加入期数:">
            <el-select v-model="listQuery.fromWeek" clearable style="width:105px;">
              <el-option v-for="item in weeks" :key="item.week" :label="item.week" :value="item.week" />
            </el-select>
            <span style="padding: 0 5px;">至</span>
            <el-select v-model="listQuery.toWeek" clearable style="width:105px;">
              <el-option v-for="item in weeks" :key="item.week" :label="item.week" :value="item.week" />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:5px;">
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button type="danger" :loading="delLoading" icon="el-icon-delete" @click="handleDelete">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="memberTable" v-loading="loading" :data="list" style="width: 100%" size="mini" border stripe>
        <el-table-column type="selection" :selectable="checkAllowDelete" align="center" width="40" />
        <el-table-column label="代办处编号" prop="agentNo" align="center" />
        <el-table-column label="顾客编号" prop="memberNo" align="center" />
        <el-table-column label="顾客姓名" prop="memberName" align="center" />
        <el-table-column label="荣衔" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.honor, row.cardTypeH) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="级别" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.level, row.levelType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="加入期数" prop="periodWeek" align="center" />
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { fetchMembers, deleteMembers } from '@/api/agent'
import { fetchWeeks } from '@/api/home'
export default {
  name: 'AgentMemberList',
  components: { Pagination },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        memberNo: undefined,
        memberName: undefined,
        honor: undefined,
        level: undefined,
        fromWeek: undefined,
        toWeek: undefined
      },
      weeks: [],
      delLoading: false,
      initDict: {
        honor: 'cn.mimember.cardtype',
        level: 'cn.member.level'
      }
    }
  },
  computed: {
    levels() {
      return this.$ll(this.initDict.level)
    },
    honors() {
      return this.$ll(this.initDict.honor)
    }
  },
  mounted() {
    this.getWeeks()
  },
  methods: {
    getList() {
      if (this.listQuery.memberNo || this.listQuery.memberName) {
        this.loading = true
        this.listQuery.memberNo = this.listQuery.memberNo ? this.listQuery.memberNo.toUpperCase() : this.listQuery.memberNo
        fetchMembers(this.listQuery).then(res => {
          this.list = res.data.records
          this.total = +res.data.total || 0
          this.loading = false
        }).catch(err => {
          console.warn(err)
          this.loading = false
        })
      } else  {
        this.loading = false
        this.list = []
        this.total = 0
        this.$message.error('请输入顾客编号或者顾客姓名进行查询!')
      }
    },
    getWeeks() {
      fetchWeeks().then(res => {
        this.weeks = res.data
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    checkAllowDelete(row) {
      return row.allowDelete
    },
    handleDelete() {
      const selection = this.$refs['memberTable'].selection
      const selectMembers = selection.map(ele => ele.memberNo)
      if (!selectMembers || !selectMembers.length) {
        this.$message.error('至少要选择一个顾客')
      } else {
        this.$confirm('此操作将删除顾客点位, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delLoading = true
          deleteMembers({ members: selectMembers }).then(() => {
            this.$message.success('删除成功')
            this.delLoading = false
            this.getList()
          }).catch(() => {
            this.delLoading = false
            this.getList()
          })
        }).catch(() => { })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.memberNo-css {
  ::v-deep .el-input__inner {
    text-transform: uppercase
  }
}
.input-red {
  ::v-deep .el-form-item__label:before {
    content: '*';
    color: #ff4949;
    margin-right: 4px;
  }
}

</style>
