// 消费券预测
import request from '@/utils/request'

// getLIst
export function bdPreHisMemsByParameter(query) {
    return request({
        url: '/agent/BdPreHisMemsByParameter',
        method: 'GET',
        params: query
    })
}
// getLIst
export function getChinaWweek2(query) {
    return request({
        url: '/agent/getChinaWweek2',
        method: 'GET',
        params: query
    })
}
// getLIst
export function getChinaWweek3(query) {
    return request({
        url: '/agent/getChinaWweek3',
        method: 'GET',
        params: query
    })
}
