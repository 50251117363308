<!--
  @文件名:    提货单管理查看详情
  @file:    PoOutSendsInfo
  @author:
  @date:    2021/4/17 0017
  @time:    15:39
  @require:
-->
<template>
  <div class="PoOutSendsInfo">
    <app-breadcrumb />
    <div class="page-container out-detail-container">
      <el-form ref="outForm" :model="order" label-width="100px" size="small">
        <div class="pane-title">基本信息</div>
        <el-divider></el-divider>
        <el-form-item label="出库单号:">{{ order.outNo }}</el-form-item>
        <el-form-item label="收货人姓名:">{{ order.recName }}</el-form-item>
        <el-form-item label="联系电话:">{{ order.recPhone }}</el-form-item>
        <el-form-item label="收货地址:">{{ order.recAddr }}</el-form-item>
        <div class="pane-title">发货单明细</div>
        <el-table v-loading="loading" :data="list2" show-summary border stripe fit style="width:100%;margin: 10px 0 20px 0;">
          <el-table-column label="产品编号" prop="goodscode" align="center" />
          <el-table-column label="产品名称" prop="goodsname" align="center" />
          <el-table-column label="订货数量" prop="quantity" align="center" />
          <el-table-column label="已发货" prop="sendqty" align="center" />
          <el-table-column label="本次发货" prop="sendqtyNow" align="center" />
          <el-table-column label="欠货" prop="chaqty" align="center" />
        </el-table>
        <div class="pane-title">商品明细</div>
        <el-table
            v-loading="loading"
            :data="list3"
            row-key="id"
            :row-class-name="tableRowClassName"
            border
            stripe
            fit
            style="width:100%;"
        >
          <el-table-column label="产品号" prop="orderNo" align="center" />
          <el-table-column label="姓名" prop="userName" align="center" />
          <el-table-column label="编号" prop="userCode" align="center" />
          <el-table-column label="订单类型" align="center">
            <template slot-scope="{row}">
              <span>{{ $lt(initDict.allOrderType, row.orderType) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="产品编码" prop="goodscode" align="center" />
          <el-table-column label="产品名称" prop="goodsname" align="center" />
          <el-table-column label="订货数量" prop="quantity" align="center" />
          <el-table-column label="已发货" prop="sendqty" align="center" />
          <el-table-column label="赠品" prop="isgive" align="center">
            <template slot-scope="{row}">
              <span>{{ $lt(initDict.yesNo, row.isgive) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="本次发货" prop="sendqtyNow" align="center" />
          <el-table-column label="欠货" prop="chaqty" align="center" />
        </el-table>
        <div class="btns">
          <el-button type="default" size="small" icon="el-icon-close" @click="onBack">返回</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import {fetchPoOutSendsInfo} from "@/api/order/order-out"

export default {
  name: "PoOutSendsInfo",
  directives: {},
  components: {},
  props: [],
  data() {
    return {
      loading: false,
      order: {},
      pickout: {},
      list: [],
      list2: [],
      list3: [],
      initDict: {
        allOrderType: 'po.allordertype.cn',
        yesNo: 'yesno',
      }
    }
  },
  computed: {},
  mounted() {
    const id = this.$route.query.id
    if (!id || !id.length) {
      this.$router.go(-1)
    } else {
      this.loading = true
      fetchPoOutSendsInfo(id).then(res => {
        this.order = res.data.poOutSend
        this.list2 = res.data.poOutGoods
        this.list3 = res.data.poOrderGoods
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    tableRowClassName({row}) {
      if (row.details && row.details.length) {
        return 'order-row'
      } else if (row.goodsCode === '合计') {
        return 'summary-row'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.out-detail-container {
  .btns {
    margin: 20px 0;
    text-align: center;
  }
  .pay-amt {
    font-weight: bold;
    color: #077e25;
  }
  .el-table {
    .order-row {
      .cell {
        text-align: left;
        font-weight: bold;
      }
    }
    .summary-row {
      color: #FF0000;
    }
  }
}
</style>
