import request from '@/utils/request'

export function validateMember(query) {
  return request({
    url: '/order/uplevel/validate',
    method: 'get',
    params: query
  })
}

export function fetchGoods(query) {
  return request({
    url: '/order/uplevel/goods',
    method: 'get',
    params: query
  })
}
export function goodsAgent(query) {
  return request({
    url: '/order/uplevel/goodsAgent',
    method: 'get',
    params: query
  })
}

export function createOrder(data) {
  return request({
    url: '/order/uplevel/create',
    method: 'post',
    data
  })
}
export function fcBalance(query) {
  return request({
    url: 'order/uplevel/fcBalance',
    method: 'get',
    params: query
  })
}
export function CNAugustPromotionios(query) {
  return request({
    url: 'common/CNAugustPromotionios',
    method: 'get',
    params: query
  })
}
export function ecsecondcreate(data) {
  return request({
    url: '/order/second/create',
    method: 'post',
    data
  })
}
