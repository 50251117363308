import Layout from '@/views/layout'

export default [{
  path: '/member',
  component: Layout,
  redirect: '/member/profile',
  meta: {
    title: '业务查询'
  },
  children: [
    {
      path: 'index',
      component: () => import('@/views/agent/member/index'),
      name: 'AgentMemberList',
      meta: { title: '顾客资料查看' }
    },
    {
      path: 'bonus',
      component: () => import('@/views/member/bonus/index'),
      name: 'MemberBonus',
      meta: { title: '消费券查询' }
    },
    {
      path: 'agencyfee',
      component: () => import('@/views/member/agencyfee/index'),
      name: 'AgencyFee',
      meta: { title: '代办费查询' }
    },
    {
      path: 'profile',
      component: () => import('@/views/member/profile/index'),
      name: 'MemberProfile',
      meta: { title: '个人资料' }
    },
    {
      path: 'agentinfo',
      component: () => import('@/views/agent/agent-info/index'),
      name: 'AgentInfo',
      meta: { title: '代办处资料维护' }
    },
    {
      path: 'password',
      component: () => import('@/views/member/password/index'),
      name: 'MemberPassword',
      meta: { title: '密码修改' }
    },
    {
      path: 'prebonus',
      component: () => import('@/views/member/pre-bonus/index'),
      name: 'MemberPreBonus',
      meta: { title: '消费券预测' }
    },
    {
      path: 'recommends',
      component: () => import('@/views/member/point-recommend/index'),
      name: 'MemberPointRecommend',
      meta: { title: '海外旅游奖励' }
    },
    {
      path: 'bussiness',
      component: () => import('@/views/member/bussiness/index'),
      name: 'MemberBussinessList',
      meta: { title: '申请经销商' }
    },
    {
      path: 'bussiness-viewedit',
      component: () => import('@/views/member/bussiness/viewedit/index'),
      name: 'Viewedit',
      meta: { title: '申请经销商' },
      hidden: true
    },
    {
      path: 'bussiness-notice',
      component: () => import('@/views/member/bussiness/notice/index'),
      name: 'Notice',
      meta: { title: '申请经销商须知' },
      hidden: true
    }
  ]
}]
