<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container withdraw">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <el-form-item label="申请日期">
          <!-- 时间起始 -->
          <el-date-picker
                  v-model="listQuery.startTime"
                  :picker-options="this.start_Date"
                  type="date"
                  style="width: 130px;"
                  value-format="yyyy-MM-dd"
                  placeholder="开始日期"
          />&nbsp;&nbsp; - &nbsp;&nbsp;
          <!-- 时间结束 -->
          <el-date-picker
                  v-model="listQuery.endTime"
                  :picker-options="this.end_Date"
                  type="date"
                  style="width: 130px;"
                  value-format="yyyy-MM-dd"
                  placeholder="结束日期"
          />
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="listQuery.status1" placeholder="状态" clearable>
              <el-option v-for="item in statusList" :key="item.code" :label="$lt(initDict.status, item.code)"
                         :value="item.code"/>
            </el-select>
          </el-form-item>
          <el-form-item label="审核日期">
            <!-- 时间起始 -->
            <el-date-picker
                    v-model="listQuery.startTimeSecond"
                    :picker-options="this.start_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="开始日期"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 时间结束 -->
            <el-date-picker
                    v-model="listQuery.endTimeSecond"
                    :picker-options="this.end_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="handleApply">申领</el-button>
            <el-button :loading="cancelLoading" type="danger" icon="el-icon-delete" @click="handleCancel">取消订单
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="withdrawTable" :data="list" style="width: 100%" size="mini" border>
        <el-table-column type="selection" :selectable="checkAllowCancel" width="40" align="center"/>
        <el-table-column label="操作" width="50" align="center">
          <template slot-scope="{row}">
            <div class="opts">
              <el-tooltip v-if=" row.receiptStatus!==1" class="item" effect="dark" content="上传发票" placement="top">
                <i class="button el-icon-upload2" style="color: #67C23A;font-weight: 700" @click="handleUploadInvoice(row)"/>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="申请单号" prop="applNo" align="center"/>
        <el-table-column label="申领金额" prop="amount" width="80" align="center"/>
        <el-table-column label="综合管理费" prop="fees" width="80" align="center"/>
        <el-table-column label="实发金额" prop="sendAmt" width="80" align="center"/>
        <el-table-column label="申请时间" prop="createdTime" align="center"/>
        <el-table-column label="申请状态" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.status, row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="审核时间" prop="checkTime" align="center"/>
        <el-table-column label="失败原因" prop="falseReason" align="center"/>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
  </div>
</template>

<script>
import {fetchWithdraws, cancelAppls} from '@/api/account/withdraw'
import Pagination from '@/components/Pagination'
// import { isPaymentPassword } from '@/api/home'

export default {
  name: 'AccountWithdraw',
  components: {Pagination},
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        status: undefined
      },
      applyDates: undefined,
      checkDates: undefined,
      cancelLoading: false,
      initDict: {
        status: 'fi_ac_appl_status_cn'
      }
    }
  },
  computed: {
    statusList() {
      return this.$ll(this.initDict.status)
    }
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      const fromCreated = this.listQuery.startTime
      const toCreated = this.listQuery.endTime
      const fromChecked = this.listQuery.startTimeSecond
      const toChecked = this.listQuery.endTimeSecond
      this.loading = true
      fetchWithdraws({...this.listQuery, fromCreated, toCreated, fromChecked, toChecked}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    checkAllowCancel(row) {
      return row.status === '0'
    },
    handleApply() {
      // 当点击申领的时候判断
      this.$router.push('/account/withdraw-apply')
      // isPaymentPassword().then(res => {
      //   if (res.data) {
      //     this.$router.push({path: '/two-password'})
      //   }
      //   else {
      //     this.$router.push('/account/withdraw-apply')
      //   }
      // }).catch(err => {
      //   console.error(err)
      // })
    },
    handleCancel() {
      const selection = this.$refs['withdrawTable'].selection
      const selectAppls = selection.map(ele => ele.id)
      if (!selectAppls || !selectAppls.length) {
        this.$message.error('至少要选择一个记录')
      } else {
        this.$confirm('此操作将删除提现申请记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cancelLoading = true
          cancelAppls({ids: selectAppls}).then(() => {
            this.$message.success('取消成功')
            this.cancelLoading = false
            this.getList()
          }).catch(() => {
            this.cancelLoading = false
            this.getList()
          })
        }).catch(() => {
        })
      }
    },
    handleUploadInvoice(row) {
      this.$router.push(`/account/invoice-upload?id=${row.id}`)
    }
  }
}
</script>

<style>

</style>
