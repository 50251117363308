export default {
  el: {
    colorpicker: {
      confirm: 'Sahkan',
      clear: 'Kosongkan'
    },
    datepicker: {
      now: 'Sekarang',
      today: 'hari ini',
      cancel: 'batalkan',
      clear: 'Kosongkan',
      confirm: 'sahkan',
      selectDate: 'Pilih tarikh',
      selectTime: 'Pilih masa',
      startDate: 'Tarikh mula',
      startTime: 'masa permulaan',
      endDate: 'Tarikh akhir',
      endTime: 'Masa akhir',
      prevYear: 'Tahun lepas',
      nextYear: 'Tahun depan',
      prevMonth: 'bulan lepas',
      nextMonth: 'bulan depan',
      year: 'tahun',
      month1: 'Jan',
      month2: 'Feb',
      month3: 'Mar',
      month4: 'Apr',
      month5: 'May',
      month6: 'Jun',
      month7: 'Jul',
      month8: 'Aug',
      month9: 'Sep',
      month10: 'Oct',
      month11: 'Nov',
      month12: 'Dec',
      // week: '周次',
      weeks: {
        sun: 'SUN',
        mon: 'MON',
        tue: 'TUE',
        wed: 'WED',
        thu: 'THU',
        fri: 'FRI',
        sat: 'SAT'
      },
      months: {
        jan: 'Januari',
        feb: 'Februari',
        mar: 'Mar',
        apr: 'April',
        may: 'May',
        jun: 'Jun',
        jul: 'Julai',
        aug: 'August',
        sep: 'September',
        oct: 'Oktober',
        nov: 'November',
        dec: 'Desember'
      }
    },
    select: {
      loading: 'Memuatkan',
      noMatch: 'Tiada data yang sepadan',
      noData: 'Tiada data',
      placeholder: 'Sila pilih'
    },
    cascader: {
      noMatch: 'Tiada data yang sepadan',
      loading: 'Memuatkan',
      placeholder: 'Sila pilih',
      noData: 'Tiada data tersedia'
    },
    pagination: {
      goto: 'Pergi ke',
      pagesize: 'items / halaman',
      total: 'umum {total} items',
      pageClassifier: 'halaman'
    },
    messagebox: {
      title: 'Tip',
      confirm: 'Tentukan',
      cancel: 'Batalkan',
      error: 'Data input tidak sah!'
    },
    upload: {
      deleteTip: 'Tekan delete untuk padam',
      delete: 'padam',
      preview: 'papar imej',
      continue: 'Continue to upload'
    },
    table: {
      emptyText: 'Tiada data tersedia',
      confirmFilter: 'skrin',
      resetFilter: 'Tetap Semula',
      clearFilter: 'keseluruhan',
      sumText: 'total'
    },
    tree: {
      emptyText: 'Tiada data tersedia'
    },
    transfer: {
      noMatch: 'Tiada data yang sepadan',
      noData: 'Tiada data tersedia',
      titles: ['Senarai 1', 'Senarai 2'],
      filterPlaceholder: 'Sila masukkan kandungan gelintar',
      noCheckedFormat: 'umum {total} items',
      hasCheckedFormat: 'Pilih {checked}/{total} item'
    },
    image: {
      error: 'Memuatkan gagal'
    },
    pageHeader: {
      title: 'Kembali'
    },
    popconfirm: {
      confirmButtonText: 'Sahkan',
      cancelButtonText: 'Kosongkan'
    }
  }
}