import request from '@/utils/request'

export function fetchLoginMobile(query) {
  return request({
    url: '/agent/login-mobile',
    method: 'get',
    params: query
  })
}

export function sendSmsCode(data) {
  return request({
    url: '/agent/sms',
    method: 'post',
    data
  })
}
export function searchMember(query) {
  return request({
    url: '/agent/member/search',
    method: 'get',
    params: query
  })
}
export function getLinkNumber(query) {
  return request({
    url: '/agent/getLinkNumber',
    method: 'get',
    params: query
  })
}

export function validateRecommendNo(query) {
  return request({
    url: '/agent/member/validate',
    method: 'get',
    params: query
  })
}
// 手机号身份证号验证
export function validatePaperNoAndMobile(query) {
  return request({
    url: 'agent/validatePaperNoAndMobile',
    method: 'get',
    params: query
  })
}

export function registerMember(data) {
  return request({
    url: '/agent/member/register',
    method: 'post',
    data
  })
}

export function fetchMembers(query) {
  return request({
    url: '/agent/members',
    method: 'get',
    params: query
  })
}

export function deleteMembers(data) {
  return request({
    url: '/agent/member/delete',
    method: 'post',
    data
  })
}

// 获取代办处个人资料
export function agentProfileModify() {
  return request({
    url: '/agent/agentProfileModify',
    method: 'get'
  })
}

// 代办处个人资料编辑
export function agentProfileModifyEdit(data) {
  return request({
    url: '/agent/agentProfileModifyEdit',
    method: 'post',
    data
  })
}
