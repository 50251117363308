import request from '@/utils/request'

export function fetchOutOrders(query) {
    return request({
        url: '/order/out/list',
        method: 'get',
        params: query
    })
}

// 获取电商提货订单列表
export function fetchOutOrdersDs(query) {
    return request({
        url: '/order/out/listDs',
        method: 'get',
        params: query
    })
}

export function fetchOutOrderDetail(query) {
    return request({
        url: '/order/out/detail',
        method: 'get',
        params: query
    })
}

export function pickoutOrder(data) {
    return request({
        url: '/order/out/pickout',
        method: 'post',
        data
    })
}

export function pickoutDs(data) {
    return request({
        url: '/order/out/pickoutDs',
        method: 'post',
        data
    })
}

// 出库单管理
export function fetchPoOuts(query) {
    return request({
        url: '/order/out/poOuts',
        method: 'get',
        params: query
    })
}

//获取首页提货订单列表
export function fetlistHome(query) {
    return request({
        url: 'order/out/listHome',
        method: 'get',
        params: query
    })
}

// 获取首页电商提货订单列表
export function listHomeDs(query) {
    return request({
        url: 'order/out/listHomeDs',
        method: 'get',
        params: query
    })
}

// 取消出库
export function cancelPoOut(data) {
    return request({
        url: '/order/out/cancelPoOut',
        method: 'post',
        data
    })
}

// 出库单详情
export function poOutsInfo(id) {
    return request({
        url: '/order/out/poOutsInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}

// 出库单发货
export function fetchPoOutSends(params) {
    return request({
        url: '/order/out/poOutSends',
        method: 'get',
        params: params
    })
}

// 出库单发货详情
export function fetchPoOutSendsInfo(id) {
    return request({
        url: '/order/out/poOutSendsInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}

// 出库单发货-发货
export function writeDcSend(data) {
    return request({
        url: '/order/out/writeDcSend',
        method: 'post',
        data
    })
}

// 收货确认查询
export function agentPoOutSend(param) {
    return request({
        url: '/order/out/agentPoOutSend',
        method: 'get',
        params: param
    })
}

// 收货确认
export function agentPoOutSendRec(data) {
    return request({
        url: '/order/out/agentPoOutSendRec',
        method: 'post',
        data
    })
}

// 收货确认详情
export function agentPoOutSendInfo(id) {
    return request({
        url: '/order/out/agentPoOutSendInfo',
        method: 'get',
        params: {
            id: id
        }
    })
}

// 物流查看
export function logisticsQuery(query) {
    return request({
        url: '/order/out/logisticsQuery',
        method: 'get',
        params: query
    })
}

// 获取物流信息
export function fetchDcInfo(sendId, dcNo) {
    return request({
        url: '/order/out/getDcInfo',
        method: 'get',
        params: {
            sendId: sendId,
            dcNo: dcNo
        }
    })
}
