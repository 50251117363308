import request from '@/utils/request'

export function fetchMobile() {
  return request({
    url: '/member/mobile',
    method: 'get'
  })
}

export function fetchCustomerType() {
  return request({
    url: '/member/customer-type',
    method: 'get'
  })
}
