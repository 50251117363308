<!--代办费查询-->
<template>
  <div>
    <app-breadcrumb />
    <div class="page-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="80px" size="mini">
          <el-form-item label="期次:">
            <!-- 期数起始 -->
            <el-select
                v-model="listQuery.weekStart"
                v-loading="wWeekLoading"
                placeholder="开始期次"
                filterable
                style="width: 120px;"
            >
              <el-option v-for="i in listdata" :key="i.wWeek" :label="i.wWeek" :value="i.wWeek" />
            </el-select>
            &nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 期数结束 -->
            <el-select
                v-model="listQuery.weekEnd"
                v-loading="wWeekLoading"
                filterable
                style="width: 120px;"
                placeholder="结束期次"
            >
              <el-option v-for="i in listdata" :key="i.wWeek" :label="i.wWeek" :value="i.wWeek" />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button  :loading="btnLoading" type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%" size="mini" border fit stripe>
        <el-table-column :label="$t('期数')" prop="wWeek" align="center" />
        <el-table-column :label="$t('代办费')" prop="bonusAgent" align="center" />
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { getBdPreHisAgentByParameter, getChinaWweek1 } from '@/api/member/agencyfee'
export default {
  name: 'AgencyFee',
  components: { Pagination },
  data() {
    return {
      btnLoading: false,
      wWeekLoading:false,
      loading: false,
      listdata: [],
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        weekStart: '',
        weekEnd: ''
      },
      initDict: {
        compareFlagAll: 'type.compare'
      }
    }
  },
  created() {
    this.getWeeks()
  },
  computed: {
    compareFlagAlls() {
      return this.$ll(this.initDict.compareFlagAll)
    },
  },
  methods: {
    getList() {
      this.listLoading = true
      this.btnLoading = true
      this.loading = true
      getBdPreHisAgentByParameter({...this.listQuery}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total
        this.listLoading = false
        this.btnLoading = false
        this.loading = false
      }).catch(() => {
        this.$message.error('查询失败')
        this.listLoading = false
        this.btnLoading = false
        this.loading = false
      })
    },
    // 查询
    handleFilter() {
      this.getList()
    },
    // 期次
    getWeeks() {
      this.wWeekLoading = true
      this.btnLoading = true
      getChinaWweek1().then(res => {
        this.listdata = res.data
        this.listQuery.weekStart = res.data[0].wWeek
        this.listQuery.weekEnd = res.data[0].wWeek
        this.wWeekLoading = false
        this.btnLoading = false
      })
    },
  }
}
</script>

<style>

</style>
