import request from '@/utils/request'

export function fetchCaptcha(params) {
  return request({
    url: '/captcha',
    method: 'get',
    params
  })
}

export function fetchCurrentWeek() {
  return request({
    url: '/week',
    method: 'get'
  })
}

export function fetchWeeks() {
  return request({
    url: '/weeks',
    method: 'get'
  })
}

export function fetchConfig(query) {
  return request({
    url: '/config',
    method: 'get',
    params: query
  })
}

export function fetchHomepage() {
  return request({
    url: '/index',
    method: 'get'
  })
}

export function fetchRegions() {
  return request({
    url: '/regions',
    method: 'get'
  })
}

export function fetChildRegions(query) {
  return request({
    url: '/getChildRegionsToMap',
    method: 'get',
    params: query
  })
}

export function getChildRegionsTo(query) {
  return request({
    url: '/getChildRegionsTo',
    method: 'get',
    params: query
  })
}

export function getAlRegionsCode(query) {
  return request({
    url: '/getAlRegionsCode',
    method: 'get',
    params: query
  })
}

export function getAlRegionsIdList(query) {
  return request({
    url: '/getAlRegionsIdList',
    method: 'get',
    params: query
  })
}
export function memberprofile(query) {
  return request({
    url: '/member/profile',
    method: 'get',
    params: query
  })
}

// 获取经销商资料
export function memberProfileModify() {
  return request({
    url: '/member/memberProfileModify',
    method: 'get'
  })
}

// 发送验证码
export function memberSendVerification(query) {
  return request({
    url: '/member/memberSendVerification',
    method: 'get',
    params: query
  })
}

// 经销商个人资料编辑
export function memberProfileModifyEdit(data) {
  return request({
    url: '/member/memberProfileModifyEdit',
    method: 'post',
    data
  })
}
// 分红 基金 查看
export function memberAccountTurnover(query) {
  return request({
    url: '/account/memberAccountTurnover',
    method: 'GET',
    params: query
  })
}
// 判断是否进入二级支付密码页
export function isPaymentPassword(query) {
  return request({
    url: '/isPaymentPassword',
    method: 'GET',
    params: query
  })
}
// 二级支付密码保存
export function paymentPasswordSave(data) {
  return request({
    url: '/paymentPasswordSave',
    method: 'POST',
   data
  })
}
