import request from '@/utils/request'

export function validateMember(query) {
  return request({
    url: '/order/reconsume/validate',
    method: 'get',
    params: query
  })
}

export function fetchGoods(query) {
  return request({
    url: '/order/reconsume/goods',
    method: 'get',
    params: query
  })
}

export function createOrder(data) {
  return request({
    url: '/order/reconsume/create',
    method: 'post',
    data
  })
}
export function reconsumeairGel(query) {
  return request({
    url: '/order/reconsume/airGel',
    method: 'get',
    params: query
  })
}

export function checkNo(query) {
  return request({
    url: '/checkNo',
    method: 'get',
    params: query
  })
}


