import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '@/store'
import {getToken, removeToken} from '@/utils/auth'
import router from '@/router/index'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 300000
})

service.interceptors.request.use(
    config => {
        const token = getToken()
        if (token) {
            config.headers['Authorization'] = token
        }
        const lang = store.getters.language
        config.headers['lang'] = lang || (navigator.language || navigator.browserLanguage)
        return config;
    },
    error => Promise.reject(error)
)

service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            if (res.code === 401) {
                MessageBox.confirm('还没有登录或登录超时，或权限不足，请重新登录', '确认登录', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const redirect = router.history.current.fullPath
                    router.push({path: '/login?redirect=' + redirect || ''})
                })
            } else if (res.code === 900) {
                MessageBox.alert('页面更新，请重新登录', '页面更新', {
                    confirmButtonText: '确定',
                    showClose: false,
                    callback: () => {
                        removeToken()
                        window.location.reload()
                    }
                })
            } else {
                Message({
                    type: 'error',
                    message: res.message || '业务处理失败',
                    duration: 6000,
                    showClose: true
                })
            }
            console.log(res.message)
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error)
        Message({
            type: 'error',
            message: error.message || '网络请求异常'
        })
        return Promise.reject(error)
    }
)

export default service
