<template>
  <div>
    <p>购买套组CN-N000039-24每一套选择两个护膝 </p>
    <el-table
        border
        :data="HuXi_CN_list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CN_hxTypeChange(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "TZCN19508",
  data(){
    return{
      tableKey:0,
      giftlist:[],
      HuXi_CN_list:[
        {
          name:'健康护膝(M)',
          code:'CN-T001-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-47
        },{
          name:'健康护膝(L)',
          code:'CN-T001-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-48
        },{
          name:'健康护膝(XL)',
          code:'CN-T001-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:2,
          gift:'套组内产品',
          id:-49
        }
      ],
    }
  },
  props: {
    CN24_num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'TZCN19508_num': function (val) {
      if(val===0){
        this.HuYao_19508_list[0].quantity = 0
        this.HuYao_19508_list[1].quantity = 0
        this.HuYao_19508_list[2].quantity = 0
        this.HuYao_19508_list[3].quantity = 0
        this.HuXi_19508_list[0].quantity = 0
        this.HuXi_19508_list[1].quantity = 0
        this.HuXi_19508_list[2].quantity = 0
      }
    },
  },
  methods:{
    CN_hxTypeChange(){
      let num = 0
      this.HuXi_CN_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.CN24_num * 2 ){
        console.log(111)
        // this.$message.error('护膝所选尺码数量与购买套组(CN-N000039-24)数量不符')
      }
      this.$emit('CN_hxTypeChange', this.HuXi_CN_list)
      // console.log(this.HuXi_19508_list)
    }
  }
}
</script>

<style scoped>

</style>