<!--
  @文件名:    收货确认
  @file:    AgentPoOutSend
  @author:
  @date:    2021/4/18 0018
  @time:    17:33
  @require:
-->
<template>
  <div class="AgentPoOutSend">
    <app-breadcrumb/>
    <div class="page-container orders-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="100px" size="mini">
          <el-form-item label="出库单号:">
            <el-input v-model.trim="listQuery.outNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="物流单号:">
            <el-input v-model.trim="listQuery.dcNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button type="success" plain icon="el-icon-check" @click="confirmReceipt">确认收货</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
          v-loading="loading"
          :data="list"
          border
          fit
          size="mini"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            type="selection"
            align="center"
            :selectable="(row, index) => {return row.sendStatus == 1 && row.recStatus !== 1 && row.agentNo === this.userCode }"
        />
        <el-table-column label="操作" width="60" header-align="center" align="center">
          <template slot-scope="{row}">
            <div class="opts">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <el-button :loading="row.btnloading" style="padding: 0;border: none" icon="el-icon-search" @click="handleView(row)"/>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="出库单号" prop="outNo" align="center"/>
        <el-table-column label="代办处编号" prop="agentNo" align="center"/>
        <el-table-column label="收货人姓名" prop="recName" align="center"/>
        <el-table-column label="收货方式" prop="recType" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.recType, row.recType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="物流单号" prop="dcNo" align="center"/>
        <el-table-column label="创建日期" width="75" prop="createTime1" align="center"/>
        <el-table-column label="创建人" prop="createCode1" align="center"/>
        <el-table-column label="发货状态" prop="sendStatus" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.sendStatus, row.sendStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发货日期" width="75" prop="sendTime" align="center"/>
        <el-table-column label="发货人" prop="sendCode" align="center"/>
        <el-table-column label="收货状态" prop="recStatus" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.recStatus, row.recStatus) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
    <div class="page-container out-detail-container" id="printContent" style="display: none">
      <table style="border: 1px solid #333;width: 100%;border-collapse: collapse;margin-top: 20px">
<!--        <tr>
          <td colspan="6" style="border: 1px solid #333;font-size: 16px;color: #B3640d">филиала Компании FOHOW</td>
        </tr>-->
        <tr>
          <td style="border: 1px solid #333;">公司编号:</td>
          <td style="border: 1px solid #333;">{{ listData.companyCode }}</td>
          <td style="border: 1px solid #333;">出库单号:</td>
          <td style="border: 1px solid #333;">{{ listData.outNo }}</td>
          <td style="border: 1px solid #333;">代办处编号:</td>
          <td style="border: 1px solid #333;">{{ listData.agentNo }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #333;">发货人:</td>
          <td style="border: 1px solid #333;">{{ listData.sendCode }}</td>
          <td style="border: 1px solid #333;">收货人姓名:</td>
          <td style="border: 1px solid #333;">{{ listData.recCode }}</td>
          <td style="border: 1px solid #333;">收货人联系电话:</td>
          <td style="border: 1px solid #333;">{{ listData.outRecPhone }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #333;">创建人:</td>
          <td style="border: 1px solid #333;">{{ listData.createCode1 }}</td>
          <td style="border: 1px solid #333;">创建时间:</td>
          <td style="border: 1px solid #333;">{{ listData.createdTime }}</td>
          <td style="border: 1px solid #333;">物流单号:</td>
          <td style="border: 1px solid #333;">{{ listData.dcNo }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #333;">收货地址:</td>
          <td style="border: 1px solid #333;" colspan="5">{{ listData.outRecAddr }}</td>
        </tr>
      </table>
      <div class="pane-title" style="text-align: left">出发货单明细:</div>
      <original-table
          :table-data="list4"
          :column-name="['商品编码', '商品名称', '单位', '单价', '数量', '金额小计', '备注']"
          :column-set="['goodscode', 'name', 'specification', 'standardPrice', 'sendqty', 'totprice', 'memo']"
      />
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

import Pagination from "@/components/Pagination"
import {agentPoOutSend, agentPoOutSendInfo, agentPoOutSendRec} from "@/api/order/order-out"
import {mapGetters} from "vuex";
import OriginalTable from "@/components/OriginalTable";

export default {
  name: "AgentPoOutSend",
  directives: {},
  components: {OriginalTable, Pagination},
  props: [],
  data() {
    return {
      loading: false,
      list: [],
      list4: [],
      multipleSelection: [],
      total: 0,
      listQuery: {
        outNo: null,
        dcNo: null,
        limit: 10,
        page: 1
      },
      initDict: {
        sendStatus: 'status.po.sendstatus',
        recStatus: 'pd.recstatus',
        recType: 'poorder.rectype',
      },
      listData: {},
      listDataTitle: 'филиала Компании FOHOW'
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapGetters([
      'userCode'
    ]),
    sendStatus() {
      return this.$ll(this.initDict.sendStatus)
    }
  },
  // 监控data中的数据变化
  watch: {},
  created() {
    this.getList()
  },
  mounted() {

  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {
    getList() {
      this.loading = true
      agentPoOutSend({...this.listQuery}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    confirmReceipt() {
      if (this.multipleSelection.length) {
        const idlist = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          idlist.push(this.multipleSelection[i].id)
        }
        this.$confirm('确定收货?', '收货', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          agentPoOutSendRec(idlist).then(response => {
            console.log(response)
            this.$notify({
              title: '成功',
              message: '操作成功!',
              type: 'success'
            })
            this.getList()
          }).catch(err => {
            console.error(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '操作取消'
          })
        })
      } else {
        this.$message.error('请先勾选!')
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleView(row) {
      this.listData = {}
      this.$set(row, 'btnloading', true)
      agentPoOutSendInfo(row.id).then(response => {
        this.listData = response.data
        this.list4 = response.data.pdSendinfoItemsdto
        this.$nextTick(()=>{
          const newstr = document.getElementById('printContent').innerHTML
          sessionStorage.setItem('str', newstr)
          const {href} = this.$router.resolve({
            name: "Print",
            params: {str: newstr}
          });
          window.open(href, '', 'location=0; menubar=0; resizable=1; status=0; titlebar=; toolbar=0')
          this.$set(row, 'btnloading', false)
        })
      }).catch(err => {
        console.error(err)
        this.$set(row, 'btnloading', false)
      })
    },
    // 合并单元格
    // eslint-disable-next-line no-unused-vars
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (rowIndex === 0) {
        return [1, 6]
      } else if (rowIndex === 4 && columnIndex === 1) {
        return [1, 5]
      } else {
        return [1, 1]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.out-detail-container {
  .btns {
    margin: 20px 0;
    text-align: center;
  }

  .pay-amt {
    font-weight: bold;
    color: #077e25;
  }

  .el-table {
    .order-row {
      .cell {
        text-align: left;
        font-weight: bold;
      }
    }


    .summary-row {
      color: #FF0000;
    }
  }

  ::v-deep .el-table__footer-wrapper tbody td {
    color: red !important;
    font-weight: bold;
  }
}
</style>
