<template>
  <div class="settlement-table">
    <div v-for="(item, index) in list" class="month" :key="item.month">
      <div class="title">{{ item.month }}</div>
      <el-table :data="item.periods" :table-key="index" size="mini" style="width: 100%" stripe>
        <el-table-column prop="week" label="中国期次" width="80" />
        <el-table-column prop="week" label="起止日期" header-align="center">
          <template slot-scope="{row}">
            <span>{{ row.start }} ~ {{ row.end }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettlementPeriods',
  data() {
    return {
      year: 2025,
      list: [
        { month: '1月',
          periods: [
            { week: '202501', start: '2025-01-01', end: '2025-01-07' },
            { week: '202502', start: '2025-01-18', end: '2025-01-14' },
            { week: '202503', start: '2025-01-15', end: '2025-01-21' },
            { week: '202504', start: '2025-01-22', end: '2025-01-28' },
            { week: '202505', start: '2025-01-29', end: '2025-02-04' }
          ]
        },
        { month: '2月',
          periods: [
            { week: '202506', start: '2025-02-05', end: '2025-02-11' },
            { week: '202507', start: '2025-02-12', end: '2025-02-18' },
            { week: '202508', start: '2025-02-19', end: '2025-02-25' },
            { week: '202509', start: '2025-02-26', end: '2025-03-04' }
          ]
        },
        { month: '3月',
          periods: [
            { week: '202510', start: '2025-03-05', end: '2025-03-11' },
            { week: '202511', start: '2025-03-12', end: '2025-03-18' },
            { week: '202512', start: '2025-03-19', end: '2025-03-25' },
            { week: '202513', start: '2025-03-26', end: '2025-04-01' }
          ]
        },
        { month: '4月',
          periods: [
            { week: '202514', start: '2025-04-02', end: '2025-04-08' },
            { week: '202515', start: '2025-04-09', end: '2025-04-15' },
            { week: '202516', start: '2025-04-16', end: '2025-04-22' },
            { week: '202517', start: '2025-04-23', end: '2025-04-29' },
            { week: '202518', start: '2025-04-30', end: '2025-05-06' }
          ]
        },
        { month: '5月',
          periods: [

            { week: '202519', start: '2025-05-07', end: '2025-05-13' },
            { week: '202520', start: '2025-05-14', end: '2025-05-20' },
            { week: '202521', start: '2025-05-21', end: '2025-05-27' },
            { week: '202522', start: '2025-05-28', end: '2025-06-03s' }
          ]
        },
        { month: '6月',
          periods: [
            { week: '202523', start: '2025-06-04', end: '2025-06-10' },
            { week: '202524', start: '2025-06-11', end: '2025-06-17' },
            { week: '202525', start: '2025-06-18', end: '2025-06-24' },
            { week: '202526', start: '2025-06-25', end: '2025-07-01' }
          ]
        },
        { month: '7月',
          periods: [
            { week: '202527', start: '2025-07-02', end: '2025-07-08' },
            { week: '202528', start: '2025-07-09', end: '2025-07-15' },
            { week: '202529', start: '2025-07-16', end: '2025-07-22' },
            { week: '202530', start: '2025-07-23', end: '2025-07-29' },
            { week: '202531', start: '2025-07-30', end: '2025-08-05' }
          ]
        },
        { month: '8月',
          periods: [
            { week: '202532', start: '2025-08-06', end: '2025-08-12' },
            { week: '202533', start: '2025-08-13', end: '2025-08-19' },
            { week: '202534', start: '2025-08-20', end: '2025-08-26' },
            { week: '202535', start: '2025-08-27', end: '2025-09-02' }
          ]
        },
        { month: '9月',
          periods: [
            { week: '202536', start: '2025-09-03', end: '2025-09-09' },
            { week: '202537', start: '2025-09-10', end: '2025-09-16' },
            { week: '202538', start: '2025-09-17', end: '2025-09-23' },
            { week: '202539', start: '2025-09-24', end: '2025-09-30' }
          ]
        },
        { month: '10月',
          periods: [
            { week: '202540', start: '2025-10-01', end: '2025-10-07' },
            { week: '202541', start: '2025-10-08', end: '2025-10-14' },
            { week: '202542', start: '2025-10-15', end: '2025-10-21' },
            { week: '202543', start: '2025-10-22', end: '2025-10-28' },
            { week: '202544', start: '2025-10-29', end: '2025-11-04' }
          ]
        },
        { month: '11月',
          periods: [
            { week: '202545', start: '2025-11-06', end: '2025-11-11' },
            { week: '202546', start: '2025-11-12', end: '2025-11-18' },
            { week: '202547', start: '2025-11-19', end: '2025-11-25' },
            { week: '202548', start: '2025-11-26', end: '2025-12-02' }
          ]
        },
        { month: '12月',
          periods: [
            { week: '202549', start: '2025-12-03', end: '2025-12-09' },
            { week: '202550', start: '2025-12-10', end: '2025-12-16' },
            { week: '202551', start: '2025-12-17', end: '2025-12-23' },
            { week: '202552', start: '2025-12-24', end: '2025-12-30' },
            { week: '202553', start: '2025-12-31', end: '2025-01-06' }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.settlement-table {
  display: flex;
  flex-wrap: wrap;
  .month {
    width: calc(33% - 20px);
    padding: 0 10px;
    .title {
      height: 40px;
      line-height: 40px;
      background-color: #3e4f6a;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
}
</style>
