import Layout from '@/views/layout'

export default [{
  path: '/account',
  component: Layout,
  redirect: '/account/index',
  meta: {
    title: '账户管理'
  },
  children: [
    {
      path: 'recharge',
      component: () => import('@/views/account/recharge/index'),
      name: 'AccountRecharge',
      meta: { title: '充值管理' }
    },
    {
      path: 'recharge-apply',
      component: () => import('@/views/account/recharge/apply/index'),
      name: 'AccountRechargeApply',
      meta: { title: '账户充值' },
      hidden: true
    },
    {
      path: 'withdraw',
      component: () => import('@/views/account/withdraw/index'),
      name: 'AccountWithdraw',
      meta: { title: '推广服务费申领' }
    },
    {
      path: 'withdraw-apply',
      component: () => import('@/views/account/withdraw/apply/index'),
      name: 'AccountWithdrawApply',
      meta: { title: '推广服务费申领' },
      hidden: true
    },
    {
      path: 'invoice-upload',
      component: () => import('@/views/account/withdraw/invoice/index'),
      name: 'AccountInvoiceUpload',
      meta: { title: '上传发票' },
      hidden: true
    },
    {
      path: 'transfer',
      component: () => import('@/views/account/transfer/index'),
      name: 'AccountTransfer',
      meta: { title: '转账管理' }
    },
    {
      path: 'transfer-apply',
      component: () => import('@/views/account/transfer/apply/index'),
      name: 'AccountTransferApply',
      meta: { title: '转账' },
      hidden: true
    },
    {
      path: 'index',
      component: () => import('@/views/account/index'),
      name: 'AccountIndex',
      meta: { title: '账户查询' }
    }
  ]
}]
