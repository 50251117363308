<template>
  <div>
    <app-breadcrumb />
    <div class="page-container order-wrapper">
      <el-form ref="orderForm" :model="order" :rules="rules" label-width="140px" size="small">
        <div class="step1" v-if="activeIndex===0">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info" style="margin-bottom:10px;" />
          <el-form-item label="订货人编号:" prop="memberNo">
            <el-input style="max-width: 200px" v-model.trim="order.memberNo" clearable @blur.native.capture="handleNoCheck(order.memberNo)" />
            <span class="redspan">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNo }}</span>
          </el-form-item>
          <div v-show="open">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="请选择旋磁智感健康文胸产品尺码" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01057</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸70A/B</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details1.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01058</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸70C/D</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details2.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01059</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸75A/B</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details3.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01060</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸75C/D</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details4.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01061</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸80A/B</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details5.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01062</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸80C/D</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details6.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01063</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸85A/B</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details7.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01064</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">旋磁智感健康文胸85C/D</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details8.quantity"></el-input></div></el-col>
              </el-row>
            </el-tabs>
            <el-tabs v-model="activeName" >
              <el-tab-pane label="请选择远红外塑体衣产品尺码" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01051</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)M</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details9.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01052</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)L</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details10.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01053</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XL</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details11.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01054</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XXl</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details12.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01055</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">远红外塑体衣(平角款)XXXL</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details13.quantity"></el-input></div></el-col>
              </el-row>
            </el-tabs>
          </div>
          <div v-show="opens">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="请选择远健康磁性护腰产品尺码" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-02</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(M)</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details14.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-03</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(L)</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details15.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">CN-T003-01-04</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(XL)</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details16.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="12"><div class="grid-content bg-purple">QC(CN)01043</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">健康磁性护腰2.0(XXL)</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details17.quantity"></el-input></div></el-col>
              </el-row>
            </el-tabs>
          </div>
          <div v-show="open1">
            <el-tabs v-model="activeName" >
              <el-tab-pane label="(购买睡眠凝胶套组，请选择对应床垫尺码)" name="first" />
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="18"><div class="grid-content bg-purple">健康磁性多功能床垫1张(1.8米)、负离子远红被一张、健康磁性枕2个</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details24.quantity"></el-input></div></el-col>
              </el-row>
              <el-row :gutter="20" style="margin-bottom: 10px">
                <el-col :span="18"><div class="grid-content bg-purple">健康磁性多功能床垫1张(1.5米)、负离子远红被一张、健康磁性枕2个</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple"><el-input size="mini" v-model.trim="details25.quantity"></el-input></div></el-col>
              </el-row>
            </el-tabs>
          </div>
          <el-form-item>
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onNext">下一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step2" v-show="activeIndex===1">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <div class="pane-item">顾客编号: {{ order.memberNo }}</div>
          <p></p>
          <el-alert v-if="!isAgent && isCustomer" type="error" title="普通顾客不能报新零售单" />
          <template v-if="isAgent || !isCustomer">
            <div class="pane-title">商品列表</div>
            <el-divider></el-divider>
            <div class="summary">
              <span>金额:<em>{{ amount }}</em></span>
              <span>消费指数:<em>{{ pv }}</em></span>
            </div>
            <product-selector v-model="order.details" :data="goods" :loading="goodsLoading" />
            <el-form-item class="btns">
              <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onConfirm">下一步</el-button>
              <el-button v-if="isAgent" icon="el-icon-back" @click="onForward">上一步</el-button>
              <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
            </el-form-item>
          </template>
        </div>
        <div class="step3" v-if="activeIndex===2">
          <div class="pane-title">支付信息</div>
          <el-divider></el-divider>
          <el-form-item label="商品总金额:" style="margin-bottom:0;"><span>{{ amount }}</span></el-form-item>
          <el-form-item label="应支付金额:" style="margin-bottom:0;"><span class="amt">{{ amount }}</span></el-form-item>
          <el-form-item label="支付密码:" prop="password">
            <el-input v-model.trim="order.password" show-password placeholder="请填写您的支付密码" style="width:280px;"/>
          </el-form-item>
          <div class="pane-title">订单明细</div>
          <el-table
            :data="order.details"
            show-summary
            border
            fit
            stripe
            :summary-method="orderDetailSummaries"
            style="width:100%;margin:10px 0 20px 0;"
          >
            <el-table-column label="商品编码" prop="code" width="140" align="center" />
            <el-table-column label="商品名称" prop="name" header-align="center" show-overflow-tooltip />
            <el-table-column label="商品价格" prop="price" width="80" align="center"/>
            <el-table-column label="消费指数" prop="pv" width="80" align="center" />
            <el-table-column label="购买数量" prop="quantity" width="80" align="center"/>
            <el-table-column label="金额合计" width="100" align="center">
              <template slot-scope="{row}">
                <span>{{ (row.price * row.quantity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="消费指数合计" width="110" align="center">
              <template slot-scope="{row}">
                <span>{{ (row.pv * row.quantity).toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="是否赠品" width="80" align="center">
              <template>否</template>
            </el-table-column>
          </el-table>
          <el-form-item class="btns">
            <el-button :loading="loading" type="primary" icon="el-icon-right" @click="onSubmit">保存</el-button>
            <el-button icon="el-icon-back" @click="onForward2">上一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack">取消</el-button>
          </el-form-item>
        </div>
        <div class="step4" v-if="activeIndex===3">
          <div class="success">
            <img :src="require('@/assets/images/success.png')">
            <div class="success-txt">恭喜您，创建订单成功。</div>
            <div class="success-txt">订单号：<span>{{ orderNo }}</span></div>
            <div>
              <el-button icon="el-icon-s-home" @click="onBackHome">查看订单</el-button>
              <el-button icon="el-icon-refresh" @click="onContinue">继续订货</el-button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductSelector from '@/components/ProductSelector'
import { validateMember, fetchGoods, createOrder } from '@/api/order/re-purchase'
import { fetchCustomerType } from '@/api/member/profile'
import { searchMember } from '@/api/agent'
// import { isPaymentPassword } from '@/api/home'
export default {
  name: 'OrderRePurchase',
  components: { ProductSelector },
  data() {
    return {
      memberNo:'',
      activeIndex: 0,
      loading: false,
      isCustomer: undefined,
      order: {
        memberNo: undefined,
        password: undefined,
        details: []
      },
      rules: {
        memberNo: [{ required: true, message: '请填写订货人编号', trigger: 'change' }],
        password: [{ required: true, message: '请填写您的支付密码', trigger: 'change' }]
      },
      goods: [],
      goodsLoading: false,
      orderNo: undefined,
      ids:0,
      ids1:0,
      activeName:'first',
      open:false,
      opens:false,
      details1:{
        name:'旋磁智感健康文胸70A/B',
        code:'QC(CN)01057',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-1
      }, details2:{
        name:'旋磁智感健康文胸70C/D',
        code:'QC(CN)01058',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-2
      }, details3:{
        name:'旋磁智感健康文胸75A/B',
        code:'QC(CN)01059',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-3
      }, details4:{
        name:'旋磁智感健康文胸75C/D',
        code:'QC(CN)01060',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-4
      }, details5:{
        name:'旋磁智感健康文胸80A/B',
        code:'QC(CN)01061',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-5
      }, details6:{
        name:'旋磁智感健康文胸80C/D',
        code:'QC(CN)01062',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-6
      }, details7:{
        name:'旋磁智感健康文胸85A/B',
        code:'QC(CN)01063',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-7
      }, details8:{
        name:'旋磁智感健康文胸85C/D',
        code:'QC(CN)01064',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-8
      }, details9:{
        name:'远红外塑体衣(平角款)M',
        code:'QC(CN)01051',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-9
      }, details10:{
        name:'远红外塑体衣(平角款)L',
        code:'QC(CN)01052',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-10
      },details11:{
        name:'远红外塑体衣(平角款)XL',
        code:'QC(CN)01053',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-11
      }, details12:{
        name:'远红外塑体衣(平角款)XXL',
        code:'QC(CN)01054',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-12
      }, details13:{
        name:'远红外塑体衣(平角款)XXXL',
        code:'QC(CN)01055',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-13
      }, details14:{
        name:'健康磁性护腰2.0(M)',
        code:'CN-T003-01-02',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-14
      },details15:{
        name:'健康磁性护腰2.0(L)',
        code:'CN-T003-01-03',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-15
      },details16:{
        name:'健康磁性护腰2.0(XL)',
        code:'CN-T003-01-04',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-16
      },details17:{
        name:'健康磁性护腰2.0(XXL)',
        code:'QC(CN)01043',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-17
      },
      //睡眠套组
      open1:false,
      details24:{
        name:'健康磁性多功能床垫1张(QC(1.8米)、负离子远红被一张、健康磁性枕2个',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-21
      },
      details25:{
        name:'健康磁性多功能床垫1张(QC(1.5米)、负离子远红被一张、健康磁性枕2个',
        code:'',
        quantity: 0,
        point:0,
        price:0,
        proPrice:0,
        pv:0,
        id:-22
      },

    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'isAgent'
    ]),
    amount() {
      if (!this.order.details || !this.order.details.length) {
        return 0
      }
      return this.order.details.reduce((acc, cur) => {
        return cur.price * cur.quantity + acc
      }, 0).toFixed(2)
    },
    pv() {
      if (!this.order.details || !this.order.details.length) {
        return 0
      }
      return this.order.details.reduce((acc, cur) => {
        return cur.pv * cur.quantity + acc
      }, 0).toFixed(2)
    }
  },
  mounted() {
    if (!this.isAgent) {
      this.activeIndex = 1
      this.order.memberNo = this.userCode
      fetchCustomerType().then(res => {
        const customerType = res.data
        this.isCustomer = !customerType || customerType === '0'
        if (!this.isCustomer) {
          this.getGoodsData()
        }
      })
    }
  },
  watch:{
    'order.memberNo': function(val) {
      this.order.memberNo = val.toUpperCase()
    },
    goods:{
      handler() {
        for(let i=0;i<this.goods.length;i++){
          for(let j=0;j<this.goods[i].children.length;j++){
            if(this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity>0 && this.goods[i].children[j].name === '女神套装'){
              this.open = true
            }
            if(this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity>0 && this.goods[i].children[j].name === '康丽舒络套组'){
              this.opens = true
            }
            if(this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity===0 && this.goods[i].children[j].name === '女神套装'){
              this.open = false
              this.details1.quantity = 0
              this.details2.quantity = 0
              this.details3.quantity = 0
              this.details4.quantity = 0
              this.details5.quantity = 0
              this.details6.quantity = 0
              this.details7.quantity = 0
              this.details8.quantity = 0
              this.details9.quantity = 0
              this.details10.quantity = 0
              this.details11.quantity = 0
              this.details12.quantity = 0
              this.details13.quantity = 0
            }
            if(this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity===0 && this.goods[i].children[j].name === '康丽舒络套组'){
              this.opens = false
              this.details14.quantity = 0
              this.details15.quantity = 0
              this.details16.quantity = 0
              this.details17.quantity = 0
            }
            //养生睡眠套组
            if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity > 0){
              this.open1 = true
            }
            if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity === 0){
              this.open1 = false
              this.details24.quantity = 0
              this.details25.quantity = 0
            }

          }
        }
      },
      deep:true,
    }
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    onNext() {
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          validateMember({ memberNo: this.order.memberNo }).then(() => {
            this.activeIndex = 1
            this.loading = false
            this.getGoodsData()
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    getGoodsData() {
      this.goodsLoading = true
      fetchGoods().then(res => {
        this.goods = res.data
        this.goodsLoading = false
      }).catch(err => {
        console.warn(err)
        this.goodsLoading = false
      })
    },
    onForward() {
      this.activeIndex = 0
    },
    onConfirm() {
      for(let i=0;i<this.goods.length;i++) {
        for (let j = 0; j < this.goods[i].children.length; j++) {
          if (this.goods[i].children[j].id === 11554 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '女神套装') {
            this.ids = this.goods[i].children[j].quantity
            let ids1 = this.details1.quantity*1 + this.details2.quantity*1+this.details3.quantity*1+
                this.details4.quantity*1+this.details5.quantity*1+this.details6.quantity*1+this.details7.quantity*1+this.details8.quantity*1
            let ids2 = this.details9.quantity*1 + this.details10.quantity*1+this.details11.quantity*1+
                this.details12.quantity*1+this.details13.quantity*1
            if(this.ids != ids1 ){
              return  this.$message.error('旋磁智感健康文胸产品数量应与女神套装一致');
            }if(this.ids != ids2){
              return  this.$message.error('远红外塑体衣产品数量应与女神套装一致');
            }
          }
          if (this.goods[i].children[j].id === 11973 && this.goods[i].children[j].quantity > 0 && this.goods[i].children[j].name === '康丽舒络套组') {
            this.ids1 = this.goods[i].children[j].quantity
            let ids3 = this.details14.quantity*1 + this.details15.quantity*1+this.details16.quantity*1+ this.details17.quantity*1
            if(this.ids1 != ids3 ){
              return  this.$message.error('远健康磁性护腰产品数量应与康丽舒络套组一致');
            }
          }
          //  睡眠套组
          if(this.goods[i].children[j].id === 12793 && this.goods[i].children[j].quantity > 0){
            if(this.details24.quantity*1 + this.details25.quantity*1 < 1){
              return  this.$message.error('请选择套餐')
            }
            if(this.details24.quantity*1 + this.details25.quantity*1 != this.goods[i].children[j].quantity){
              return  this.$message.error('套餐数量应与购买数量一致')
            }
          }

        }
      }
      if(this.details1.quantity>0){
        this.order.details.push(this.details1)
      }
      if(this.details2.quantity>0){
        this.order.details.push(this.details2)
      }
      if(this.details3.quantity>0){
        this.order.details.push(this.details3)
      }
      if(this.details4.quantity>0){
        this.order.details.push(this.details4)
      }
      if(this.details5.quantity>0){
        this.order.details.push(this.details5)
      }
      if(this.details6.quantity>0){
        this.order.details.push(this.details6)
      }
      if(this.details7.quantity>0){
        this.order.details.push(this.details7)
      }
      if(this.details8.quantity>0){
        this.order.details.push(this.details8)
      }
      if(this.details9.quantity>0){
        this.order.details.push(this.details9)
      }
      if(this.details10.quantity>0){
        this.order.details.push(this.details10)
      }
      if(this.details11.quantity>0){
        this.order.details.push(this.details11)
      }
      if(this.details12.quantity>0){
        this.order.details.push(this.details12)
      }
      if(this.details13.quantity>0){
        this.order.details.push(this.details13)
      }
      if(this.details14.quantity>0){
        this.order.details.push(this.details14)
      }
      if(this.details15.quantity>0){
        this.order.details.push(this.details15)
      }
      if(this.details16.quantity>0){
        this.order.details.push(this.details16)
      }
      if(this.details17.quantity>0){
        this.order.details.push(this.details17)
      }
      //养生睡眠
      if(this.details24.quantity>0){
        this.order.details.push(this.details24)
      }
      if(this.details25.quantity>0){
        this.order.details.push(this.details25)
      }
      for (let b=0;b<this.order.details.length;b++){
        for(let c=b+1;c<this.order.details.length;c++){
          if(this.order.details[b] === this.order.details[c]){
            this.order.details.splice(c,1)
            c--
          }
          if(this.order.details[b].quantity === '0' ||this.order.details[b].quantity === null || this.order.details[b].quantity === undefined || this.order.details[b].quantity === '' ){
            this.order.details.splice(b,1)
          }
        }
      }
      if (!this.order.details || !this.order.details.length) {
        this.$message.error('至少要选择一个商品')
      } else {
        this.activeIndex = 2
        // isPaymentPassword().then(res => {
        //   if (res.data) {
        //     this.$router.push({path: '/two-password'})
        //   } else {
        //     this.activeIndex = 2
        //   }
        // }).catch(err => {
        //   console.error(err)
        // })
      }
    },
    onForward2() {
      this.activeIndex = 1
    },
    onSubmit() {
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          const details = this.order.details.map(ele => {
            return {
              goodsId: ele.id,
              quantity: ele.quantity
            }
          })
          createOrder({
            memberNo: this.order.memberNo,
            agentNo: this.isAgent ? this.userCode : '',
            orderType: 20,
            password: this.order.password,
            details
          }).then(res => {
            const { orderNo } = res.data
            this.orderNo = orderNo
            this.loading = false
            this.activeIndex = 3
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onBackHome() {
      this.$router.push('/order/index')
    },
    onContinue() {
      location.reload()
      if (this.isAgent) {
        this.activeIndex = 0
      } else {
        this.activeIndex = 1
        this.goods = []
        this.getGoodsData()
      }
      this.order = {
        memberNo: undefined,
        password: undefined,
        details: []
      }
      this.$nextTick(() => {
        this.$refs['orderForm'].resetFields()
      })
    },
    orderDetailSummaries(param) {
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === 5) {
          sums[index] = this.amount
        } else if (index === 6) {
          sums[index] = this.pv
        }
      })
      return sums
    },
    handleNoCheck(val) {
      if (val) {
        searchMember({ userCode: val.trim().toUpperCase() }).then(res => {
          if (res.data.length > 0) {
            this.memberNo = res.data[0].userName
          } else  {
            this.memberNo = '请输入正确的订货人编号'
          }
        })
      } else {
        this.memberNo = ''
      }
    }
  }
}
</script>

<style lang="scss">
.order-wrapper {
  .step2 {
    .summary {
      padding: 10px;
      font-size: 15px;
      font-weight: bold;
      span {
        padding-right: 20px;
        em {
          padding-left: 10px;
          font-size: 16px;
          font-style: normal;
          color: #FF0000;
        }
      }
    }
  }
  .step3 {
    .amt {
      color: #008800;
      font-weight: bold;
    }
  }
  .step4 {
    .success {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        max-height: 120px;
      }
      .success-txt {
        display: inline-block;
        padding: 10px 0;
        font-size: 14px;
        font-weight: bold;
        width: 420px;
        span {
          color: #FF0000;
          font-size: 16px;
        }
      }
      .el-button {
        margin-top: 20px;
      }
    }
  }
  .redspan {
    color: red;
  }
}
</style>
