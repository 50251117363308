import request from '@/utils/request'

export function fetchRecharges(query) {
  return request({
    url: '/account/recharge/list',
    method: 'get',
    params: query
  })
}

export function deleteRecharge(query) {
  return request({
    url: '/account/recharge/delete',
    method: 'get',
    params: query
  })
}

export function fetchBankAccounts() {
  return request({
    url: '/account/recharge/banks',
    method: 'get'
  })
}

export function fetchPayments() {
  return request({
    url: '/account/recharge/payments',
    method: 'get'
  })
}

export function applyRecharge(data) {
  return request({
    url: '/account/recharge/apply',
    method: 'post',
    data
  })
}

export function onlineRecharge(data) {
  return request({
    url: '/account/recharge/online',
    method: 'post',
    data
  })
}

export function checkTransCode(query) {
  return request({
    url: '/account/transfer/checkTransCode',
    method: 'get',
    params: query
  })
}

export function transCode(data) {
  return request({
    url: '/account/transfer/transCode',
    method: 'post',
    data
  })
}
