export default {
  'logo_title': '',
  'login': 'Daftar masuk',
  'login.username': 'Nama Pengguna',
  'login.password': 'Katalaluan',
  'login.verificationcode': 'Kod Pengesahan',
  'login.username.placeholder': 'Sila isi nama pengguna',
  'login.password.placeholder': 'Sila isi kata laluan',
  'login.verificationcode.placeholder': 'Kod pengesahan'
}
