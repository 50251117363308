<template>
  <div>
    <app-breadcrumb />
    <div class="page-container ship-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <el-form-item label="提货单号:">
            <el-input style="width:170px;" v-model.trim="listQuery.outNo"/>
          </el-form-item>
          <el-form-item label="出库单号:">
            <el-input style="width:170px;" v-model.trim="listQuery.sortNo"/>
          </el-form-item>
          <el-form-item label="订单编号:">
            <el-input style="width:170px;" v-model.trim="listQuery.orderNo"/>
          </el-form-item>
          <el-form-item label="经销商编号:">
            <el-input style="width:170px;" v-model.trim="listQuery.memberNo"/>
          </el-form-item>
          <el-form-item label="经销商姓名:">
            <el-input style="width:170px;" v-model.trim="listQuery.memberName"/>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%" size="mini" border>
<!--        <el-table-column label="操作" header-align="center" width="50" align="center">-->
<!--          <template slot-scope="{row}">-->
<!--            <el-tooltip class="item" effect="dark" content="详情" placement="top">-->
<!--              <el-button :loading="row.btnloading" style="padding: 0;border: none" icon="el-icon-search" @click="handleView(row)"/>-->
<!--            </el-tooltip>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="提货单号" min-width="120" prop="outNo" header-align="center" align="center"/>
        <el-table-column label="订单编号" min-width="120" prop="orderNo" header-align="center" align="center"/>
        <el-table-column label="单据类型" header-align="center" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出库单号" prop="sortNo" header-align="center" align="center"/>
        <el-table-column label="购货人" prop="memberNo" header-align="center" align="center"/>
        <el-table-column label="金额" prop="totalAmount" header-align="center" align="center"/>
        <el-table-column label="换货积分" prop="totalpointa0" header-align="center" align="center" />
        <el-table-column label="快递单号" align="center">
          <template slot-scope="scope">
              <span v-for="(val, idx) in scope.row.dcNo" :key="val" class="dcnocss" @click="buledcNO(scope, idx)">{{ val }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </template>
        </el-table-column>
        <el-table-column label="物流状态" header-align="center" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.status, row.signStatus) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" header-align="center" align="center"/>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
    <el-dialog
            title="基本信息"
            :visible.sync="buledialog"
            width="30%"
            :before-close="handleClose">
      <div style="text-align: left">
<!--        <p>出库单号: {{ buledialogdcNO.sortNo }}</p>-->
        <p>快递单号: {{ buledialogdcNO.dcNo }}</p>
        <p>收货人姓名: {{ buledialogdcNO.recName }}</p>
        <p>联系电话: {{ buledialogdcNO.recPhone }}</p>
        <p>收货地址: {{ buledialogdcNO.recAddr }}</p>
        <p>物流公司: {{ buledialogdcNO.dcName }}</p>
        <h3 v-if="dcNoBuleTableh3if" style="color: red">查询无结果，请隔段时间再查</h3>
          <p v-if="!dcNoBuleTableh3if">物流状态:<span style="color: red">{{ $lt(initDict.status, resdatastate) }}</span></p>
          <div v-if="!dcNoBuleTableh3if">
        <div v-for="kd in resdataKd" :key="kd.id">
          <p>{{ kd.time }} ------- {{ kd.context }}</p>
        </div>
      </div>
      </div>
      <el-button type="primary" @click="buledialog = false">确 定</el-button>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {fetchDcInfo, logisticsQuery} from "@/api/order/order-out"

export default {
  name: 'OrderShipList',
  components: {Pagination},
  data() {
    return {
      resdatastate: '',
      resdataKd: [],
      dcNoBuleTableh3if: false,
      buledialogdcNO: {},
      buledialog: false,
      loading: false,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        orderNo: '',
        outNo: '',
        memberNo: '',
        memberName: '',
        sortNo: ''
      },
      initDict: {
        orderType: 'po.allordertype.cn',
        status: 'logistics.status'
      }
    }
  },

  watch: {
    "listQuery.orderNo": function (val) {
      this.listQuery.orderNo = val.toUpperCase()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    buledcNO(scope, idx) {
      this.loading = true
      fetchDcInfo(scope.row.sendId, scope.row.dcNo[idx]).then(res => {
          if(res.success){
              this.buledialogdcNO = res.data.data
              if (res.data.dataKD.length) {
                  this.dcNoBuleTableh3if = false
                  this.resdataKd = res.data.dataKD.reverse()
                  this.resdatastate = res.data.json.state
              } else {
                  this.dcNoBuleTableh3if = true
              }
              this.buledialog = true
              this.loading = false
          } else {
              console.warn(res.message)
          }
      })

    },
    handleClose(done) {
      done()
    },
    getList() {
      this.loading = true
      logisticsQuery({...this.listQuery}).then(res => {
          for (let i = 0; i < res.data.records.length; i++) {
              if (res.data.records[i].dcNo) {
                    res.data.records[i].dcNo = res.data.records[i].dcNo.split('/')
              }
          }
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })

    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleView(row) {
      this.$router.push({path: '/order/ship-view', query: {id: row.id}})
    }
  }
  }
</script>

<style scoped lang="scss">
.dcnocss:hover {
    cursor: pointer;
}
.dcnocss {
    color: blue;
}
</style>
