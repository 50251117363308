<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container transfer">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <el-form-item label="选择日期">
            <!-- 时间起始 -->
            <el-date-picker
                v-model="listQuery.startTime"
                :picker-options="this.start_Date"
                type="date"
                style="width: 130px;"
                value-format="yyyy-MM-dd"
                placeholder="开始日期"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 时间结束 -->
            <el-date-picker
                v-model="listQuery.endTime"
                :picker-options="this.end_Date"
                type="date"
                style="width: 130px;"
                value-format="yyyy-MM-dd"
                placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item label="收款人编号:">
            <el-input v-model.trim="listQuery.inUserCode" placeholder="请填写收款人编号" style="width:140px;"/>
          </el-form-item>
          <el-form-item label="账户类型:">
            <el-select v-model="listQuery.accountType" placeholder="账户类型" clearable style="width:140px;">
              <el-option v-for="item in accountTypeList" :key="item.code" :label="$lt(initDict.acType, item.code)"
                         :value="item.code"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="handleApply">新建</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%" size="mini" border>
        <el-table-column label="付款人编号" prop="outUserCode" width="100" align="center"/>
        <el-table-column label="付款人姓名" prop="outUserName" width="100" align="center"/>
        <el-table-column label="账户类型" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.acType, row.acType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="付款金额" width="80" align="center">
          <template slot-scope="{row}">
            <span v-if="userCode === row.outUserCode">{{ row.money }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="收款人编号" prop="inUserCode" width="100" align="center"/>
        <el-table-column label="收款金额" width="80" align="center">
          <template slot-scope="{row}">
            <span v-if="userCode === row.inUserCode">{{ row.money }}</span>
            <span v-else>0</span>
          </template>
        </el-table-column>
        <el-table-column label="收款人姓名" prop="inUserName" width="100" align="center"/>
        <el-table-column label="类型" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.transType, row.transType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="130" align="center"/>
        <el-table-column label="状态" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.status, row.status) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="memo" align="center"/>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
  </div>
</template>

<script>
import {fetchTransfers} from '@/api/account/transfer'
import Pagination from '@/components/Pagination'
import {mapGetters} from 'vuex'
// import { isPaymentPassword } from '@/api/home'

export default {
  name: 'AccountTransfer',
  components: {Pagination},
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        accountType: undefined,
        inUserCode: undefined
      },
      applyDates: undefined,
      initDict: {
        acType: 'fiacinput.actype.cn',
        status: 'fi_ac_trans.type',
        transType: 'fi_ac_trans.status'
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCode'
    ]),
    accountTypeList() {
      return this.$ll(this.initDict.acType).filter(ele => ele.code === 'F$' || ele.code === 'H0')
    }
  },
  mounted() {
    // this.getList()
  },
  methods: {
    getList() {
      const fromDate = this.listQuery.startTime
      const toDate = this.listQuery.endTime
      this.loading = true
      fetchTransfers({...this.listQuery, fromDate, toDate}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleApply() {
      this.$router.push('/account/transfer-apply')
      // isPaymentPassword().then(res => {
      //   if (res.data) {
      //     this.$router.push({path: '/two-password'})
      //   }
      //   else {
      //     this.$router.push('/account/transfer-apply')
      //   }
      // }).catch(err => {
      //   console.error(err)
      // })
    }
  }
}
</script>

<style>

</style>
