<!--
  @文件名:    查看,编辑
  @file:    Viewedit
  @author:  LX
  @date:    2021/4/14
  @time:    10:44
  @require:
-->
<template>
    <div class="viewedit member-bussiness">
        <app-breadcrumb/>
        <div :class="{'inputdis':this.$route.query.view}">
            <el-form style="padding: 0 20px" v-loading="listLoading" :rules="rules" ref="ruleForm"
                     label-position="right"
                     label-width="200px" :model="listQuery">
                <h2 class="h2right">基本信息
                    <span style="display: inline-block; margin-left: 20px" class="sapnred">(申请人必须与营业执照经营人一致)</span>
                    <span style="display: inline-block; margin-left: 20px" class="sapnred">(所有</span><span
                            style="color: red; font-size: 20px">"*"</span><span class="sapnred">均为必填项)</span>
                </h2>
                <hr style="border: 1px dashed ; width: 100%;height: 1px;"/>
                <div style="padding: 0 20px">
                    <el-form-item v-if="companyCodeHeader" label="审核结果" prop="companyCode">
            <span
                    style="display: inline-block; float: left; font-size: 16px; font-weight: 500; margin-left: 20px">{{ $lt(initDict.checkState, listQuery.checkState) }}</span>
                    </el-form-item>
                    <el-form-item style="background: white; color: white" prop="actualOperator" :label="$t('申请人')">
                        <el-input class="inputw" :disabled="true" v-model.trim="listQuery.actualOperator" clearable/>
                    </el-form-item>
                    <!--          身份证照片-->
                    <div v-if="IFPaperType === 1">
                        <el-form-item style="background: white; color: white" prop="actualOperatorId"
                                      :label="$t('申请人身份证')">
                            <el-input class="inputw" :disabled="true" v-model.trim="listQuery.actualOperatorId"
                                      clearable/>
                        </el-form-item>
                        <el-form-item :label="$t('申请人国徽面')" v-if="addimage">
                            <el-image
                                    v-show="shijiguohui"
                                    style="width: 150px; height: 100px; float: left"
                                    :src="shijiguohui">
                            </el-image>
                        </el-form-item>
                        <el-form-item class="existItem" v-if="companyCodeIf" :label="$t('请上传申请人身份证照片')">
                            <span class="sapnred">(照片最大限制为5M,请上传国徽面,只能上传一张)</span>
                            <el-upload
                                    action="#"
                                    ref="upload"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview1"
                                    :on-remove="handleRemove1"
                                    :on-change="handleChange1"
                                    :auto-upload="false"
                                    :multiple="true"
                                    :file-list="fileList1"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible1">
                                <img width="100%" :src="dialogImageUrl1" alt=""/>
                            </el-dialog>
                        </el-form-item>
                        <el-form-item :label="$t('申请人人像面')" v-if="addimage">
                            <el-image
                                    v-show="shijirenxiang"
                                    style="width: 150px; height: 100px; float: left"
                                    :src="shijirenxiang">
                            </el-image>
                        </el-form-item>
                        <el-form-item class="existItem" v-if="companyCodeIf" :label="$t('请上传申请人身份证照片:')">
                            <span class="sapnred">(照片最大限制为5M,请上传人像面,只能上传一张)</span>
                            <el-upload
                                    action="#"
                                    ref="upload"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview2"
                                    :on-remove="handleRemove2"
                                    :on-change="handleChange2"
                                    :auto-upload="false"
                                    :multiple="true"
                                    :file-list="fileList2"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible2">
                                <img width="100%" :src="dialogImageUrl2" alt=""/>
                            </el-dialog>
                        </el-form-item>
                    </div>
                    <!--          护照照片-->
                    <div v-if="IFPaperType === 2">
                        <el-form-item style="background: white; color: white" prop="actualOperatorId"
                                      :label="$t('申请人护照')">
                            <el-input class="inputw" :disabled="true" v-model.trim="listQuery.actualOperatorId"
                                      clearable/>
                        </el-form-item>
                        <el-form-item :label="$t('申请人护照')" v-if="addimage">
                            <el-image
                                    v-show="huzhao"
                                    style="width: 150px; height: 100px; float: left"
                                    :src="huzhao">
                            </el-image>
                        </el-form-item>
                        <el-form-item class="existItem" v-if="companyCodeIf" :label="$t('请上传申请人护照照片')">
                            <span class="sapnred">(照片最大限制为5M,只能上传一张)</span>
                            <el-upload
                                    action="#"
                                    ref="upload"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview6"
                                    :on-remove="handleRemove6"
                                    :on-change="handleChange6"
                                    :auto-upload="false"
                                    :multiple="true"
                                    :file-list="fileList6"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible6">
                                <img width="100%" :src="dialogImageUrl6" alt=""/>
                            </el-dialog>
                        </el-form-item>
                    </div>
                    <el-form-item prop="tel" :label="$t('申请人联系电话')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.tel" clearable/>
                    </el-form-item>
                    <el-form-item v-if="!companyCodeIf" :label="$t('备注')">
                        <el-input :disabled="multiple" class="inputw" type="textarea" v-model="listQuery.remark"/>
                    </el-form-item>
                </div>
                <h2 class="h2right">营业执照信息</h2>
                <hr style="border: 2px dashed #000000; width: 100%;height: 1px;"/>
                <div style="padding: 0 20px">
                    <el-form-item prop="legalName" :label="$t('营业执照名称')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.legalName" clearable/>
                    </el-form-item>
                    <el-form-item prop="businessLicenseNo" :label="$t('统一社会信用代码')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.businessLicenseNo"
                                  clearable/>
                    </el-form-item>
                    <el-form-item class="existItem" label="有效期:">
                        <!-- 时间起始 -->
                        <el-date-picker
                                v-model="listQuery.startTermDate"
                                :disabled="multiple"
                                type="datetime"
                                style="width: 200px; float: left"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期"
                                :clearable="false"
                        />
                        <span style="display: inline-block; float: left">一</span>
                        <!-- 时间结束 -->
                        <el-date-picker
                                v-model="listQuery.endTermDate"
                                type="datetime"
                                :clearable="false"
                                :disabled="multiple"
                                style="width: 200px; float: left"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期"
                        />
                    </el-form-item>
                    <el-form-item class="existItem" v-if="companyCodeIf" label="注册地区">
                        <el-col :span="6">
                            <el-select
                                    v-model="countrymodel"
                                    value-key="regionId"
                                    placeholder="请选择国家"
                                    :disabled="multiple"
                                    clearable
                                    @change="countrychange"
                            >
                                <el-option
                                        v-for="item in addchoicecountry"
                                        :key="item.regionId"
                                        :label="item.regionName"
                                        :value="item"
                                />
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <el-select
                                    v-model="provincemodel"
                                    value-key="regionId"
                                    placeholder="请选择省份"
                                    :disabled="multiple"
                                    clearable
                                    @change="provincechange"
                            >
                                <el-option
                                        v-for="item in addchoiceprovince"
                                        :key="item.regionId"
                                        :label="item.regionName"
                                        :value="item"
                                />
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <el-select
                                    v-model="citymodel"
                                    value-key="regionId"
                                    placeholder="请选择市"
                                    :disabled="multiple"
                                    clearable
                                    @change="citychange"
                            >
                                <el-option
                                        v-for="item in addchoicecity"
                                        :key="item.regionId"
                                        :label="item.regionName"
                                        :value="item"
                                />
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <el-select
                                    v-model="areamodel"
                                    :disabled="multiple"
                                    placeholder="请选择区/县"
                                    clearable>
                                <el-option
                                        v-for="item in addchoicearea"
                                        placeholder="请选择区/县"
                                        :key="item.regionId"
                                        :label="item.regionName"
                                        :value="item.regionCode"
                                />
                            </el-select>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="regisAddr" :label="$t('注册地址')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.regisAddr" clearable/>
                    </el-form-item>
                    <el-form-item :label="$t('营业执照')" v-if="addimage">
                        <el-image
                                v-show="yingyezhizhao"
                                style="width: 150px; height: 100px; float: left"
                                :src="yingyezhizhao">
                        </el-image>
                    </el-form-item>
                    <el-form-item class="existItem" v-if="companyCodeIf" :label="$t('请上传营业执照照片:')">
                        <span class="sapnred">(照片最大限制为5M,只能上传一张)</span>
                        <el-upload
                                action="#"
                                ref="upload"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview3"
                                :on-remove="handleRemove3"
                                :on-change="handleChange3"
                                :auto-upload="false"
                                :multiple="true"
                                :file-list="fileList3"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible3">
                            <img width="100%" :src="dialogImageUrl3" alt=""/>
                        </el-dialog>
                    </el-form-item>
                </div>
                <h2 class="h2right">银行账户信息</h2>
                <hr style="border: 2px dashed #000000; width: 100%;height: 1px;"/>
                <div style="padding: 0 20px">
                    <el-form-item class="existItem" label="银行账户类别">
                        <el-select
                                v-model="listQuery.accountType"
                                :disabled="multiple"
                                class="inputw"
                        >
                            <el-option v-for="i in accountTypes" :key="i.code"
                                       :label="$lt(initDict.accountType, i.code)"
                                       :value="Number(i.code)"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="listQuery.accountType === 1" prop="accountBank" :label="$t('开户银行')">
                        <el-input
                                class="inputw"
                                :disabled="multiple"
                                v-model.trim="listQuery.accountBank"
                                clearabled
                        />
                    </el-form-item>
                    <el-form-item v-if="listQuery.accountType === 0" prop="accountBank" :label="$t('开户银行')">
                        <el-select
                                class="inputw"
                                :disabled="multiple"
                                v-model="listQuery.accountBank"
                        >
                            <el-option v-for="i in accountBanks" :key="i.code"
                                       :label="$lt(initDict.accountBank, i.code)"
                                       :value="i.code"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="accountName" :label="$t('户名')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.accountName" clearable/>
                    </el-form-item>
                    <el-form-item prop="accountCode" :label="$t('账号')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.accountCode" clearable/>
                    </el-form-item>
                    <el-form-item prop="accountBranch" :label="$t('开户银行具体支行')">
                        <el-input :disabled="multiple" class="inputw" v-model.trim="listQuery.accountBranch" clearable/>
                        <span v-if="companyCodeIf" style="color: red; float: left">--请务必核对清楚</span>
                    </el-form-item>
                    <div v-if="addimage">
                        <el-form-item v-if="listQuery.accountType === 0" :label="$t('银行卡照片')">
                            <el-image
                                    v-if="yinhangka && listQuery.accountType === 0"
                                    style="width: 150px; height: 100px; float: left"
                                    :src="yinhangka">
                            </el-image>
                        </el-form-item>
                    </div>
                    <el-form-item class="existItem" v-if="listQuery.accountType === 0 && companyCodeIf"
                                  :label="$t('请上传银行卡照片')">
                        <span class="sapnred">(照片最大限制为5M,只能上传一张)</span>
                        <el-upload
                                action="#"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview4"
                                :on-remove="handleRemove4"
                                :on-change="handleChange4"
                                :auto-upload="false"
                                :multiple="true"
                                :file-list="fileList4"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible4">
                            <img width="100%" :src="dialogImageUrl4" alt=""/>
                        </el-dialog>
                    </el-form-item>
                    <div v-if="addimage">
                        <el-form-item v-if="listQuery.accountType === 1" :label="$t('开户许可证照片')">
                            <el-image
                                    v-if="kaihuxukezheng && listQuery.accountType === 1"
                                    style="width: 150px; height: 100px; float: left"
                                    :src="kaihuxukezheng">
                            </el-image>
                        </el-form-item>
                    </div>
                    <el-form-item class="existItem" v-if="listQuery.accountType === 1 && companyCodeIf"
                                  :label="$t('请上传开户许可证照片')">
                        <span class="sapnred">(照片最大限制为5M,只能上传一张)</span>
                        <el-upload
                                action="#"
                                ref="upload"
                                list-type="picture-card"
                                :on-preview="handlePictureCardPreview5"
                                :on-remove="handleRemove5"
                                :on-change="handleChange5"
                                :auto-upload="false"
                                :multiple="true"
                                :file-list="fileList5"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible5">
                            <img width="100%" :src="dialogImageUrl5" alt=""/>
                        </el-dialog>
                    </el-form-item>
                    <el-form-item v-if="!companyCodeIf" :label="$t('客服备注')">
                        <el-input disabled class="inputw" type="textarea" v-model="listQuery.serviceRemark"/>
                    </el-form-item>
                    <el-form-item v-if="!companyCodeIf" label="审核结果" prop="companyCode">
            <span
                    style="display: inline-block; float: left; font-size: 16px; font-weight: 500; margin-left: 20px">{{ $lt(initDict.checkState, listQuery.checkState) }}</span>
                    </el-form-item>
                </div>
                <el-button class="el-icon-back" type="info" @click="returnbtn('ruleForm')">返回</el-button>
                <el-button v-if="companyCodeIf" :loading="searchloading" class="el-icon-edit" type="primary"
                           @click="savebtn('ruleForm')">保存
                </el-button>
            </el-form>
        </div>
    </div>
</template>
<script>
    import {fetChildRegions, getAlRegionsIdList} from '@/api/home'
    import axios from 'axios'
    import {getToken} from '@/utils/auth'
    import {
        checkMemberApplyIsExist,
        getImages,
        getMemberInfo,
        registeredReseller
    } from '@/api/member/bussiness'
    import {mapGetters} from "vuex";

    export default {
        name: "Viewedit",
        data() {
            return {
                IFPaperType: undefined, // 判断身份证还是护照照片显示
                searchloading: false,
                ids: {
                    id: '',
                    imgIdArr: []
                },
                // rules判断
                rules: {
                    accountCode: [
                        {required: true, message: '请填写账号', trigger: ['blur', 'change']}
                    ],
                    actualOperator: [
                        {required: true, message: '请填写申请人', trigger: ['blur', 'change']}
                    ],
                    actualOperatorId: [
                        {required: true, message: '请填写申请人信息', trigger: ['blur', 'change']}
                    ],
                    tel: [
                        {required: true, message: '请填写联系电话', trigger: ['blur', 'change']}
                    ],
                    legalName: [
                        {required: true, message: '请填写营业执照名称', trigger: ['blur', 'change']}
                    ],
                    businessLicenseNo: [
                        {required: true, message: '请填写统一社会信用代码', trigger: ['blur', 'change']}
                    ],
                    regisAddr: [
                        {required: true, message: '请填写注册地址', trigger: ['blur', 'change']}
                    ],
                    accountBank: [
                        {required: true, message: '请填写开户银行', trigger: ['blur', 'change']}
                    ],
                    accountName: [
                        {required: true, message: '请填写户名', trigger: ['blur', 'change']}
                    ],
                    // AccountCode: [
                    //   {required: true, message: '请填写账号', trigger: ['blur', 'change']}
                    // ],
                    accountBranch: [
                        {required: true, message: '请填写开户银行具体支行', trigger: ['blur', 'change']}
                    ]
                },
                //----------------------------------------------------------
                listLoading: false,
                listQuery: {
                    actualOperator: '',
                    actualOperatorId: '',
                    tel: '',
                    businessLicenseNo: '',
                    regisAddr: '',
                    legalName: '',
                    accountName: '',
                    accountCode: '',
                    accountBranch: '',
                    regionCode: '', // 保存时提交的地区
                    accountType: undefined, // 默认银行账户类别
                    accountBank: '', // 开户银行值
                },
                //图片----------------------------------------------------------
                kaihuxukezheng: '',
                yinhangka: '',
                shijiguohui: '',
                shijirenxiang: '',
                yingyezhizhao: '',
                huzhao: '',
                imgid: '',
                headers: {
                    'Authorization': getToken()
                },
                param: "",
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                dialogVisible4: false,
                dialogVisible5: false,
                dialogVisible6: false,
                dialogImageUrl1: "",
                dialogImageUrl2: "",
                dialogImageUrl3: "",
                dialogImageUrl4: "",
                dialogImageUrl5: "",
                dialogImageUrl6: "",
                fileList1: [],
                fileList2: [],
                fileList3: [],
                fileList4: [],
                fileList5: [],
                fileList6: [],
                // ----------------------------------------------
                countrymodel2: '',// 国家要传code值
                provincemodel2: '',// 省要传code值
                citymodel2: '',// 市要传code值
                disabled: false, // 所在地区显示
                countrymodel: '', // 新增选择国家model
                provincemodel: '', // 新增选择省存储model
                citymodel: '', // 新增选择市存储model
                areamodel: '', // 新增选择县存储model
                // -----------------------------------------------------
                addchoicecountry: [], // 新增选择国家存储
                addchoiceprovince: [], // 新增选择省存储
                addchoicecity: [], // 新增选择市存储
                addchoicearea: [], // 新增选择县存储
                // $ll----------------------------------------
                initDict: {
                    checkState: 'sysaftersale.examineresult',
                    accountType: 'account.type',
                    accountBank: 'bank.list'
                },
                // 查看判断-----------------------
                multiple: false,
                companyCodeIf: false,
                companyCodeHeader: false,
                addimage: false // 新增时小图片隐藏
            }
        },
        created() {
            this.getList()
            this.zerochange()
            this.dogetMemberInfo()
        },
        computed: {
            ...mapGetters([
                'userCode',
            ]),
            checkStates() {
                return this.$ll(this.initDict.checkState)
            },
            accountTypes() {
                return this.$ll(this.initDict.accountType)
            },
            accountBanks() {
                return this.$ll(this.initDict.accountBank)
            }
        },
        methods: {
            // 获取信息
            dogetMemberInfo() {
                this.listLoading = true
                getMemberInfo({'userCode': this.userCode}).then(res => {
                    if (res.data.paperType === '1') {
                        this.IFPaperType = 1
                    } else if (res.data.paperType === '2') {
                        this.IFPaperType = 2
                    }
                    this.listQuery.actualOperator = res.data.memberName
                    this.listQuery.actualOperatorId = res.data.paperNo
                    this.listLoading = false
                })
            },
            // 图片存进formdata里面 发送axios请求给后台 + 删除
            doimgbtn() {
                if (this.listQuery.accountType === 0) {
                    this.fileList5[0] = ''
                } else if (this.listQuery.accountType === 1) {
                    this.fileList4[0] = ''
                }
                this.ids.imgIdArr = []
                const deleteId = this.imgid || this.$route.query.id
                const formData = new FormData()
                if (this.fileList1[0] && this.fileList1[0].raw) {
                  formData.append('shijiguohui', this.fileList1[0].raw)
                }
                if (this.fileList2[0] && this.fileList2[0].raw) {
                  formData.append('shijirenxiang', this.fileList2[0].raw)
                }
                if (this.fileList3[0] && this.fileList3[0].raw) {
                  formData.append('yingyezhizhao', this.fileList3[0].raw)
                }
                if (this.fileList4[0] && this.fileList4[0].raw) {
                  formData.append('yinhangka', this.fileList4[0].raw)
                }
                if (this.fileList5[0] && this.fileList5[0].raw) {
                  formData.append('kaihuxukezheng', this.fileList5[0].raw)
                }
                if (this.fileList6[0] && this.fileList6[0].raw) {
                  formData.append('huzhao', this.fileList6[0].raw)
                }
                formData.append('id', deleteId)
                // 发送保存图片请求
                axios({
                  url: process.env.VUE_APP_BASE_API + '/agent/saveImg',
                  method: 'post',
                  // withCredentials: true,
                  data: formData,
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': getToken()
                  }
                }).then((uploadRes) => {
                  if (uploadRes.data.success) {
                    this.$message.success('保存成功')
                    this.$router.push('/member/bussiness')
                    this.searchloading = false
                  } else {
                    this.$message.error('保存失败')
                    this.searchloading = false
                    return false
                  }
                }).catch(() => {
                  this.$message.error('保存失败')
                  this.searchloading = false
                  return false
                })
            },
            //判断之正确与否 在存值
            handleChange1(file, fileList) {
                this.fileList1 = []
                //  限制单张上传，超过限制即覆盖
                if (fileList.length > 1) {
                    fileList.splice(0, 1)
                }
                // 上传文件之前校验图片格式和大小 //  校验
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    this.$message.error(this.$t('upload.photo.size'))
                    fileList.pop()
                    return false
                }
                const isImage = file.raw.type.includes('image')
                if (!isImage) {
                    this.$message.error(this.$t('upload.photo.format'))
                    fileList.pop()
                    return false
                }
                this.fileList1.push(file)
            },
            handleChange2(file, fileList) {
                this.fileList2 = []
                //  限制单张上传，超过限制即覆盖
                if (fileList.length > 1) {
                    fileList.splice(0, 1)
                }
                // 上传文件之前校验图片格式和大小 //  校验
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    this.$message.error(this.$t('upload.photo.size'))
                    fileList.pop()
                    return false
                }
                const isImage = file.raw.type.includes('image')
                if (!isImage) {
                    this.$message.error(this.$t('upload.photo.format'))
                    fileList.pop()
                    return false
                }
                this.fileList2.push(file)
            },
            handleChange3(file, fileList) {
                this.fileList3 = []
                //  限制单张上传，超过限制即覆盖
                if (fileList.length > 1) {
                    fileList.splice(0, 1)
                }
                // 上传文件之前校验图片格式和大小 //  校验
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    this.$message.error(this.$t('upload.photo.size'))
                    fileList.pop()
                    return false
                }
                const isImage = file.raw.type.includes('image')
                if (!isImage) {
                    this.$message.error(this.$t('upload.photo.format'))
                    fileList.pop()
                    return false
                }
                this.fileList3.push(file)
            },
            handleChange4(file, fileList) {
                this.fileList4 = []
                //  限制单张上传，超过限制即覆盖
                if (fileList.length > 1) {
                    fileList.splice(0, 1)
                }
                // 上传文件之前校验图片格式和大小 //  校验
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    this.$message.error(this.$t('upload.photo.size'))
                    fileList.pop()
                    return false
                }
                const isImage = file.raw.type.includes('image')
                if (!isImage) {
                    this.$message.error(this.$t('upload.photo.format'))
                    fileList.pop()
                    return false
                }
                this.fileList4.push(file)
            },
            handleChange5(file, fileList) {
                this.fileList5 = []
                //  限制单张上传，超过限制即覆盖
                if (fileList.length > 1) {
                    fileList.splice(0, 1)
                }
                // 上传文件之前校验图片格式和大小 //  校验
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    this.$message.error(this.$t('upload.photo.size'))
                    fileList.pop()
                    return false
                }
                const isImage = file.raw.type.includes('image')
                if (!isImage) {
                    this.$message.error(this.$t('upload.photo.format'))
                    fileList.pop()
                    return false
                }
                this.fileList5.push(file)
            },
            handleChange6(file, fileList) {
                this.fileList6 = []
                //  限制单张上传，超过限制即覆盖
                if (fileList.length > 1) {
                    fileList.splice(0, 1)
                }
                // 上传文件之前校验图片格式和大小 //  校验
                const isLt5M = file.size / 1024 / 1024 < 5
                if (!isLt5M) {
                    this.$message.error(this.$t('upload.photo.size'))
                    fileList.pop()
                    return false
                }
                const isImage = file.raw.type.includes('image')
                if (!isImage) {
                    this.$message.error(this.$t('upload.photo.format'))
                    fileList.pop()
                    return false
                }
                this.fileList6.push(file)
            },
            //判断之正确与否 在存值 end-------------------------------------
            //上传的图片后显示该图片值
            handlePictureCardPreview1(file) {
                this.dialogImageUrl1 = file.url
                this.dialogVisible1 = true
            },
            handlePictureCardPreview2(file) {
                this.dialogImageUrl2 = file.url
                this.dialogVisible2 = true
            },
            handlePictureCardPreview3(file) {
                this.dialogImageUrl3 = file.url
                this.dialogVisible3 = true
            },
            handlePictureCardPreview4(file) {
                this.dialogImageUrl4 = file.url
                this.dialogVisible4 = true
            },
            handlePictureCardPreview5(file) {
                this.dialogImageUrl5 = file.url
                this.dialogVisible5 = true
            },
            handlePictureCardPreview6(file) {
                this.dialogImageUrl6 = file.url
                this.dialogVisible6 = true
            },
            //上传的图片后显示该图片值end------------------
            handleRemove1() {
                this.fileList1[0] = ''
            },
            handleRemove2() {
                this.fileList2[0] = ''
            },
            handleRemove3() {
                this.fileList3[0] = ''
            },
            handleRemove4() {
                this.fileList4[0] = ''
            },
            handleRemove5() {
                this.fileList5[0] = ''
            },
            handleRemove6() {
                this.fileList6[0] = ''
            },
            //图片请求end-----------------------------------
            // 图片请求
            dovgetImages() {
                getImages({'id': this.$route.query.id}).then(res => {
                    if (res.data.length > 0) {
                        for (let i = 0; i < res.data.length; i++) {
                            let type = {
                                name: res.data[i].photoName,
                                url: res.data[i].photoRoute,
                                imgId: res.data[i].id,
                                type: res.data[i].type
                            }
                            if (res.data[i].type === "shijiguohui") {
                                this.shijiguohui = res.data[i].photoRoute
                                this.fileList1.push(type)
                            } else if (res.data[i].type === "shijirenxiang") {
                                this.shijirenxiang = res.data[i].photoRoute
                                this.fileList2.push(type)
                            } else if (res.data[i].type === "yingyezhizhao") {
                                this.yingyezhizhao = res.data[i].photoRoute
                                this.fileList3.push(type)
                            } else if (res.data[i].type === "yinhangka") {
                                this.yinhangka = res.data[i].photoRoute
                                this.fileList4.push(type)
                            } else if (res.data[i].type === "kaihuxukezheng") {
                                this.kaihuxukezheng = res.data[i].photoRoute
                                this.fileList5.push(type)
                            } else if (res.data[i].type === "huzhao") {
                                this.huzhao = res.data[i].photoRoute
                                this.fileList6.push(type)
                            }
                        }
                    }
                })
            },
            // 图片请求end---------------------
            //------------------------------------------------------
            getList() {
                if (this.$route.query.id) {
                    this.doview()
                    this.dovgetImages()
                    this.listLoading = true
                    checkMemberApplyIsExist({'id': this.$route.query.id}).then(res => {
                        this.listQuery = res.data[0]
                        this.listQuery.legalName = res.data[0].memberName
                        this.doareagetList()
                        this.listLoading = false
                    })
                } else {
                    this.multiple = false
                    this.companyCodeIf = true
                    this.listQuery.accountType = 0
                    this.companyCodeHeader = false
                    this.addimage = false
                }
            },
            // 查看限制隐藏
            doview() {
                if (this.$route.query.view === "view") {
                    this.multiple = true
                    this.companyCodeIf = false
                    this.companyCodeHeader = false
                    this.addimage = true
                } else {
                    this.multiple = false
                    this.companyCodeIf = true
                    this.companyCodeHeader = true
                    this.addimage = true
                }
            },
            //编辑返回地区
            doareagetList() {
                getAlRegionsIdList({'regionCode': this.listQuery.regionCode}).then(res => {
                    let areaList = res.data
                    areaList.reverse()
                    this.countrymodel = areaList[0].regionName
                    if (areaList[1]) {
                        this.provincemodel = areaList[1]
                        fetChildRegions({'regionId': res.data[0].regionId}).then(resa => {
                            this.addchoiceprovince = resa.data
                        })
                    }
                    if (areaList[2]) {
                        this.citymodel = areaList[2]
                        fetChildRegions({'regionId': res.data[1].regionId}).then(resa => {
                            this.addchoicecity = resa.data
                        })
                    }
                    if (areaList[3]) {
                        this.areamodel = areaList[3].regionCode
                        fetChildRegions({'regionId': res.data[2].regionId}).then(resa => {
                            this.addchoicearea = resa.data
                        })
                    }
                }).catch(() => {
                })
            },
            // 所在地区,  需要created的
            zerochange() {
                fetChildRegions({'regionId': 0}).then(res => {
                    this.addchoicecountry = res.data
                })
            },
            // 新增按钮 - 国家change事件
            countrychange(val) {
                // 选择国家后 省, 市, 区清空
                this.provincemodel = ''
                this.citymodel = ''
                this.areamodel = ''
                this.countrymodel2 = val.regionCode
                fetChildRegions({'regionId': val.regionId}).then(res => {
                    this.addchoiceprovince = res.data
                }).catch(() => {
                })
            },
            // 新增按钮 - 省change事件
            provincechange(val) {
                // 选择省后  市, 区清空
                this.citymodel = ''
                this.areamodel = ''
                this.provincemodel2 = val.regionCode
                fetChildRegions({'regionId': val.regionId}).then(res => {
                    this.addchoicecity = res.data
                }).catch(() => {
                })
            },
            // 新增按钮 - 市change事件,
            citychange(val) {
                // 选择市后 区清空
                this.areamodel = ''
                this.citymodel2 = val.regionCode
                fetChildRegions({'regionId': val.regionId}).then(res => {
                    this.addchoicearea = res.data
                }).catch(() => {
                })
            },
            // 判断要提交地区数据
            doArea() {
                // 判断要提交的数据  判断区, 在 市 省 国
                if (this.areamodel) {
                    this.listQuery.regionCode = this.areamodel
                    return true
                } else if (this.citymodel) {
                    this.listQuery.regionCode = this.citymodel.regionCode
                    return true
                } else {
                    this.searchloading = false
                    return false
                }
            },
            // 保存按钮
            savebtn(formName) {
                this.searchloading = true
                if (this.IFPaperType === 1) {
                    if (!(this.fileList1.length > 0 && this.fileList2.length > 0)) {
                        this.$message.error('请上传申请人身份证 正反面 照片')
                        this.searchloading = false
                        return false
                    }
                } else if (this.IFPaperType === 2) {
                    if (!(this.fileList6.length > 0)) {
                        this.$message.error('请上传申请人护照照片')
                        this.searchloading = false
                        return false
                    }
                }
                if (!(this.fileList3.length > 0)) {
                    this.$message.error('请上传营业执照照片')
                    this.searchloading = false
                    return false
                }
                if (this.listQuery.accountType === 0) {
                    if (!(this.fileList4.length > 0)) {
                        this.$message.error('请上传银行卡照片')
                        this.searchloading = false
                        return false
                    }
                } else if (this.listQuery.accountType === 1)
                    if (!(this.fileList5.length > 0)) {
                        this.$message.error('请上传开户许可证照片')
                        this.searchloading = false
                        return false
                    }
                if (!this.doArea()) {
                    return this.$message.error('地区最少填写到市(地区请最少填写到第三位)')
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        registeredReseller({...this.listQuery}).then(res => {
                            this.listQuery.id = res.data
                            this.imgid = res.data || this.$route.query.id
                            this.doimgbtn()
                        }).catch(() => {
                            this.$message.error('保存失败')
                            this.searchloading = false
                        })
                    } else {
                        this.searchloading = false
                        this.$message.error('请填写完整信息')
                        return false
                    }
                })
            },
            // 返回按钮
            returnbtn(formName) {
                this.$refs[formName].resetFields()
                this.$router.go(-1)
            }
        }
    }
</script>
<style scoped lang="scss">

    ::v-deep .existItem .el-form-item__label:before {
        content: '*';
        color: #ff4949;
        margin-right: 4px;
    }

    .inputdis {
        ::v-deep .el-input.is-disabled .el-input__inner {
            color: #333333;
            background-color: white;
        }

        ::v-deep .el-textarea.is-disabled .el-textarea__inner {
            color: #333333;
            background-color: white;
        }

        ::v-deep .el-form-item__error {
            display: none;
        }

    }

    .inputw {

        ::v-deep .el-input__inner {
            color: #333333;
            background-color: white;
        }

        width: 40%;
        float: left;
    }

    .h2right {
        float: left;
        color: #F39F43;
    }

    .sapnred {
        font-size: 16px;
        color: red;
    }

    ::v-deep .el-form-item__label {
        font-weight: 700;
        font-size: 16px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 20px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }

    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
</style>
