<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container invoice-upload">
      <el-alert title="友情提示：您上传提供的发票必须与实发金额一致！" type="warning" effect="dark"/>
      <p></p>
      <el-form ref="invoiceForm" :model="invoiceForm" :rules="rules" label-width="140px" size="small">
        <el-form-item label="发票类型:" prop="type">
          <el-select v-model="invoiceForm.type" placeholder="请选择发票类型" style="width:280px;">
            <el-option v-for="item in selectTypes" :key="item.code" :label="$lt(initDict.type, item.code)"
                       :value="item.code"/>
          </el-select>
        </el-form-item>
        <el-form-item label="发票票号:" prop="invoiceNo">
          <el-input v-model.trim="invoiceForm.invoiceNo" placeholder="请填写发票票号" style="width:280px;"/>
        </el-form-item>
        <el-form-item label="发票时间:" prop="invoiceDate">
          <el-date-picker v-model="invoiceForm.invoiceDate" type="date" placeholder="请填写发票时间,有效期90天"
                          style="width:280px;"/>
        </el-form-item>
        <el-form-item v-show="invoiceForm.type && invoiceForm.type==='1'" label="上传ofd文件:">
          <el-upload
              class="upload-demo"
              :action="uploadOfdUrl"
              :headers="headers"
              :show-file-list="false"
              :on-success="uploadOfdSuccess"
              :before-upload="beforeOfdUpload"
              :on-progress="uploadOfdProcess"
          >
            <el-button v-if="!uploadOfdLoading && !invoiceForm.ofdFile">选择文件</el-button>
            <el-progress v-if="uploadOfdLoading" :percentage="uploadOfdPercent"/>
            <div v-if="!uploadOfdLoading && invoiceForm.ofdFile"><span style="color:#008800;">{{ ofdFileName }}</span>
            </div>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传ofd文件，且不超过2MB</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传发票图片:" prop="picFile">
          <el-upload
              class="upload-demo"
              :action="uploadUrl"
              :headers="headers"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :before-upload="beforeUpload"
              :on-progress="uploadProcess"
          >
            <el-button v-if="!uploadLoading && !invoiceForm.picFile">选择文件</el-button>
            <el-progress v-if="uploadLoading" :percentage="uploadPercent"/>
            <div v-if="!uploadLoading && invoiceForm.picFile"><span style="color:#008800;">{{ picFileName }}</span>
            </div>
            <div slot="tip" class="el-upload__tip" style="margin:0;">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" type="primary" @click="onSubmit">保存</el-button>
          <el-button type="default" @click="onBack">返回</el-button>
          <el-button type="text" icon="el-icon-info" style="color:#266AAD;" @click="dialogVisible = true">发票示例及说明
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="发票示例及说明" :visible.sync="dialogVisible" center fullscreen>
      <invoice-sample/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import {uploadInvoice} from '@/api/account/withdraw'
import InvoiceSample from './sample'

export default {
  name: 'AccountInvoiceUpload',
  components: {InvoiceSample},
  data() {
    const validateOfdFile = (rule, value, callback) => {
      if (this.invoiceForm.type === '1') {
        if (!value || !value.length) {
          callback(new Error('请选择ofd文件'))
        }
      }
      callback()
    }
    return {
      picBase64Data: null,
      ofdBase64Data: null,
      loading: false,
      invoiceForm: {
        id: undefined,
        invoiceNo: undefined,
        type: undefined,
        invoiceDate: undefined,
        picFile: undefined,
        ofdFile: undefined
      },
      rules: {
        invoiceNo: [{required: true, message: '请填写发票票号', trigger: 'blur'}],
        type: [{required: true, message: '请选择发票类型', trigger: 'change'}],
        invoiceDate: [{required: true, message: '请填写发票时间', trigger: 'blur'}],
        picFile: [{required: true, message: '请选择发票图片', trigger: 'change'}],
        ofdFile: [{required: true, validator: validateOfdFile, trigger: 'change'}]
      },
      uploadUrl: process.env.VUE_APP_BASE_API + '/upload/picture',
      uploadOfdUrl: process.env.VUE_APP_BASE_API + '/upload/ofd',
      headers: {
        'Authorization': getToken()
      },
      uploadLoading: false,
      uploadPercent: 0,
      uploadOfdLoading: false,
      uploadOfdPercent: 0,
      dialogVisible: false,
      picFileName: '',
      ofdFileName: '',
      initDict: {
        type: 'receipt.type.select'
      }
    }
  },
  computed: {
    selectTypes() {
      return this.$ll(this.initDict.type)
    }
  },
  mounted() {
    const id = this.$route.query.id
    if (id) {
      this.invoiceForm.id = id
    } else {
      this.$router.go(-1)
    }
  },
  methods: {
    uploadSuccess(response, file) {
      this.uploadLoading = false
      this.uploadPercent = 0
      if (response.code === 200) {
        this.invoiceForm.picFile = response.data
        this.picFileName = file.name
      }
    },
    beforeUpload(file) {
      const acceptTypes = ['image/png', 'image/jpeg']
      const isJPG = acceptTypes.some(item => item === file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg/png格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过2MB')
        return false
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)
      const that = this
      reader.onload = function(e) {
        const picBase64 = e.target.result
        that.picBase64Data = picBase64.split(',')[1]
      }
      return isJPG && isLt2M
    },
    uploadProcess(file) {
      this.uploadLoading = true
      this.uploadPercent = file.percentage ? +file.percentage.toFixed(0) : 0
    },
    uploadOfdSuccess(response, file) {
      this.uploadOfdLoading = false
      this.uploadOfdPercent = 0
      if (response.code === 200) {
        this.invoiceForm.ofdFile = response.data
        this.ofdFileName = file.name
      }
    },
    beforeOfdUpload(file) {
      // const acceptTypes = ['application/vnd.ofd']
      console.log(file, 'file')
      // console.log(file.type)
      // const isOfd = acceptTypes.some(item => item === file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isOfd) {
      //   this.$message.error('只能上传ofd文件!')
      // }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过2MB')
        return false
      }
      let reader = new FileReader()
      const that = this
      reader.readAsDataURL(file)
      reader.onload = function(e) {
        const ofdBase64 = e.target.result
        that.ofdBase64Data = ofdBase64.split(',')[1]
      }
      // return isOfd && isLt2M
      return isLt2M
    },
    uploadOfdProcess(file) {
      this.uploadOfdLoading = true
      this.uploadOfdPercent = file.percentage ? +file.percentage.toFixed(0) : 0
    },
    onBack() {
      this.$router.go(-1);
    },
    onSubmit() {
      this.$refs['invoiceForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.invoiceForm.picFile = this.picBase64Data
          this.invoiceForm.ofdFile = this.ofdBase64Data
          // console.log({ '1':this.invoiceForm.picFile , '2':this.invoiceForm.ofdFile })
          uploadInvoice(this.invoiceForm).then(() => {
            this.$message.success('上传发票成功')
            this.$router.replace('/account/withdraw')
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
