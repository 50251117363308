import i18n from '@/lang'

export default function getPageTitle(pageTitle) {
  const title = ''
  if (!pageTitle) {
    return title
  }
  const hasKey = i18n.te(pageTitle)
  if (hasKey) {
    const pageName = i18n.t(pageTitle)
    return `${pageName}`
  }
  return `${pageTitle}`
}
