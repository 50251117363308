import Vue from 'vue'

import Element from 'element-ui'
import '@/assets/css/variables.scss'
import '@/assets/css/index.scss'


import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './lang/index'

import './permission'


Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.prototype.start_Date =  {
  disabledDate: time => {
    const start = new Date()
    return time.getTime() < start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
  }
}
Vue.prototype.end_Date =  {
  disabledDate: time => {
    return time.getTime() > new Date();
  }
}

import AppBreadcrumb from '@/components/Breadcrumb'
Vue.component('AppBreadcrumb', AppBreadcrumb)

import { dict } from './utils/dict'
Vue.mixin(dict)

Vue.config.productionTip = false

import { getLanguage } from '@/lang/index'
getLanguage()

const on = Vue.prototype.$on
Vue.prototype.$on = function(event, func) {
  let timer
  let debounce = func
  if (event === 'click') {
    debounce = function() {
      clearTimeout(timer)
      timer = setTimeout(function() {
        func.apply(this, arguments)
      }, 500)
    }
  }
  on.call(this, event, debounce)
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
