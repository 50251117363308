<template>
  <div>
    <app-breadcrumb />
    <div class="page-container account">
      <div class="summary">
        <span>账户：<em class="amt">{{ balance }}</em></span>
        <span v-if="!isAgent">活跃度：<em class="amt">{{ activity }}</em></span>
        <span>A积分：<em class="amt">{{ points }}</em></span>
      </div>
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <el-form-item label="创建时间">
            <!-- 时间起始 -->
            <el-date-picker
                    v-model="listQuery.startTime"
                    :picker-options="this.start_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="开始时间"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 时间结束 -->
            <el-date-picker
                    v-model="listQuery.endTime"
                    :picker-options="this.end_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="结束时间"
            />
          </el-form-item>
          <el-form-item label="帐户类型:">
            <el-select v-model="listQuery.acType" placeholder="帐户类型">
              <el-option v-for="item in accountTypeList" :key="item.code" :label="$lt(initDict.acType, item.code)" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" style="width: 100%" size="mini" border>
        <el-table-column label="操作" width="50" header-align="center" />
        <el-table-column label="帐户类型" width="100" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.acType, row.acType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="交易类别" width="100" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="存入" width="80" align="center">
          <template slot-scope="{row}">
<!--            <span v-if="row.money >= 0" style="color:green;">+{{ row.money.toFixed(2) }}</span>-->
            <span  style="color:green;">+{{ row.inValidMoney.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="取出" width="80" align="center">
          <template slot-scope="{row}">
<!--            <span v-if="row.money < 0" style="color:red;">{{ row.money.toFixed(2) }}</span>-->
            <span style="color:red;">-{{row.outValidMoney.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="可用余额" width="80" align="center">
          <template slot-scope="{row}">
            <span>{{ row.validBalance.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNo" width="130" align="center" />
        <el-table-column label="创建时间" prop="createdTime" width="130" align="center" />
        <el-table-column label="摘要" prop="remark" show-overflow-tooltip align="center" />
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { fetchBalance, fetchBalanceItems } from '@/api/account/index'
import Pagination from '@/components/Pagination'
export default {
  name: 'AccountIndex',
  components: { Pagination },
  data() {
    return {
      loading: false,
      balance: undefined,
      activity: undefined,
      points: undefined,
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        acType: 'F$',
        // type: '0'
      },
      applyDates: undefined,
      initDict: {
        acType: 'fiacinput.actype.cn',
        orderType: 'ac_change_order_type'
      }
    }
  },
  computed: {
    isAgent() {
      return this.$store.state.user.isAgent
    },
    accountTypeList() {
      return this.$ll(this.initDict.acType).filter(ele => ele.code === 'F$' || ele.code === 'H0')
    }
  },
  mounted() {
  },
  methods: {
    getList() {
      const fromDate = this.listQuery.startTime
      const toDate = this.listQuery.endTime
      this.loading = true
      fetchBalanceItems({ ...this.listQuery, fromDate, toDate }).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.getBalance()
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    getBalance() {
      fetchBalance({ acType: 'F$' }).then(res => {
        this.balance = res.data
      })
      fetchBalance({ acType: 'H0' }).then(res => {
        this.points = res.data
      })
      if (!this.isAgent) {
        fetchBalance({ acType: 'NV' }).then(res => {
          this.activity = res.data
        })
      }
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.account {
  .summary {
    height: 25px;
    line-height: 25px;
    color: #538E0D;
    text-align: left;
    font-weight: bold;
    padding-bottom: 6px;
    span {
      display: inline-block;
      padding-right: 10px;
      .amt {
        font-style: normal;
        color: #FF0000;
      }
    }

  }
}
</style>
