<template>
  <div>
    <p style="font-size: 20px">购买气血温通养生仪2.0（N）(CN-M006-01-20)请选择赠品</p>
    <el-radio v-model="id" :label="-150" style="margin-bottom: 10px;"  @input="getYSYidChange" >
      <span >赠品1:健康磁性多功能床垫（小）(CN-T008-01-00)*1</span>
      <br><span style="margin-left: 74px">负离子远红外子母被(CN-T012-01-00)*1</span>
      <br><span style="margin-left: 74px">健康磁性枕(CN-T010-01-00)*2</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-151" style="margin-bottom: 10px;" @input="getYSYidChange">
      <span>赠品2:健康磁性多功能床垫(CN-T009-01-00)*1</span>
      <br><span style="margin-left: 74px">负离子远红外子母被(CN-T012-01-00)*1</span>
      <br><span style="margin-left: 74px">健康磁性枕(CN-T010-01-00)*2</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-152" style="margin-bottom: 10px;" @input="getYSYidChange">
      <span>赠品3:便携舒络仪3.0(CN-M002-01-00)*1</span>
      <br><span style="margin-left: 74px">雪活泉舒怡按摩霜(中文)(CN-C029-01-00)*12</span>
      <br><span style="margin-left: 74px">雪活泉温舒凝胶(CN-C002-01-00)*13</span>
      <br><span style="margin-left: 74px">能量围巾（西柚红）(CN-T015-01-50)*1</span>
      <br><span style="margin-left: 74px">雪活泉抗皱紧致喷雾(CN-C005-01-00)*5</span>
      <br><span style="margin-left: 74px">7800积分</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-154" style="margin-bottom: 10px;" @input="getYSYidChange">
      <span>赠品4:便携舒络仪3.0(CN-M002-01-00)*1</span>
      <br><span style="margin-left: 74px">雪活泉舒怡按摩霜(中文)(CN-C029-01-00)*12</span>
      <br><span style="margin-left: 74px">雪活泉温舒凝胶(CN-C002-01-00)*13</span>
      <br><span style="margin-left: 74px">能量围巾（高级灰）(CN-T015-01-40)*1</span>
      <br><span style="margin-left: 74px">雪活泉抗皱紧致喷雾(CN-C005-01-00)*5</span>
      <br><span style="margin-left: 74px">7800积分</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-155" style="margin-bottom: 10px;" @input="getYSYidChange">
      <span>赠品5:便携舒络仪3.0(CN-M002-01-00)*1</span>
      <br><span style="margin-left: 74px">雪活泉舒怡按摩霜(中文)(CN-C029-01-00)*12</span>
      <br><span style="margin-left: 74px">雪活泉温舒凝胶(CN-C002-01-00)*13</span>
      <br><span style="margin-left: 74px">能量围巾（天空蓝）(CN-T015-01-30)*1</span>
      <br><span style="margin-left: 74px">雪活泉抗皱紧致喷雾(CN-C005-01-00)*5</span>
      <br><span style="margin-left: 74px">7800积分</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-153" style="margin-bottom: 10px;" @input="getYSYidChange">
      <span>赠品6:时光宝盒B(CN-M007-01-00)*1</span>
      <br><span style="margin-left: 74px">雪活泉抗皱紧致喷雾(CN-C005-01-00)*17</span>
      <br><span style="margin-left: 74px">雪活泉富勒烯润颜面膜(CN-C004-01-00)*15</span>
      <br><span style="margin-left: 74px">雪活泉丽妍凝胶(CN-C001-01-00)*7</span>
      <br><span style="margin-left: 74px">雪活泉紧致凝胶(CN-C003-01-00)*7</span>
      <br><span style="margin-left: 74px">雪活泉温舒凝胶(CN-C002-01-00)*7</span>
      <br><span style="margin-left: 74px">2300积分</span>
    </el-radio>
  </div>
</template>

<script>
export default {
  name: "CNM0060120",
  data(){
    return{
      id:''
    }
  },
  props: {
    CNM0060120num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    CNM0060120num: {
      handler: function (val) {
       if(val === 0){
         this.id = ''
         this.getYSYidChange()
       }
      },
      immediate: true
    }
  },
  methods:{
    getYSYidChange(){
      this.$emit('getYSYidChange', this.id)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-radio__label{
 font-size: 18px;

}
</style>
