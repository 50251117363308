<template>
  <div class="invoice-sample">
    <h3>一、发票票样：</h3>
    <div class="image"><img :src="require('@/assets/images/fapiao.png')"></div>
    <div class="image"><img :src="require('@/assets/images/fapiao2.png')"></div>
    <p>1)若您开具增值税电子普通发票，请直接将电子发票上传，无需邮寄；并确保您上传的发票为PDF版本，且发票字面清晰。</p>
    <p>2)若您开具纸质增值税普通发票，请将纸质发票（第二联：发票联，购货方扣税凭证）邮寄至公司，地址如下：天津市红桥区咸阳北路46号银泰大厦B座1-2层和治友德服务中心 客服部收 电话4000-826-567</p>
    <h3>二、发票内容：</h3>
    <p>1)开票项目：*现代服务*推广服务费       税收分类编码：30499       *其他咨询服务*产品推广服务费       税收分类编码：304060399</p>
    <p>2)其他要求：“开票人”“复核人”“收款人”处不能为“管理员”，必须为真实人员姓名，且“复核人”不能与“收款人”和“开票人”是同一人。</p>
    <div class="contact">*若有其他未尽事宜，烦请致电客服沟通咨询！</div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceSample'
}
</script>

<style lang="scss">
.invoice-sample {
  h3 {
    color: #FF0000;
  }
  .image {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  p {
    color: #008800;
  }
  .contact {
    color: #FF0000;
  }
}
</style>