<template>
  <div class="app-login" :class="agentBgClass">
    <div class="login-wrapper">
      <el-form ref="loginForm" :model="login" :rules="rules" class="login-form" label-position="left">
        <el-form-item prop="username">
          <el-input v-model.trim="login.username" :class="{'en':isEn}" :placeholder="$t('login.username.placeholder')"
                    @blur.native.capture="getAgentLoginMobile">
            <span slot="prefix" class="ipt-label">{{ $t('login.username') }}</span>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model.trim="login.password" :class="{'en':isEn}" show-password
                    :placeholder="$t('login.password.placeholder')">
            <span slot="prefix" class="ipt-label">{{ $t('login.password') }}</span>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <div class="vcode">
            <el-input v-model.trim="login.code" :class="{'en':isEn}" maxlength="4"
                      :placeholder="$t('login.verificationcode.placeholder')">
              <span slot="prefix" class="ipt-label">{{ $t('login.verificationcode') }}</span>
            </el-input>
            <el-image fit="contain" :src="captcha.data" @click="handleRefresh">
              <div slot="error" class="image-slot" @click="handleRefresh">
                <i v-if="captchaLoading" class="el-icon-loading"></i>
                <i v-else class="el-icon-refresh"></i>
              </div>
              <div slot="placeholder" class="image-slot"><i class="el-icon-loading"></i></div>
            </el-image>
          </div>
        </el-form-item>
        <el-form-item v-if="isAgent && mobile !== undefined" prop="smsCode">
          <div class="smscode">
            <el-input v-model.trim="login.smsCode" :class="{'en':isEn}" maxlength="6"
                      :placeholder="$t('login.verificationcode.placeholder')">
              <span slot="prefix" class="ipt-label">{{ $t('login.verificationcode') }}</span>
            </el-input>
            <el-button :loading="sendLoading" :disabled="send" @click="sendSms">{{
                send ? `${time}秒后重发` : '发送验证码'
              }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" :loading="loginLoading" @click="SignInBtn">{{ $t('login') }}</el-button>
        </el-form-item>
        <p v-if="!isAgent"
           style="display:block;width: 60px;margin: -10px 0 0 0;text-decoration: underline;color: #266aad;cursor: pointer"
           @click="toforgetpassword">
          忘记密码</p>
        <div v-if="!isAgent" class="lang">
          <el-link
              v-for="item in languages"
              :key="item.code"
              :underline="false"
              :class="{active:language===item.code}"
              @click="handleLang(item)"
          >
            {{ item.title }}
          </el-link>
        </div>
      </el-form>
      <div v-if="isAgent">
        <p style="display:block;width: 60px;margin: 0 20px;float:right;text-decoration: underline;color: #266aad;cursor: pointer"
           @click="toforgetpassword">
          忘记密码</p>
      </div>
    </div>
    <el-dialog
        title="首次登录"
        :visible.sync="dialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="30%">
      <el-dialog
          center
          width="50%"
          title="顾客须知"
          :visible.sync="innerVisible"
          append-to-body>
        <div>
          <div>
            <h3>一、顾客定义</h3>
            1. 顾客：按照公司要求完整、详细、真实填写相关信息进行注册、并经公司审核同意的自然人，包括普通顾客和优惠顾客。
            <br/>
            2. 普通顾客：累计消费公司产品，消费指数不足2100的顾客群体。
            <br/>
            3. 优惠顾客：累计消费公司产品，消费指数超过2100（含）的顾客群体。
            <br/>
            <br/>
            <h3>二、顾客资格</h3>
            1. 申请条件
            <br/>
            （1）年满18周岁的任何人士，具有民事行为能力且自愿申请。
            <br/>
            （2）公司只接受自然人申请。
            <br/>
            2. 申请手续
            <br/>
            （1）免费注册，即注册时公司不收取任何费用。
            <br/>
            （2）登录公司网站，在线办理申请手续，按照公司要求完整、详细、真实填写相关注册信息，经公司审核同意后顾客资格生效。
            <br/>
            （3）任何人不得重复申请公司的顾客资格。
            <br/>
            （4）公司有权自行决定是否接受任何顾客资格申请。
            <br/>
            3. 顾客资格的有效期
            <br/>
            （1）顾客资格有效期为一年，具体起始时间以在线提交注册申请之日起计算。
            <br/>
            （2）在顾客资格有效期内，可享受顾客的相关权益。
            <br/>
            4. 顾客资格的免费续约
            <br/>
            顾客资格有效期内，遵守公司制定的行为规范及其他相关制度、规定，无重大违法/违规行为，即可免费续约。
            <br/>
            5. 顾客资格的终止
            <br/>
            顾客资格终止后，则不再享有顾客的相关权益：
            <br/>
            （1）顾客本人以书面形式向公司申请终止。
            <br/>
            （2）顾客本人没有达到资格自动延续的条件。
            <br/>
            （3）根据公司相关业务规章制度，公司终止顾客资格。
            <br/>
            <br/>
            <br/>
            <h3>三、顾客权益</h3>
            1. 普通顾客权益：
            <br/>
            （1）根据自身需求，自主决定、选择、购买、使用公司产品、接受公司服务。
            <br/>
            （2）享有购买公司产品满一定金额后的免费配送服务。
            <br/>
            （3）享有使用公司相关电子资讯渠道获取资讯信息的服务。
            <br/>
            2. 优惠顾客权益：
            <br/>
            （1）根据自身需求，自主决定、选择购买、使用公司产品、接受公司服务。
            <br/>
            （2）享有购买公司产品满一定金额后的免费配送服务。
            <br/>
            （3）享有使用公司相关电子资讯渠道获取资讯信息的服务。
            <br/>
            （4）享受优惠价格、折扣购买公司产品。
            <br/>
            （5）参与公司促销活动、优惠计划。
            <br/>
            （6）获取并使用电子消费券兑换公司产品。
            <br/>
            <br/>
            <br/>
            <h3>四、其他</h3>
            1. 公司制定的市场支持方案、业务促销政策等相关规则内容若与本须知冲突，以公司制定的市场支持方案、业务促销政策等相关规则内容为准。
            <br/>
            2. 本须知自公布之日起生效施行。
            <br/>
            3. 公司在法律范围内，可根据实际需要修订本须知的有关条款，相关修订内容将通过公司官方渠道公布并实施。
            <br/>
            4. 本《顾客须知》的最终解释权归天津和治友德制药有限公司所有。
            <br/>
            以上内容如有与国家现行法律、法规相冲突的，以国家现行法律、法规为准。
            <br/>
            <br/>
            <br/>
          </div>
          <div style="text-align: center">
            <el-button
                type="primary"
                icon="el-icon-check"
                :loading="loginLoading"
                @click="afterRead">已仔细阅读并同意
            </el-button>
          </div>
        </div>
      </el-dialog>
      <div>
        <p style="margin-top: 0">首次登录需要验证手机号并设置新密码</p>
        <p style="margin-top: 0;color: red">短信验证码已发送</p>
        <el-form>
          <el-form-item label="短信验证码">
            <el-input v-model.trim="firstphonecode" :placeholder="$t('短信验证码')"/>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input v-model.trim="newPassword" :placeholder="$t('请输入新密码')" show-password/>
          </el-form-item>
          <el-form-item label="再次输入新密码">
            <el-input v-model.trim="newPassword2" :placeholder="$t('请再次输入新密码')" show-password/>
          </el-form-item>
        </el-form>
        <p>
          密码长度为6-16位，且包含大写字母、小写字母、数字、特殊符号其中两种，未收到短信请
          <span @click="reload" style="color: #266aad;text-decoration: underline;cursor: pointer">
            点此刷新页面
          </span>
          重新登录</p>
        <div style="width: 100%;font-size: 20px">
          <el-checkbox v-model="checked" @change="verifyChecked2"><p style="font-size: 20px">已仔细阅读并同意</p>
          </el-checkbox>
          <span @click="toRead" style="color: #266aad;text-decoration: underline;cursor: pointer">
            《顾客须知》
          </span>
        </div>
        <el-button type="primary" icon="el-icon-check" :loading="loginLoading" style="margin-top: 25px"
                   :disabled="!checked"
                   @click="smsFirstVer">{{ $t('确定') }}
        </el-button>
      </div>
    </el-dialog>
    <!--    跳转-->
    <el-dialog
        class="eldialog"
        title=""
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dcNoBuledialong"
        width="40%"
    >
      <div>
        <p style="font-size: 22px">业务系统已升级，将启用您的专属新网址<a :href="jumpMark">{{ jumpMark }}</a>。</p>
        <p style="font-size: 22px">请您记录，妥善保管，并注意保密。下次请使用新网址<a :href="jumpMark">{{ jumpMark }}</a>登陆，谢谢！
        </p>
        <p>{{ times }}秒后将自动跳转</p>
      </div>
    </el-dialog>
    <!--  简单密码需要修改  -->
    <el-dialog
        title="简单密码需要修改"
        :visible.sync="dialogVisible2"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="30%">
      <div>
        <p style="margin-top: 0;color: red">短信验证码已发送</p>
        <el-form>
          <el-form-item label="短信验证码">
            <el-input v-model.trim="firstphonecode" :placeholder="$t('短信验证码')"/>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input v-model.trim="newPassword" :placeholder="$t('请输入新密码')" show-password/>
          </el-form-item>
          <el-form-item label="再次输入新密码">
            <el-input v-model.trim="newPassword2" :placeholder="$t('请再次输入新密码')" show-password/>
          </el-form-item>
        </el-form>
        <p>
          密码长度为6-16位，且包含大写字母、小写字母、数字、特殊符号其中两种，未收到短信请
          <span @click="reload" style="color: #266aad;text-decoration: underline;cursor: pointer">
            点此刷新页面
          </span>
          重新登录</p>
        <el-button type="primary" icon="el-icon-check" :loading="loginLoading" style="margin-top: 25px"
                   @click="smsFirstVer">{{ $t('确定') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 滑动验证码 -->
    <div>
      <Vcode :show="vCodeIsShow" :range="4" @success="vCodeSuccess" @close="vCdoeClose"/>
    </div>
  </div>
</template>

<script>
import {fetchCaptcha} from '@/api/home'
import {sendSmsCode, fetchLoginMobile} from '@/api/agent'
import {getLanguage} from '@/lang'
import {memberFirstLoginCode, memberOneLoginConfirm} from "@/api/auth"
import Vcode from "vue-puzzle-vcode"

export default {
  name: 'Login',
  components: { Vcode },
  data() {
    return {
      login: {
        username: undefined,
        password: undefined,
        code: undefined,
        smsCode: undefined
      },
      rules: {
        username: [{required: true, message: '请输入用户名', trigger: '[blur, change]'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
        smsCode: [{required: true, message: '请输入短信验证码', trigger: 'blur'}]
      },
      loginLoading: false,
      captchaLoading: false,
      checked: false,
      checked2: false,
      captcha: {
        data: '',
        key: ''
      },
      languages: [
        {code: 'zh_CN', title: 'Chinese'},
        {code: 'ms', title: 'Malaysia'},
        {code: 'en', title: 'English'}
      ],
      redirect: undefined,
      otherQuery: {},
      mobile: '',
      firstphonecode: '',
      newPassword: '',
      newPassword2: '',
      innerVisible: false,
      sendLoading: false,
      send: false,
      dialogVisible: false,
      dialogVisible2: false,
      vCodeIsShow: false,
      time: 60,
      dcNoBuledialong: false,
      jumpMark: null,
      times: 10
    }
  },
  computed: {
    language() {
      return this.$store.getters.language
    },
    isEn() {
      return this.$store.getters.language !== 'zh_CN'
    },
    isAgent() {
      return process.env.VUE_APP_AGENT === 'true'
    },
    agentBgClass() {
      return process.env.VUE_APP_AGENT === 'true' ? 'app-login-agent' : ''
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    },
    "login.username": function (val) {
      this.login.username = val.toUpperCase()
    }
  },
  mounted() {
    this.getCaptcha()
  },
  methods: {
    // 登录按钮
    SignInBtn() {
      this.vCodeIsShow = true
    },
    // 滑动验证码 - 用户点击遮罩层，应该关闭模态框
    vCdoeClose() {
      this.vCodeIsShow = false
    },
    vCodeSuccess() {
      // 通过验证后，需要手动隐藏模态框
      this.vCodeIsShow = false
      this.handleLogin()
    },
    afterRead() {
      this.innerVisible = false
      this.checked2 = true
      this.checked = true
    },
    toRead() {
      this.innerVisible = true
    },
    verifyChecked2() {
      if (this.checked2) {
        this.checked = true
      } else {
        this.$message.error('请仔细阅读并同意《顾客须知》!')
        this.checked = false
      }
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    getCaptcha() {
      this.captchaLoading = true
      this.captcha = {
        data: '',
        key: ''
      }
      fetchCaptcha().then(response => {
        this.captcha = response.data
        this.captchaLoading = false
      }).catch(err => {
        console.warn(err)
        this.captchaLoading = false
      })
    },
    handleRefresh() {
      if (!this.captchaLoading) {
        this.getCaptcha()
      }
    },
    getAgentLoginMobile() {
      if (this.isAgent) {
        fetchLoginMobile({userCode: this.login.username.toUpperCase()}).then(res => {
          if (res.data && res.data.length) {
            this.mobile = res.data
          } else {
            this.mobile = undefined
          }
        })
      }
    },
    sendSms() {
      const userCode = this.login.username
      const captcha = this.login.code
      const captchaKey = this.captcha.key
      if (!userCode) {
        this.$message.error('请填写用户名')
        return
      } else if (!captcha) {
        this.$message.error('请填写验证码')
        return
      }
      this.sendLoading = true
      sendSmsCode({userCode, captcha, captchaKey}).then(res => {
        this.sendLoading = false
        this.send = true
        this.$message.success(`已成功发送验证码到手机号${res.data}`)
        const timer = setInterval(() => {
          this.time--
          if (this.time <= 0) {
            this.send = false
            this.time = 60
            clearInterval(timer)
          }
        }, 1000)
      }).catch(err => {
        console.warn(err)
        this.sendLoading = false
      })
    },
    handleLang(lang) {
      this.$i18n.locale = lang.code
      this.$store.dispatch('app/setLanguage', lang.code)
      getLanguage()
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const loginData = {
            userCode: this.login.username,
            password: this.login.password,
            captcha: this.login.code,
            captchaKey: this.captcha.key,
            smsCode: this.login.smsCode,
            isAgent: this.isAgent
          }
          this.loginLoading = true
          this.$store.dispatch('user/login', loginData).then((res) => {
            if (!res.first) {
              // window.parent.location.reload()
              // this.$router.go(0)
              this.$router.push({path: this.redirect || '/', query: this.otherQuery})
              if (res.substring(0, 8) == "jumpMark") {
                this.dcNoBuledialong = true
                this.jumpMark = res.substring(8)
                const timer = setInterval(() => {
                  this.times--
                  if (this.times <= 0) {
                    window.location.href = this.jumpMark
                    this.times = 10
                    this.dcNoBuledialong = false
                    clearInterval(timer)
                  }
                }, 1000)
              }
            } else {
              memberFirstLoginCode({userCode: this.login.username}).then((result) => {
                this.loginLoading = false
                if (result.success) {
                  this.dialogVisible = res.first == 1
                  this.dialogVisible2 = res.first == 2
                }
              }).catch(err => {
                console.warn(err)
                this.loginLoading = false
              })
            }
            this.loginLoading = false
          }).catch((err) => {
            console.log(err, '313')
            this.loginLoading = false
          })
        }
      })
    },
    toforgetpassword() {
      this.$router.push({path: '/forget-password'})
    },
    reload() {
      window.location.reload()
    },
    smsFirstVer() {
      if (!this.firstphonecode) {
        return this.$message.error('请输入短信验证码!')
      }
      if (!this.newPassword) {
        return this.$message.error('请输入新密码!')
      }
      if (!this.newPassword2) {
        return this.$message.error('请再次输入新密码!')
      }
      if (this.newPassword !== this.newPassword2) {
        return this.$message.error('两次密码不一致,请修改!')
      }
      this.loginLoading = true
      memberOneLoginConfirm({
        userCode: this.login.username,
        captcha: this.firstphonecode,
        newPassword: this.newPassword
      }).then((res) => {
        if (res.success) {
          this.dialogVisible = false
          this.dialogVisible2 = false
          this.getCaptcha()
          this.login.code = ''
          this.login.password = ''
          this.$message.success('操作成功!请重新登录!')
        }
        this.loginLoading = false
      }).catch(err => {
        console.warn(err)
        this.loginLoading = false
      })
    }
  }
}
</script>

<style lang="scss" spoced>
.eldialog {
  z-index: 9999 !important;
  margin-top: 12%;
}
</style>
