<template>
    <div>
      <p>购买套组TZ(CN)19508,请选择商品尺码</p>
      <el-table
          border
          :data="HuYao_19508_list"
          header-align="center"
          style="width: 100%">
        <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
        <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
        <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
        <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
        <el-table-column label="购买数量" prop="quantity" width="200" align="center">
          <template slot-scope="{row}">
            <el-input-number
                v-model="row.quantity"
                size="mini"
                :min="0"
                style="width:100px;"
                @change="hyTypeChange(row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-table
          border
          :data="HuXi_19508_list"
          header-align="center"
          style="width: 100%">
        <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
        <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
        <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
        <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
        <el-table-column label="购买数量" prop="quantity" width="200" align="center">
          <template slot-scope="{row}">
            <el-input-number
                v-model="row.quantity"
                size="mini"
                :min="0"
                style="width:100px;"
                @change="hxTypeChange(row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
export default {
  name: "TZCN19508",
  data(){
    return{
      tableKey:0,
      giftlist:[],
      HuYao_19508_list:[
        {
          name:'健康磁性护腰2.0(M)',
          code:'CN-T003-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-50
        },{
          name:'健康磁性护腰2.0(L)',
          code:'CN-T003-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-51
        },{
          name:'健康磁性护腰2.0(XL)',
          code:'CN-T003-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-52
        },{
          name:'健康磁性护腰2.0(XXl)',
          code:'QC(CN)01043',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-53
        },
      ],
      HuXi_19508_list:[
        {
          name:'健康护膝(M)',
          code:'CN-T001-01-02',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-47
        },{
          name:'健康护膝(L)',
          code:'CN-T001-01-03',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-48
        },{
          name:'健康护膝(XL)',
          code:'CN-T001-01-04',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          gift:'是',
          id:-49
        }
      ],
    }
  },
  props: {
    TZCN19508_num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'TZCN19508_num': function (val) {
      if(val===0){
        this.HuYao_19508_list[0].quantity = 0
        this.HuYao_19508_list[1].quantity = 0
        this.HuYao_19508_list[2].quantity = 0
        this.HuYao_19508_list[3].quantity = 0
        this.HuXi_19508_list[0].quantity = 0
        this.HuXi_19508_list[1].quantity = 0
        this.HuXi_19508_list[2].quantity = 0
      }
    },
  },
  methods:{
    hyTypeChange(){
     let num = 0
      this.HuYao_19508_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.TZCN19508_num){
        this.$message.error('护腰所选尺码数量与购买套组(TZ(CN)19508)数量不符')
      }
      this.$emit('hyTypeChange', this.HuYao_19508_list)
      // console.log(this.HuYao_19508_list)

    },
    hxTypeChange(){
      let num = 0
      this.HuXi_19508_list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.TZCN19508_num){
        this.$message.error('护膝所选尺码数量与购买套组(TZ(CN)19508)数量不符')
      }
      this.$emit('hxTypeChange', this.HuXi_19508_list)
      // console.log(this.HuXi_19508_list)
    }
  }
}
</script>

<style scoped>

</style>