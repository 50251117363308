<template>
  <div class="app-footer">
    <span>{{ isAgent ? '代办处订货系统' : '订货系统' }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapGetters([
      'isAgent'
    ])
  }
}
</script>

<style>

</style>
