<template>
  <div>
    <p style="font-size: 20px">购买FQ促销套组一(CN-N000117-24)请选择赠品</p>
    <el-radio v-model="id" :label="-1018" style="margin-bottom: 10px;"  @input="CNN00011724_CD_Change" >
      <span >赠品1:健康磁性多功能床垫（小）(CN-T008-01-00)*1</span>
    </el-radio>
    <br>
    <el-radio v-model="id" :label="-1019" style="margin-bottom: 10px;" @input="CNN00011724_CD_Change">
      <span>赠品2:健康磁性多功能床垫(CN-T009-01-00)*1</span>
    </el-radio>
    <br>
  </div>
</template>

<script>
export default {
  name: "CNN00011724",
  data(){
    return{
      id:''
    }
  },
  props: {
    CNN00011724: {
      type: Number,
      default: 0
    }
  },
  watch:{
    CNN00011724: {
      handler: function (val) {
        if(val === 0){
          this.id = ''
          this.CNN00011724_CD_Change()
        }
      },
      immediate: true
    }
  },
  methods:{
    CNN00011724_CD_Change(){
      this.$emit('CNN00011724_CD_Change', this.id)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-radio__label{
  font-size: 18px;

}
</style>
