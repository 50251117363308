import store from '../store'
export const dict = {
  computed: {
    $le() {
      return function(code, value) {
        const dict = this.$store.state.user.dict.find(ele => ele.listCode === code)
        if (dict && dict.items && dict.items.length) {
          const item = dict.items.find(ele => ele.code === ('' + value))
          return item ? item.title : value
        } else {
          return value
        }
      }
    },

    $ll() {
      return function(code) {
        const dict = this.$store.state.user.dict.find(ele => ele.listCode === code)
        if (dict) {
          return dict.items
        } else {
          return []
        }
      }
    },

    $lt() {
      return function(code, value) {
        const key = this.$le(code, value)
        return this.$i18n.t(key)
      }
    }
  },
  created() {
    this.loadDictLists()
  },
  methods: {
    async fetchListByKey(code) {
      const dict = this.$store.state.user.dict.find(ele => ele.listCode === code)
      if (dict) {
        return dict.items
      } else {
        const { items } = await store.dispatch('user/getDict', code)
        return items || []
      }
    },
    async loadDictLists() {
      if (this.initDict) {
        for (const key of Object.values(this.initDict)) {
          await this.fetchListByKey(key)
        }
      }
    }
  }
}
