import request from '@/utils/request'

export function fetchWithdraws(query) {
  return request({
    url: '/account/withdraw/list',
    method: 'get',
    params: query
  })
}

export function fetchAccount(query) {
  return request({
    url: '/account/withdraw/account',
    method: 'get',
    params: query
  })
}

export function applyWithdraw(data) {
  return request({
    url: '/account/withdraw/apply',
    method: 'post',
    data
  })
}

export function cancelAppls(data) {
  return request({
    url: '/account/withdraw/cancel',
    method: 'post',
    data
  })
}

export function uploadInvoice(data) {
  return request({
    url: '/account/withdraw/invoice',
    method: 'post',
    data
  })
}
