import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import { Loading } from 'element-ui'
import { fetchLanguage } from '@/api/language'

import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementMsLocale from './element-ms'

import enLocale from './en'
import zhLocale from './zh-CN'
import msLocale from './ms'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh_CN: {
    ...zhLocale,
    ...elementZhLocale
  },
  ms: {
    ...msLocale,
    ...elementMsLocale
  }
}

export function getLanguage() {
  const lang = localeLanguage()
  const loading = Loading.service({
    lock: true
  })
  fetchLanguage({ langCode: lang }).then(response => {
    const constMessages = messages[lang] || {}
    const asyncMessages = response.data || {}
    i18n.setLocaleMessage(lang, { ...constMessages, ...asyncMessages })
    loading.close()
  }).catch(() => {
    loading.close()
  })
  return lang
}

export function localeLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) {
    return chooseLanguage
  }

  let language = navigator.language || navigator.browserLanguage
  // if (language === 'zh-CN') {
  //   language = 'zh_CN'
  // }
  language = 'zh_CN'
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}

const i18n = new VueI18n({
  locale: localeLanguage(),
  messages,
  silentTranslationWarn: true,
  fallbackLocale: 'zh_CN'
})

export default i18n
