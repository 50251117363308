<template>
  <div>支付结果:<span>{{ isSuccess ? '成功' : '失败' }}</span></div>
</template>

<script>
export default {
  name: 'PayReturnPage',
  data() {
    return {
      isSuccess: true
    }
  },
  mounted() {
    this.isSuccess = true
  }
}
</script>

<style>

</style>
