<template>
  <div id="app">
    <div v-if="_isMobile()&&_widthLessThan768()&&this.isAgent">
      <p>为了保证订货平台安全性</p>
      <p>请您用电脑登录代办处订货平台</p>
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import versionJSON from '../src/utils/versionJSON.json'
import moment from "moment/moment";

export default {
  data() {
    return {
      isShow: false,
    }
  },
  computed: {
    isAgent() {
      return process.env.VUE_APP_AGENT === 'true'
    },
  },
  watch: {},
  beforeCreate() {
  },
  created() {
    console.log('------------------------------')
    console.log(process.env.VUE_APP_OUTPUT_NAME)
    console.log('版本更新时间:'+moment(versionJSON.compileTime).format('YYYY/MM/DD, HH:mm:ss'))
    console.log('------------------------------')
  },
  beforeMount() {
  },
  mounted() {
    // if (this._isMobile()) {
    //   console.log('手机端', this.isAgent)
    // } else {
    //   console.log('pc端', this.isAgent)
    // }
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    _isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    _widthLessThan768() {
      return document.body.clientWidth < 768
    }
  }
}

</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
}
</style>
