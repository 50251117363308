import axios from 'axios'

export function fetchLanguage(query) {
  return new Promise((resolve, reject) => {
    const url = '/lang/' + query.langCode + '.json'
    axios.get(url).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}
