import request from '@/utils/request'

// 奖励即将达成 分页 经销商/代办处
export function pageByDTO(query) {
  return request({
    url: '/remind/pageByDTO',
    method: 'get',
    params: query
  })
}

// 奖励即将达成 集合 经销商/代办处
export function listByDTO(query) {
  return request({
    url: '/remind/listByDTO',
    method: 'get',
    params: query
  })
}

// 奖励即将达成 查看 经销商/代办处
export function view(query) {
  return request({
    url: '/remind/view',
    method: 'get',
    params: query
  })
}
