<template>
  <div>
    <form :action="data.postUrl" method="post" id="pay_form" name="pay_form">
      <input type="hidden" name="payId" :value="data.payId" />
      <input type="hidden" name="payPlat" :value="data.payPlat" />
      <input type="hidden" name="userCode" :value="data.userCode" />
      <input type="hidden" name="merNo" :value="data.merNo" />
      <input type="hidden" name="inputNo" :value="data.inputNo" />
      <input type="hidden" name="amount" :value="data.amount" />
      <input type="hidden" name="sign" :value="data.sign" />
      <input type="hidden" name="remark" :value="data.remark" />
      <input type="hidden" name="orderTime" :value="data.orderTime" />
      <input type="hidden" name="products" :value="data.products" />
      <input type="hidden" name="fhReturnUrl" :value="data.returnUrl" />
      <input type="hidden" name="fhNotifyUrl" :value="data.notifyUrl" />
      <input type="hidden" name="strAction" value="" />
    </form>
  </div>
</template>

<script>
export default {
  name: 'PayOnlineForm',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    submit() {
      if (this.data.postUrl && this.data.postUrl.length) {
        document.getElementById('pay_form').submit()
      }
    }
  }
}
</script>

<style>

</style>
