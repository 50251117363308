<template>
  <div>
    <app-breadcrumb />
    <div class="page-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="80px" size="mini">
          <el-form-item label="期次:">
              <!-- 期数起始 -->
              <el-select
                      v-model="listQuery.fromWeek"
                      v-loading="wWeekLoading"
                      placeholder="开始期次"
                      filterable
                      style="width: 120px;"
              >
                <el-option v-for="i in listdata" :key="i.chinaWWeek" :label="i.chinaWWeek" :value="i.chinaWWeek" />
              </el-select>
              &nbsp;&nbsp; - &nbsp;&nbsp;
              <!-- 期数结束 -->
              <el-select
                      v-model="listQuery.toWeek"
                      v-loading="wWeekLoading"
                      filterable
                      style="width: 120px;"
                      placeholder="结束期次"
              >
                <el-option v-for="i in listdata" :key="i.chinaWWeek" :label="i.chinaWWeek" :value="i.chinaWWeek" />
              </el-select>
          </el-form-item>
<!--          <el-form-item label="消费券:">
                <el-select v-model="listQuery.compareFlagAll" style="width: 100px;">
                    <el-option value="" />
                    <el-option v-for="i in compareFlagAlls" :key="i.code"  :label="$lt(initDict.compareFlagAll, i.code)" :value="i.code"/>
                </el-select>
                <el-input v-model.trim="listQuery.bonusAll" style="width: 150px" clearable />
            </el-form-item>-->
          <el-form-item style="margin-left:10px;">
            <el-button  :loading="btnLoading" type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%" size="mini" border fit stripe>
        <el-table-column label="期次" prop="chinaWWeek" align="center" />
        <el-table-column label="姓名" prop="name" align="center" />
        <el-table-column label="消费券" prop="bonusAll" align="center" />
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import { bdBonusHisMemChinas, getChinaWweek1 } from '@/api/member/bonus'
export default {
  name: 'MemberBonus',
  components: { Pagination },
  data() {
    return {
      btnLoading: false,
      wWeekLoading:false,
      loading: false,
      listdata: [],
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 10,
        fromWeek: '',
        toWeek: ''
      },
      initDict: {
        compareFlagAll: 'type.compare'
       }
    }
  },
  created() {
    this.getWeeks()
  },
    computed: {
        compareFlagAlls() {
            return this.$ll(this.initDict.compareFlagAll)
        },
    },
  methods: {
    getList() {
      this.listLoading = true
      this.btnLoading = true
      bdBonusHisMemChinas({...this.listQuery}).then(res => {
        this.list = res.data.records
        this.total = +res.data.total
        this.listLoading = false
        this.btnLoading = false
      }).catch(() => {
          this.$message.error('查询失败')
          this.listLoading = false
          this.btnLoading = false
      })
    },
    // 查询
    handleFilter() {
      this.getList()
    },
    // 期次
    getWeeks() {
      this.wWeekLoading = true
      this.btnLoading = true
      getChinaWweek1().then(res => {
        this.listdata = res.data
        this.listQuery.fromWeek = res.data[0].chinaWWeek
        this.listQuery.toWeek = res.data[0].chinaWWeek
        this.wWeekLoading = false
        this.btnLoading = false
      })
    },
  }
}
</script>

<style>

</style>
