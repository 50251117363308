// 消费券查询
import request from '@/utils/request'

// getLIst
export function getBdPreHisAgentByParameter(query) {
    return request({
        url: 'agent/getBdPreHisAgentByParameter',
        method: 'GET',
        params: query
    })
}
// 期次
export function getChinaWweek1(query) {
    return request({
        url: '/agent/getChinaWweek1',
        method: 'GET',
        params: query
    })
}
