<!--
  @文件名:    出库单发货
  @file:    PoOutSend
  @author:
  @date:    2021/4/17 0017
  @time:    15:39
  @require:
-->
<template>
  <div class="PoOutSend">
    <app-breadcrumb/>
    <div class="page-container orders-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="100px" size="mini">
          <el-form-item label="发货单号:">
            <el-input v-model.trim="listQuery.sendNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="提货单号:">
            <el-input v-model.trim="listQuery.outNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="物流公司:">
            <el-input v-model.trim="listQuery.dcName" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="物流单号:">
            <el-input v-model.trim="listQuery.dcNo" style="width:170px;"/>
          </el-form-item>
          <el-form-item label="发货状态:">
            <el-select v-model="listQuery.sendStatus" clearable style="width:170px;">
              <el-option
                  v-for="item in sendStatus"
                  :key="item.code"
                  :label="$lt(initDict.sendStatus, item.code)"
                  :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
          v-loading="loading"
          :data="list"
          border
          fit
          size="mini"
          stripe
          style="width: 100%"
      >
        <el-table-column label="操作" width="60" header-align="center" align="center">
          <template slot-scope="{row}">
            <div class="opts">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <i class="button el-icon-search" @click="handleView(row)"/>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
<!--        <el-table-column label="仓库" prop="companyCode" align="center"/>-->
        <el-table-column label="发货单号" prop="sortNo" align="center"/>
        <el-table-column label="提货单号" prop="outNo" align="center"/>
        <el-table-column label="收货人姓名" prop="recName" align="center"/>
        <el-table-column label="联系电话" prop="recPhone" align="center"/>
        <el-table-column label="收货地址" prop="recAddr" align="center"/>
<!--        <el-table-column label="物流单号" prop="dcNo" align="center"/>-->
        <el-table-column label="创建时间" prop="createTime" align="center"/>
        <el-table-column label="发货时间" prop="sendTime" align="center"/>
        <el-table-column label="发货状态" prop="sendStatus" align="center">
          <template slot-scope="{row}">
            <span>{{ $lt(initDict.sendStatus, row.sendStatus) }}</span>
          </template>
        </el-table-column>-->
        <!--        <el-table-column label="单据类型" align="center">
                  <template slot-scope="{row}">
                    <span>{{ $lt(initDict.orderType, row.orderType) }}</span>
                  </template>
                </el-table-column>-->
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Pagination from "@/components/Pagination"
import {fetchPoOutSends} from "@/api/order/order-out"

export default {
  name: "PoOutSend",
  directives: {},
  components: {Pagination},
  props: [],
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      listQuery: {
        sendNo: null,
        outNo: null,
        dcName: null,
        dcNo: null,
        sendStatus: null,
        limit:10,
        page: 1
      },
      initDict: {
        sendStatus: 'status.po.sendstatus',
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {
    sendStatus() {
      return this.$ll(this.initDict.sendStatus)
    }
  },
  // 监控data中的数据变化
  watch: {},
  created() {
    this.getList()
  },
  mounted() {

  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {
    getList() {
      this.loading = true
      fetchPoOutSends(this.listQuery).then(res => {
        this.list = res.data.records
        this.total = +res.data.total || 0
        this.loading = false
      }).catch(err => {
        console.warn(err)
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleView(row) {
      this.$router.push(`/order/pooutsendsinfo?id=${row.id}`)
    },
  }
}
</script>
<style scoped>

</style>
