<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container out-detail-container">
      <el-form ref="outForm" :model="order" :rules="rules" label-width="100px" size="small">
        <div class="pane-title">收货信息</div>
        <el-divider></el-divider>
        <el-alert title="带红色星号“*”部分为必填项" type="info" style="margin-bottom:10px;"/>
        <el-form-item label="收货地址:">
          <el-radio-group v-model="useNewAddress" @change="onChangeAddress">
            <el-radio label="0">使用新地址</el-radio>
            <el-radio label="1">使用代办处地址</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收货人姓名:" prop="receiver">
          <el-input v-model.trim="order.receiver" placeholder="请填写收货人姓名" style="width:280px;"/>
        </el-form-item>
        <el-form-item label="联系电话:" prop="phone">
          <el-input  v-model.number="order.phone" :minlength="11" :maxlength="11" placeholder="请填写收货人联系电话" style="width:280px;"/>
        </el-form-item>
        <el-form-item label="备用电话:">
          <el-input v-model.trim="order.phone2" placeholder="请填写收货人备用电话" style="width:280px;"/>
        </el-form-item>
        <el-form-item label="收货方式:">
          <el-checkbox v-model="order.pickSelf">自提</el-checkbox>
        </el-form-item>
        <el-form-item v-if="order.pickSelf" label="自提地点:" prop="companyCode">
          <el-select v-model="order.companyCode" clearable style="width:280px;">
            <el-option v-for="item in companyList" :key="item.code" :label="$lt(initDict.companies, item.code)"
                       :value="item.code"/>
          </el-select>
        </el-form-item>
        <!--        prop="regionCode"-->
        <el-form-item v-if="!order.pickSelf" label="收货地址:">
          <el-col :span="6">
            <el-select
                v-model="countrymodel"
                value-key="regionId"
                placeholder="请选择国家"
                clearable
                @change="countrychange"
            >
              <el-option
                  v-for="item in addchoicecountry"
                  :key="item.regionId"
                  :label="item.regionName"
                  :value="item"
              />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select
                v-model="provincemodel"
                value-key="regionId"
                placeholder="请选择省份"
                clearable
                @change="provincechange"
            >
              <el-option
                  v-for="item in addchoiceprovince"
                  :key="item.regionId"
                  :label="item.regionName"
                  :value="item"
              />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select
                v-model="citymodel"
                value-key="regionId"
                placeholder="请选择市"
                clearable
                @change="citychange"
            >
              <el-option
                  v-for="item in addchoicecity"
                  :key="item.regionId"
                  :label="item.regionName"
                  :value="item"
              />
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select
                v-model="areamodel"
                placeholder="请选择区/县"
                clearable>
              <el-option
                  v-for="item in addchoicearea"
                  placeholder="请选择区/县"
                  :key="item.regionId"
                  :label="item.regionName"
                  :value="item.regionCode"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item v-if="!order.pickSelf" label="详细地址:" prop="address">
          <el-input v-model.trim="order.address" placeholder="请填写详细地址" style="width:480px;"/>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="order.remark" type="textarea" :rows="4" placeholder="请填写备注" maxlength="100" show-word-limit
                    style="width: 480px"/>
        </el-form-item>
        <div class="pane-title">支付信息</div>
        <el-divider></el-divider>
        <el-form-item label="金额:" style="margin-bottom:0;">
          <div>{{ pickout.totalAmount ? pickout.totalAmount.toFixed(2) : '0' }}</div>
        </el-form-item>
        <el-form-item label="消费指数:" style="margin-bottom:0;">
          <div>{{ pickout.totalFv ? pickout.totalFv.toFixed(2) : '0' }}</div>
        </el-form-item>
        <el-form-item label="换货积分:" style="margin-bottom:0;">
          <div>{{ pickout.totalPointA0 ? pickout.totalPointA0.toFixed(2) : '0' }}</div>
        </el-form-item>
        <el-form-item label="运费:" style="margin-bottom:0;">
          <div>{{ shippingFee ? shippingFee.toFixed(2) : '0' }}</div>
        </el-form-item>
        <el-form-item label="支付金额:" style="margin-bottom:0;">
          <div class="pay-amt">{{ shippingFee ? shippingFee.toFixed(2) : '0' }}</div>
        </el-form-item>
        <el-form-item label="支付密码:" prop="password" v-if="shippingFee">
          <el-input v-model.trim="order.password" placeholder="请填写您的支付密码" show-password style="width:280px;"/>
        </el-form-item>
        <div class="pane-title">产品清单</div>
        <el-table v-loading="pageLoading" :data="pickout.goods" show-summary border stripe fit
                  style="width:100%;margin: 10px 0 20px 0;">
          <el-table-column label="产品编号" prop="goodsCode" width="200" align="center"/>
          <el-table-column label="产品名称" prop="goodsName" header-align="center"/>
          <el-table-column label="数量总计" prop="quantity" width="200" align="center"/>
        </el-table>
        <div class="pane-title">订单明细</div>
        <el-table
            v-loading="pageLoading"
            :data="pickout.orders"
            row-key="id"
            default-expand-all
            :tree-props="{children: 'details'}"
            :span-method="arraySpanMethod"
            :row-class-name="tableRowClassName"
            border
            stripe
            fit
            style="width:100%;margin: 10px 0 20px 0;"
        >
          <el-table-column label="产品编号" prop="goodsCode" width="200" align="center">
            <template slot-scope="{row}">
              <span v-if="row.details">{{
                  `订单编号: ${row.orderNo}  顾客编号: ${row.memberNo}  金额: ${row.totalAmount}`
                }}</span>
              <span v-else>{{ row.goodsCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="产品名称" prop="goodsName" header-align="center"/>
          <el-table-column label="数量总计" prop="quantity" width="120" align="center"/>
          <el-table-column label="赠品" width="80" align="center">
            <template slot-scope="{row}"><span>{{ $lt(initDict.isGift, row.isGive) }}</span></template>
          </el-table-column>
        </el-table>
        <div class="btns">
          <el-button :loading="loading" type="primary" size="small" icon="el-icon-check" @click="onSubmit">保存
          </el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="onBack">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {getAlRegionsCode, getChildRegionsTo, fetChildRegions, getAlRegionsIdList} from '@/api/home'
  import {fetchOutOrderDetail, pickoutOrder} from '@/api/order/order-out'

  export default {
    name: 'OutOrderDetail',
    data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('联系电话不能为空'));
        } else if (value.toString().length !== 11 ) {
          return callback(new Error('请输入正确的11位手机号码'))
        } else {
          callback();
        }

        // if (value.toString().length !== 11 ) {
        //   return callback(new Error('请输入正确的11位手机号码'))
        // }
        // setTimeout(() => {
        //   if (!Number.isInteger(value)) {
        //     callback(new Error('请输入数字值'));
        //   } else {
        //     if (1000000000 > value || value > 99999999999) {
        //       callback(new Error('必须为11位号码'));
        //     } else {
        //       callback();
        //     }
        //   }
        // }, 1000);
      };
      return {
        // ----------------------------------------------
        countrymodel2: '',// 国家要传code值
        provincemodel2: '',// 省要传code值
        citymodel2: '',// 市要传code值
        countrymodel: '', // 新增选择国家model
        provincemodel: '', // 新增选择省存储model
        citymodel: '', // 新增选择市存储model
        areamodel: '', // 新增选择县存储model
        // -----------------------------------------------------
        addchoicecountry: [], // 新增选择国家存储
        addchoiceprovince: [], // 新增选择省存储
        addchoicecity: [], // 新增选择市存储
        addchoicearea: [], // 新增选择县存储
        loading: false,
        pageLoading: false,
        ids: [],
        order: {
          receiver: undefined,
          phone: undefined,
          phone2: undefined,
          pickSelf: false,
          companyCode: undefined,
          regionCode: undefined,
          address: undefined,
          password: undefined
        },
        pickout: {},
        rules: {
          receiver: [{required: true, message: '请填写收货人姓名', trigger: 'blur'}],
          phone: [  { validator: checkAge,required: true,  trigger: 'blur' }],
          companyCode: [{required: true, message: '请选择分公司', trigger: 'change'}],
          address: [{required: true, message: '请填写详细地址', trigger: 'blur'}]
        },
        useNewAddress: '0',
        country: [],
        province: [],
        city: [],
        disabled: [],
        initDict: {
          companies: 'po.company.pickself',
          isGift: 'po.order.isgive'
        }
      }
    },
    created() {
      this.zerochange()
    },
    computed: {
      companyList() {
        return this.$ll(this.initDict.companies)
      },
      shippingFee() {
        return this.order.pickSelf ? 0 : this.pickout.fee
      }
    },
    mounted() {
      const id = this.$route.query.id
      if (!id || !id.length) {
        this.$router.go(-1)
      } else {
        this.ids = id.split(',')
        this.getOrder()
        this.getRegions()
      }
    },
    methods: {
      // 所在地区,  需要created的
      zerochange() {
        fetChildRegions({'regionId': 0}).then(res => {
          this.addchoicecountry = res.data
        })
      },
      // 新增按钮 - 国家change事件
      countrychange(val) {
        // 选择国家后 省, 市, 区清空
        this.provincemodel = ''
        this.citymodel = ''
        this.areamodel = ''
        this.countrymodel2 = val.regionCode
        fetChildRegions({'regionId': val.regionId}).then(res => {
          this.addchoiceprovince = res.data
        }).catch(() => {
        })
      },
      // 新增按钮 - 省change事件
      provincechange(val) {
        // 选择省后  市, 区清空
        this.citymodel = ''
        this.areamodel = ''
        this.provincemodel2 = val.regionCode
        fetChildRegions({'regionId': val.regionId}).then(res => {
          this.addchoicecity = res.data
        }).catch(() => {
        })
      },
      // 新增按钮 - 市change事件,
      citychange(val) {
        // 选择市后 区清空
        this.areamodel = ''
        this.citymodel2 = val.regionCode
        fetChildRegions({'regionId': val.regionId}).then(res => {
          this.addchoicearea = res.data
        }).catch(() => {
        })
      },
      getRegions() {
        getChildRegionsTo({'regionId': 0}).then(res => {
          let regionData = res.data
          this.country = regionData
        })
      },
      regionChange(val) {
        getAlRegionsCode({'regionCode': val}).then(res => {
          let regionData = res.data
          if (regionData.length !== 0) {
            if (regionData[0].regionLevel === "2") {
              this.province = regionData
            } else if (regionData[0].regionLevel === "3") {
              this.city = regionData
            } else if (regionData[0].regionLevel === "4") {
              this.disabled = regionData
            }
          }
        })
      },
      getOrder() {
        this.pageLoading = true
        fetchOutOrderDetail({orders: this.ids.join(',')}).then(res => {
          this.pickout = res.data
          this.pageLoading = false
        }).catch(() => {
          this.pageLoading = false
        })
      },
      onChangeAddress(val) {
        if (val === '0') {
          this.order.regionCode = undefined
          this.order.address = undefined
          this.order.receiver = undefined
          this.order.phone = undefined
          this.order.phone2 = undefined
          this.countrymodel = ''
          this.areamodel = ''
          this.provincemodel = ''
          this.citymodel = ''

        } else {
          this.order.regionCode = this.pickout.address.regionCode
          this.order.address = this.pickout.address.address
          this.order.receiver = this.pickout.address.name
          this.order.phone = this.pickout.address.mobile
          this.order.phone2 = undefined
          // 选择代办处地址 返回收货地址信息
          this.order.regionCode = this.pickout.address.regionCode
          this.doareagetList()
        }
        this.$nextTick(() => {
          this.$refs['outForm'].clearValidate()
        })
      },
      //编辑返回地区
      doareagetList() {
        getAlRegionsIdList({'regionCode': this.order.regionCode}).then(res => {
          let areaList = res.data
          areaList.reverse()
          this.countrymodel = areaList[0].regionName
          if (areaList[1]) {
            this.provincemodel = areaList[1]
            fetChildRegions({'regionId': res.data[0].regionId}).then(resa => {
              this.addchoiceprovince = resa.data
            })
          }
          if (areaList[2]) {
            this.citymodel = areaList[2]
            fetChildRegions({'regionId': res.data[1].regionId}).then(resa => {
              this.addchoicecity = resa.data
            })
          }
          if (areaList[3]) {
            this.areamodel = areaList[3].regionCode
            fetChildRegions({'regionId': res.data[2].regionId}).then(resa => {
              this.addchoicearea = resa.data
            })
          }
        }).catch(() => {
        })
      },
      onBack() {
        this.$router.go(-1)
      },
      arraySpanMethod({row, columnIndex}) {
        if (row.details && row.details.length) {
          if (columnIndex === 0) {
            return [1, 4]
          } else {
            return [0, 0]
          }
        }
      },
      tableRowClassName({row}) {
        if (row.details && row.details.length) {
          return 'order-row'
        } else if (row.goodsCode === '合计') {
          return 'summary-row'
        }
        return ''
      },
      onSubmit() {
        if (this.order.pickSelf === false) {
          // 判断返回值
          if (this.shippingFee !== 0) {
            if (!this.order.password) {
              return this.$message.error('请输入支付密码')
            }
          }
          if (this.areamodel) {
            this.order.regionCode = this.areamodel
          } else if (this.citymodel) {
            this.order.regionCode = this.citymodel.regionCode
          } else {
            return this.$message.error('地区最少填写到市(地区请最少填写到第三位)')
          }
        }
        this.$refs['outForm'].validate((valid) => {
          if (valid) {
            this.$confirm('确定提货?', '提货', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'success'
            }).then(() => {
              // if(this.order.phone){
              //
              // }
              this.loading = true
              pickoutOrder({...this.order, orders: this.ids}).then((res) => {
                if (res.success) {
                  this.$notify({
                    title: '成功',
                    message: '提货成功!',
                    type: 'success'
                  })
                }
                this.$router.replace('/order/out')
                this.loading = false
              }).catch(err => {
                console.warn(err)
                this.loading = false
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '操作取消'
              })
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .out-detail-container {
    .btns {
      margin: 20px 0;
      text-align: center;
    }

    .pay-amt {
      font-weight: bold;
      color: #077e25;
    }

    .el-table {
      .order-row {
        .cell {
          text-align: left;
          font-weight: bold;
        }
      }

      .summary-row {
        color: #FF0000;
      }
    }
  }
</style>
