<template>
  <div>
    <app-breadcrumb />
    <div class="page-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="80px" size="mini">
          <el-form-item label="截止日期:">
            <el-date-picker
                    v-model="listQuery.toDate"
                    style="width: 190px"
                    type="datetime"
                    placeholder="日期选择">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="截止期数:">
            <el-select
                    v-model="listQuery.wweek"
                    v-loading="wWeekLoading"
                    filterable
                    style="width: 100px;"
            >
              <el-option v-for="i in listdata" :key="i.week" :label="i.week" :value="i.week" />
            </el-select>
          </el-form-item>
<!--          <el-form-item label="总计:">-->
<!--            <el-select v-model="listQuery.compareFlagAll" style="width: 90px;">-->
<!--              <el-option value="" />-->
<!--              <el-option v-for="i in compareFlagAlls" :key="i.code"  :label="$lt(initDict.compareFlagAll, i.code)" :value="i.code" />-->
<!--            </el-select>-->
<!--            <el-input v-model.trim="listQuery.totalPv" style="width: 120px" />-->
<!--          </el-form-item>-->
          <el-form-item style="margin-left:10px;">
            <el-button  :loading="btnLoading" type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table v-loading="loading" :data="list" style="width: 100%" size="mini" border fit stripe>
        <el-table-column :label="$t('miMember.memberNo')" prop="memberNo" align="center" />
        <el-table-column :label="$t('miMember.name')" prop="memberName" align="center" />
<!--        &lt;!&ndash;        级别&ndash;&gt;-->
<!--        <el-table-column :label="$t('级别')" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <div>-->
<!--              <span v-if="scope.row.levelType">{{$t('label.auto.ship.status.inactive')}}</span>-->
<!--              <span v-else-if="scope.row.customerType === 0">普通顾客</span>-->
<!--              <span v-else-if="scope.row.customerType === 1">优惠顾客</span>-->
<!--              <span v-else> {{ $lt(initDict.level, scope.row.levelType) }}</span>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash;          荣衔&ndash;&gt;-->
<!--        <el-table-column :label="$t('荣衔')" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <div>-->
<!--              <span v-if="scope.row.cardType || scope.row.cardType === 0">{{$t('label.auto.ship.status.inactive')}}</span>-->
<!--              <span v-else-if="scope.row.customerType === 0">普通顾客</span>-->
<!--              <span v-else-if="scope.row.customerType === 1">优惠顾客</span>-->
<!--              <span v-else> {{ $lt(initDict.cardType, scope.row.cardType) }}</span>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <!--          加入时间-->
        <el-table-column :label="$t('time.join')" prop="registerDate" align="center" width="75" />
        <el-table-column :label="$t('miAgent.startWeek')" prop="periodWeek" align="center" />
        <el-table-column :label="$t('the.first.cycle')" align="center">
        <template slot-scope="scope">
          <div>
              <span v-if="scope.row.totalPv1 < 100000.00 && curTime <= 366">{{ scope.row.totalPv1 }}</span>
              <span v-if="scope.row.totalPv1 < 100000.00 && curTime > 366">未达成</span>
              <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv1 / 100000.00)) + '次' }}</span>
          </div>
        </template>
        </el-table-column>
        <el-table-column :label="$t('the.second.cycle')" align="center">
        <template slot-scope="scope">
          <div>
              <span v-if="curTime < 366">{{$t('not.start')}}</span>
<!--              <div v-else-if="scope.row.totalPv2 < 100000.00">-->
<!--                  -->
<!--              </div>-->
              <span v-else-if="scope.row.totalPv2 < 100000.00 && curTime > 366 && curTime <= 732">{{ scope.row.totalPv2 }}</span>
              <span v-else-if="scope.row.totalPv2 < 100000.00 && curTime > 732">未达成</span>
              <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv2 / 100000.00)) + '次' }}</span>
          </div>
        </template>
        </el-table-column>
        <el-table-column :label="$t('the.third.cycle')" align="center">
          <template slot-scope="scope">
            <div>
                <span v-if="curTime < 732">{{$t('not.start')}}</span>
                <span v-if="scope.row.totalPv3 < 100000.00 && 732 < curTime  && curTime <= 1098">{{ scope.row.totalPv3 }}</span>
                <span v-if="scope.row.totalPv3 < 100000.00 && curTime > 1098">未达成</span>
                <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv3 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('the.fourth.cycle')"  align="center">
          <template slot-scope="scope">
            <div>
                <span v-if="curTime < 1098">{{$t('not.start')}}</span>
                <span v-if="scope.row.totalPv4 < 100000.00 && 1098 < curTime  && curTime <= 1464">{{ scope.row.totalPv4 }}</span>
                <span v-if="scope.row.totalPv4 < 100000.00 && curTime > 1464">未达成</span>
                <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv4 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('第五周期')" align="center">
          <template slot-scope="scope">
            <div>
                <span v-if="curTime < 1464">{{$t('not.start')}}</span>
                <span v-if="scope.row.totalPv5 < 100000.00 && 1464 < curTime && curTime <= 1830">{{ scope.row.totalPv5 }}</span>
                <span v-if="scope.row.totalPv5 < 100000.00 && curTime > 1830">未达成</span>
                <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv5 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('第六周期')" align="center">
          <template slot-scope="scope">
            <div>
                <span v-if="curTime < 1830">{{$t('not.start')}}</span>
                <span v-if="scope.row.totalPv6 < 100000.00 && 1830 < curTime && curTime <= 2196">{{ scope.row.totalPv6 }}</span>
                <span v-if="scope.row.totalPv6 < 100000.00 && curTime > 2196">未达成</span>
                <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv6 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('第七周期')" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="curTime < 2196">{{$t('not.start')}}</span>
              <span v-if="scope.row.totalPv7 < 100000.00 && 2196  < curTime && curTime <= 2562">{{ scope.row.totalPv7 }}</span>
              <span v-if="scope.row.totalPv7 < 100000.00 && curTime > 2562">未达成</span>
              <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv7 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('第八周期')" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="curTime < 2562">{{$t('not.start')}}</span>
              <span v-if="scope.row.totalPv8 < 100000.00 && 2562  < curTime && curTime <= 2928">{{ scope.row.totalPv8 }}</span>
              <span v-if="scope.row.totalPv8 < 100000.00 && curTime > 2928">未达成</span>
              <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv8 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('第九周期')" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="curTime < 2928">{{$t('not.start')}}</span>
              <span v-if="scope.row.totalPv9 < 100000.00 && 2928  < curTime && curTime <= 3294">{{ scope.row.totalPv9 }}</span>
              <span v-if="scope.row.totalPv9 < 100000.00 && curTime > 3294">未达成</span>
              <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv9 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('第十周期')" align="center">
          <template slot-scope="scope">
            <div>
              <span v-if="curTime < 2928">{{$t('not.start')}}</span>
              <span v-if="scope.row.totalPv9 < 100000.00 && 3294  < curTime && curTime <= 3660">{{ scope.row.totalPv9 }}</span>
              <span v-if="scope.row.totalPv9 < 100000.00 && curTime > 3660">未达成</span>
              <span v-else>{{ '已完成' + (Math.floor(scope.row.totalPv9 / 100000.00)) + '次' }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      <p style="color: red; margin: 0; font-size: 12px">
        目前显示的旅游业绩包含短期定向旅游促销业绩，最终10万海外游是否达标以公司提供名单为准！
      </p>
    </div>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import { fetchWeeks } from '@/api/home'
  import { bdPointRecommends } from '@/api/member/point-recommend'
  export default {
    name: 'MemberPointRecommend',
    components: { Pagination },
    data() {
      return {
          curTime: '',
          curDate: '',
          btnLoading: false,
          wWeekLoading:false,
          loading: false,
          listdata: [],
          list: [],
          total: 0,
          listQuery: {
              page: 1,
              limit: 10,
              toDate: '',
              wweek: '',
              totalPv: ''
          },
          initDict: {
              compareFlagAll: 'type.compare'
              // level: 'cn.member.level',
              // cardType: 'cn.mimember.cardtype'
         }
       }
    },
    created() {
      // this.getList()
      this.getWeeks()
    },
    computed: {
      compareFlagAlls() {
        return this.$ll(this.initDict.compareFlagAll)
      }
      // levels() {
      //   return this.$ll(this.initDict.level)
      // },
      // cardTypes() {
      //   return this.$ll(this.initDict.cardType)
      // }
    },
    methods: {
      getList() {
        this.listLoading = true
        this.btnLoading = true
        bdPointRecommends({...this.listQuery}).then(res => {
          this.doTime(res.data.records[0].registerDate, res.data.records[0].curdate)
          this.list = res.data.records
          this.total = +res.data.total
          this.listLoading = false
          this.btnLoading = false
        }).catch(() => {
          this.$message.error('查询失败')
          this.listLoading = false
          this.btnLoading = false
        })
      },
      handleFilter() {
        this.getList()
      },
      doTime(a, b) {
          let stime = new Date(a).getTime()
          let etime = new Date(b).getTime()
          let usedTime = etime - stime
          this.curTime= Math.ceil(usedTime/(24*3600*1000))
      },
      // 期次
      getWeeks() {
        this.wWeekLoading = true
        this.btnLoading = true
        fetchWeeks().then(res => {
          this.listdata = res.data
          this.wWeekLoading = false
          this.btnLoading = false
        })
      },
    }
  }
</script>

<style>

</style>
