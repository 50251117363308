var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticStyle:{"font-size":"18px","color":"red"}},[_vm._v("每购买一套请选择6个护腰,6个护膝,3个绝缘手套,3个导电手套")]),_c('p',[_vm._v("请选择护膝")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.PK02624_HX_list,"header-align":"center"}},[_c('el-table-column',{attrs:{"prop":"code","align":"center","label":_vm.$t('BiGoods.goodsCode')}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":_vm.$t('BiGoods.name')}}),_c('el-table-column',{attrs:{"prop":"pv","align":"center","label":_vm.$t('PV')}}),_c('el-table-column',{attrs:{"prop":"proPrice","align":"center","label":_vm.$t('pd.price')}}),_c('el-table-column',{attrs:{"label":"购买数量","prop":"quantity","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","min":0},on:{"change":function($event){return _vm.PK_HX_Change(row)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]}}])})],1),_c('p',[_vm._v("请选择护腰")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.PK02624_HY_list,"header-align":"center"}},[_c('el-table-column',{attrs:{"prop":"code","align":"center","label":_vm.$t('BiGoods.goodsCode')}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":_vm.$t('BiGoods.name')}}),_c('el-table-column',{attrs:{"prop":"pv","align":"center","label":_vm.$t('PV')}}),_c('el-table-column',{attrs:{"prop":"proPrice","align":"center","label":_vm.$t('pd.price')}}),_c('el-table-column',{attrs:{"label":"购买数量","prop":"quantity","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","min":0},on:{"change":function($event){return _vm.PK_HY_Change(row)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]}}])})],1),_c('p',[_vm._v("请选择绝缘手套")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.PK02624_JY_list,"header-align":"center"}},[_c('el-table-column',{attrs:{"prop":"code","align":"center","label":_vm.$t('BiGoods.goodsCode')}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":_vm.$t('BiGoods.name')}}),_c('el-table-column',{attrs:{"prop":"pv","align":"center","label":_vm.$t('PV')}}),_c('el-table-column',{attrs:{"prop":"proPrice","align":"center","label":_vm.$t('pd.price')}}),_c('el-table-column',{attrs:{"label":"购买数量","prop":"quantity","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","min":0},on:{"change":function($event){return _vm.PK_JY_Change(row)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]}}])})],1),_c('p',[_vm._v("请选择导电手套")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":_vm.PK02624_DD_list,"header-align":"center"}},[_c('el-table-column',{attrs:{"prop":"code","align":"center","label":_vm.$t('BiGoods.goodsCode')}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":_vm.$t('BiGoods.name')}}),_c('el-table-column',{attrs:{"prop":"pv","align":"center","label":_vm.$t('PV')}}),_c('el-table-column',{attrs:{"prop":"proPrice","align":"center","label":_vm.$t('pd.price')}}),_c('el-table-column',{attrs:{"label":"购买数量","prop":"quantity","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{staticStyle:{"width":"100px"},attrs:{"size":"mini","min":0},on:{"change":function($event){return _vm.PK_DD_Change(row)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }