<template>
  <div>
    <app-breadcrumb />
    <div class="page-container member-password">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane label="登录密码" name="first">
          <el-form ref="passwordForm" :model="password" :rules="rules" label-width="140px" size="small">
            <el-form-item label="旧登录密码:" prop="oldPassword">
              <el-input v-model.trim="password.oldPassword" placeholder="请填写旧密码" show-password />
            </el-form-item>
            <el-form-item label="新登录密码:" prop="newPassword">
              <el-input v-model.trim="password.newPassword" placeholder="请填写新密码" show-password />
            </el-form-item>
            <el-form-item label="重复密码:" prop="rePassword">
              <el-input v-model.trim="password.rePassword" placeholder="请再次填写新密码" show-password />
            </el-form-item>
            <el-form-item label="短信验证码:" prop="smsCode">
              <div class="smscode">
                <el-input v-model.trim="password.smsCode" placeholder="请填写短信验证码" style="width:150px;" />
                <el-button type="defualt" :loading="sendLoading" :disabled="send" @click="sendSms">{{ send ? `${time}秒后重发` : '发送验证码' }}</el-button>
                <el-tag type="info">短信验证码默认发送至注册绑定手机号：<span class="mobile">{{ mobile }}</span></el-tag>
              </div>
            </el-form-item>
            <el-form-item>
              <el-tag type="info">密码长度为6-16位，请勿以自己的生日、身份证号码或其它简单的数字做为密码</el-tag>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onPasswordSubmit">保存</el-button>
              <el-button type="default" @click="onPasswordReset">重置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="支付密码" name="second">
          <el-form ref="advPasswordForm" :model="advPassword" :rules="rules2" label-width="140px" size="small">
            <el-form-item label="旧支付密码:" prop="oldPassword">
              <el-input v-model.trim="advPassword.oldPassword" placeholder="请填写旧密码" show-password />
            </el-form-item>
            <el-form-item label="新支付密码:" prop="newPassword">
              <el-input v-model.trim="advPassword.newPassword" placeholder="请填写新密码" show-password />
            </el-form-item>
            <el-form-item label="重复密码:" prop="rePassword">
              <el-input v-model.trim="advPassword.rePassword" placeholder="请再次填写新密码" show-password />
            </el-form-item>
            <el-form-item label="短信验证码:" prop="smsCode">
              <div class="smscode">
                <el-input v-model.trim="advPassword.smsCode" placeholder="请填写短信验证码" style="width:150px;" />
                <el-button type="defualt" :loading="sendLoading" :disabled="send" @click="sendSms">{{ send ? `${time}秒后重发` : '发送验证码' }}</el-button>
                <el-tag type="info">短信验证码默认发送至注册绑定手机号：<span class="mobile">{{ mobile }}</span></el-tag>
              </div>
            </el-form-item>
            <el-form-item>
              <el-tag type="info">密码长度为6-16位，且包含大写字母、小写字母、数字、特殊符号其中两种，请勿以自己的生日、身份证号码或其它简单的数字做为密码</el-tag>
            </el-form-item>
            <el-form-item>
              <el-button :loading="advLoading" type="primary" @click="onAdvPasswordSubmit">保存</el-button>
              <el-button type="default" @click="onAdvPasswordReset">重置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="找回支付密码" name="third">
          <el-form ref="paymentpasswordForm" :model="paymentpassword" :rules="rules3" label-width="140px" size="small">
            <el-form-item label="证件号" prop="paperNo">
              <el-input v-model.trim="paymentpassword.paperNo" placeholder="请输入预留的证件号">
              </el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model.trim="paymentpassword.mobile" placeholder="请输入预留的手机号">
              </el-input>
            </el-form-item>
            <el-form-item label="新支付密码" prop="newPassword">
              <el-input v-model.trim="paymentpassword.newPassword" placeholder="请输入新的支付密码">
              </el-input>
            </el-form-item>
            <el-form-item label="短信验证码:" prop="smsCode">
              <div class="smscode">
                <el-input v-model.trim="paymentpassword.smsCode" placeholder="请填写短信验证码" style="width:150px;" />
                <el-button type="defualt" :loading="sendLoading" :disabled="send" @click="sendSms">{{ send ? `${time}秒后重发` : '发送验证码' }}</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-tag type="info">密码长度为6-16位，请勿以自己的生日、身份证号码或其它简单的数字做为密码</el-tag>
            </el-form-item>
            <el-form-item>
              <el-button :loading="advLoading" type="primary" @click="paymentpasswordFormSubmit">保存</el-button>
              <el-button type="default" @click="paymentpasswordFormReset">重置</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
    </el-tabs>
    </div>
  </div>
</template>

<script>
import { fetchMobile } from '@/api/member/profile'
import { editPassword, editAdvPassword, sendSmsCode,findadvpassword } from '@/api/member/password'
export default {
  name: 'MemberPassword',
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请填写新密码'))
      } else if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度为6~16位'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请再次填写新密码'))
      } else if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度为6~16位'))
      } else if (value !== this.password.newPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    const validateAdvPass2 = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请再次填写新密码'))
      } else if (value.length < 6 || value.length > 16) {
        callback(new Error('密码长度为6~16位'))
      } else if (value !== this.advPassword.newPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      activeTab: 'first',
      mobile: '',
      sendLoading: false,
      send: false,
      time: 60,
      password: {
        oldPassword: '',
        newPassword: '',
        rePassword: '',
        smsCode: ''
      },
      advPassword: {
        oldPassword: '',
        newPassword: '',
        rePassword: '',
        smsCode: ''
      },
      paymentpassword:{
        paperNo:'',
        mobile:'',
        newPassword:'',
        smsCode:'',
      },
      rules: {
        oldPassword: [{ required: true, message: '请填写旧登录密码', trigger: 'blur' }],
        newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
        rePassword: [{ required: true, validator: validatePass2, trigger: 'blur' }],
        smsCode: [{ required: true, message: '请填写短信验证码', trigger: 'blur' }]
      },
      rules2: {
        oldPassword: [{ required: true, message: '请填写旧支付密码', trigger: 'blur' }],
        newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
        rePassword: [{ required: true, validator: validateAdvPass2, trigger: 'blur' }],
        smsCode: [{ required: true, message: '请填写短信验证码', trigger: 'blur' }]
      },
      rules3: {
        paperNo: [{ required: true, message: '请填写预留身份证', trigger: 'blur' }],
        mobile: [{ required: true, message: '请填写预留手机号', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请填写新的支付密码', trigger: 'blur' }],
        smsCode: [{ required: true, message: '请填写短信验证码', trigger: 'blur' }],
      },
      loading: false,
      advLoading: false
    }
  },
  mounted() {
    this.getMobile()
  },
  methods: {
    getMobile() {
      fetchMobile().then(res => {
        this.mobile = res.data
      })
    },
    sendSms() {
      this.sendLoading = true
      sendSmsCode().then(() => {
        this.sendLoading = false
        this.send = true
        this.$message.success(`已成功发送验证码到手机号${this.mobile}`)
        const timer = setInterval(() => {
          this.time--
          if (this.time <= 0) {
            this.send = false
            this.time = 60
            clearInterval(timer)
          }
        }, 1000)
      }).catch(err => {
        console.warn(err)
        this.sendLoading = false
      })
    },
    onPasswordSubmit() {
      this.$refs['passwordForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          editPassword(this.password).then(() => {
            this.$message.success('保存新密码成功')
            this.onPasswordReset()
            this.loading = false
            this.sendLoading = false
            this.send = false
            this.time = 60
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onAdvPasswordSubmit() {
      this.$refs['advPasswordForm'].validate((valid) => {
        if (valid) {
          this.advLoading = true
          editAdvPassword(this.advPassword).then(() => {
            this.$message.success('保存新密码成功')
            this.onAdvPasswordReset()
            this.advLoading = false
            this.sendLoading = false
            this.send = false
            this.time = 60
          }).catch(err => {
            console.warn(err)
            this.advLoading = false
          })
        }
      })
    },
    paymentpasswordFormSubmit() {
      this.$refs['paymentpasswordForm'].validate((valid) => {
        if (valid) {
          this.advLoading = true
          findadvpassword(this.paymentpassword).then(() => {
            this.$message.success('保存新密码成功')
            this.onAdvPasswordReset()
            this.advLoading = false
            this.sendLoading = false
            this.send = false
            this.time = 60
          }).catch(err => {
            console.warn(err)
            this.advLoading = false
          })
        }
      })
    },
    onPasswordReset() {
      this.$refs['passwordForm'].resetFields()
    },
    onAdvPasswordReset() {
      this.$refs['advPasswordForm'].resetFields()
    },
    paymentpasswordFormReset() {
      this.$refs['paymentpasswordForm'].resetFields()
    }
  }
}
</script>

<style lang="scss">
.member-password {
  .el-form-item__content {
    .el-input {
      width: 280px;
    }
    .smscode {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 10px;
        width: 120px;
      }
      .el-tag {
        margin-left: 10px;
        .mobile {
          color: #FF0000;
        }
      }
    }
  }
}
</style>
