import request from '@/utils/request'

//查询
export function memberCheck(query) {
    return request({
        url: 'agent/memberCheck',
        method: 'get',
        params: query
    })
}
//保存
export function saveWxMember(data) {
    return request({
        url: 'agent/saveWxMember',
        method: 'post',
        data
    })
}
