import request from '@/utils/request'

// 验证经销商
export function miMemberInfo(query) {
    return request({
        url: '/common/miMemberInfo',
        method: 'get',
        params: query
    })
}

// 验证经销商 全部
export function findMemberInfo(query) {
    return request({
        url: '/common/findMemberInfo',
        method: 'get',
        params: query
    })
}

// 验证安置人市场个数
export function miMemberRightNum(query) {
    return request({
        url: '/common/miMemberRightNum',
        method: 'get',
        params: query
    })
}

// 获取系统时间
export function getSysDate() {
    return request({
        url: '/common/getSysDate',
        method: 'get'
    })
}
