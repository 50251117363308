<template>
  <div class="AgentInfo">
    <app-breadcrumb/>
    <div class="page-container">
      <template>
        <el-tabs v-model="activeName">
          <el-tab-pane label="基本信息" name="first">
            <el-form label-width="130px">
              <el-form-item label="所属分公司:">
                {{ this.listQuery.companyCode }}
              </el-form-item>
              <el-form-item label="代办处编号:">
                {{ this.listQuery.agentNo }}
              </el-form-item>
              <el-form-item label="代办处名称:">
                {{ this.listQuery.agentName }}
              </el-form-item>
              <el-form-item label="代办处级别:">
                {{ $lt(initDict.cardtype, this.listQuery.cardType ) }}
              </el-form-item>
              <el-form-item label="代办处负责人编号:">
                {{ this.listQuery.recommendNo }}
              </el-form-item>
              <el-form-item label="登录绑定电话:">
                {{ this.listQuery.loginMobile }}
              </el-form-item>
              <el-form-item label="别名:">
                <el-input
                    v-model.trim="listQuery.agentAlias"
                    size="mini"
                    style="width: 300px"
                    :disabled="disabled"
                    placeholder="别名"
                />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName">
          <el-tab-pane label="收货信息" name="first">
            <el-form label-width="130px">
              <el-form-item label="所在地区">
                {{ listQuery.agentAddressString }}
              </el-form-item>
              <el-form-item label="邮政编码:">
                <el-input
                    v-model.trim="listQuery.storePost"
                    size="mini"
                    style="width: 300px"
                    :disabled="disabled"
                    placeholder="邮政编码"
                />
              </el-form-item>
              <el-form-item label="移动电话:">
                <el-input
                    v-model.number="listQuery.mobile"
                    size="mini"
                    style="width: 300px"
                    maxlength="11"
                    :disabled="disabled"
                    placeholder="移动电话"
                />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName">
          <el-tab-pane label="银行账户信息" name="first">
            <el-form label-width="130px">
              <el-form-item label="开户银行:">
                {{ this.listQuery.accountBank }}
              </el-form-item>
              <el-form-item label="户名:">
                {{ this.listQuery.accountName }}
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </template>
      <div style="margin-left: 40%">
        <el-button v-show="modify" icon="el-icon-edit-outline" type="primary" @click="modifyedit">修改</el-button>
        <el-button v-show="determine1" icon="el-icon-circle-check" type="primary" @click="determine">确定</el-button>
        <el-button v-show="determine1" icon="el-icon-circle-close" type="primary" @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import {agentProfileModify, agentProfileModifyEdit} from "@/api/agent";
export default {
  name: "AgentInfo",
  data() {
    return {
      modify: true,
      determine1: false,
      disabled: true,
      regionId: 0,
      // ----------------------------------------------
      countrymodel2: '', // 国家要传code值
      provincemodel2: '', // 省要传code值
      citymodel2: '', // 市要传code值
      countrymodel: '', // 新增选择国家model
      provincemodel: '', // 新增选择省存储model
      citymodel: '', // 新增选择市存储model
      areamodel: '', // 新增选择县存储model
      // -----------------------------------------------------
      addchoicecountry: [], // 新增选择国家存储
      addchoiceprovince: [], // 新增选择省存储
      addchoicecity: [], // 新增选择市存储
      addchoicearea: [], // 新增选择县存储
      // --------------------------------------
      wWeekLoading: false,
      activeName: 'first',
      listQuery: {
        companyCode: null,
        agentNo: null,
        agentName: null,
        cardType: null,
        recommendNo: null,
        loginMobile: null,
        agentAlias: null,
        agentAddressString: null,
        storePost: null,
        mobile: null,
        accountBank: null,
        accountName: null
      },
      initDict: {
        cardtype: 'miagent.cardtype'
      }
    }
  },
  computed: {
    cardtypes() {
      return this.$ll(this.initDict.cardtype)
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      agentProfileModify().then(res => {
        console.log(res, '147')
        this.listQuery = res.data
      })
    },
    modifyedit() {
      this.modify = false
      this.disabled = false
      this.determine1 = true
    },
    determine() {
      agentProfileModifyEdit({...this.listQuery}).then(() => {
        this.modify = true
        this.disabled = true
        this.determine1 = false
        this.getList()
      })
    },
    close() {
      this.modify = true
      this.disabled = true
      this.determine1 = false
      this.getList()
    }
  }
}
</script>

<style scoped>

</style>
