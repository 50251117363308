<!--
  @文件名:    出库单管理查看详情
  @file:    OutOrderMDetail
  @author:
  @date:    2021/4/16 0016
  @time:    18:15
  @require:
-->
<template>
  <div class="OutOrderMDetail">
    <app-breadcrumb/>
    <div class="page-container out-detail-container">
      <el-form ref="outForm" :model="order" label-width="100px" size="small">
        <div class="pane-title">基本信息</div>
        <el-divider></el-divider>
        <el-form-item label="出库单号:">{{ order.outNo }}</el-form-item>
        <el-form-item label="出库单状态:">{{ $lt(initDict.checkYesno, order.status) }}</el-form-item>
        <el-form-item label="发货状态:">{{ $lt(initDict.sendStatus, order.sendStatus) }}</el-form-item>
        <el-form-item label="收货人姓名:">{{ order.recName }}</el-form-item>
        <el-form-item label="联系电话:">{{ order.recPhone }}</el-form-item>
        <el-form-item label="收货地址:">{{ order.recAddr }}</el-form-item>
        <el-form-item label="金额:">{{ order.totalAmount }}</el-form-item>
        <el-form-item label="消费指数:">{{ order.totalFv }}</el-form-item>
        <el-form-item label="换货积分:">{{ order.totalpointA0 }}</el-form-item>
        <el-form-item label="运费:">{{ order.sendFee }}</el-form-item>
        <el-form-item label="支付金额:"><span style="color:green;font-weight:600">{{ order.sendFee }}</span></el-form-item>
        <el-form-item v-show="order.remark" label="备注:">{{ order.remark }}</el-form-item>
        <div class="pane-title">产品清单</div>
        <el-table v-loading="loading" :data="list2" show-summary border stripe fit
                  style="width:100%;margin: 10px 0 20px 0;">
          <el-table-column label="产品编号" prop="goodsCode" align="center"/>
          <el-table-column label="产品名称" prop="goodsName" align="center"/>
          <el-table-column label="数量总计" prop="quantity" align="center"/>
        </el-table>
        <div class="pane-title">订单明细</div>
        <div :key="idx" v-for="(itemlist, idx) in list3">
          <p>订单编号 {{ itemlist.orderNo }} 顾客编号 {{ itemlist.memberNo }} 金额 {{ itemlist.totalAmount }} </p>
          <el-table
              v-loading="loading"
              :data="list5[idx]"
              row-key="id"
              show-summary
              :row-class-name="tableRowClassName"
              border
              stripe
              fit
              style="width:100%;"
          >
            <el-table-column label="产品编号" prop="goodsCode" align="center"/>
            <el-table-column label="产品名称" prop="goodsName" align="center"/>
            <el-table-column label="数量" prop="quantity" align="center"/>
            <el-table-column label="赠品" align="center">
              <template slot-scope="{row}"><span>{{ $lt(initDict.isGive, row.isGive) }}</span></template>
            </el-table-column>
          </el-table>
        </div>
        <div v-show="list4.length">
          <div class="pane-title">出库单明细</div>
          <el-table
              :data="list4"
              fit
              stripe
              border
              style="width: 100%"
          >
            <el-table-column
                label="操作"
                width="100">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)"  icon="el-icon-search" size="small"></el-button>
              </template>
            </el-table-column>
            <el-table-column prop="companyCode" label="仓库编号"/>
            <el-table-column prop="sortNo" label="发货单号"/>
            <el-table-column prop="outNo" label="提货单号"/>
            <el-table-column prop="sendtime" label="发货时间"/>
            <el-table-column prop="recCode" label="收货人"/>
            <el-table-column label="发货状态" align="center">
              <template slot-scope="{row}">
                {{ $lt(initDict.sendInfo, row.sendStatus) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="btns">
          <el-button type="default" size="small" icon="el-icon-close" @click="onBack">返回</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog
        width="50%"
        title="查看"
        :visible.sync="innerVisible"
        append-to-body
    >
      <el-form ref="outForm" :model="order2" label-width="100px" size="small">
        <div class="pane-title">发货单明细</div>
        <el-table v-loading="loading" :data="list8" show-summary border stripe fit style="width:100%;margin: 10px 0 20px 0;">
          <el-table-column label="产品编号" prop="goodscode" align="center" />
          <el-table-column label="产品名称" prop="goodsname" align="center" />
          <el-table-column label="订货数量" prop="quantity" align="center" />
          <el-table-column label="已发货" prop="sendqty" align="center" />
          <el-table-column label="本次发货" prop="sendqtyNow" align="center" />
          <el-table-column label="欠货" prop="chaqty" align="center" />
        </el-table>
        <div class="pane-title">商品明细</div>
        <el-table
            v-loading="loading"
            :data="list9"
            row-key="id"
            :row-class-name="tableRowClassName"
            border
            stripe
            fit
            style="width:100%;"
        >
          <el-table-column label="产品号" prop="orderNo" align="center" />
          <el-table-column label="姓名" prop="userName" align="center" />
          <el-table-column label="编号" prop="userCode" align="center" />
          <el-table-column label="订单类型" align="center">
            <template slot-scope="{row}">
              <span>{{ $lt(initDict.allOrderType, row.orderType) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="产品编码" prop="goodscode" align="center" />
          <el-table-column label="产品名称" prop="goodsname" align="center" />
          <el-table-column label="订货数量" prop="quantity" align="center" />
          <el-table-column label="已发货" prop="sendqty" align="center" />
<!--          <el-table-column label="赠品" prop="isgive" align="center">-->
<!--            <template slot-scope="{row}">-->
<!--              <span>{{ $lt(initDict.yesNo, row.isgive) }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="本次发货" prop="sendqtyNow" align="center" />
<!--          <el-table-column label="欠货" prop="chaqty" align="center" />-->
        </el-table>
        <div class="btns">
          <el-button type="default" size="small" style="margin-left: 90%;margin-top: 20px" icon="el-icon-close" @click="innerVisible = false">返回</el-button>
        </div>
      </el-form>
      </el-dialog>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import {poOutsInfo, fetchPoOutSendsInfo} from "@/api/order/order-out";

export default {
  name: "OutOrderMDetail",
  directives: {},
  components: {},
  props: [],
  data() {
    return {
      innerVisible:false,
      loading: false,
      order: {},
      order2: {},
      pickout: {},
      list: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list8: [],
      list9: [],
      initDict: {
        allOrderType:'po.allordertype.cn',
        checkYesno: 'check.yesno',
        sendStatus: 'status.po.sendstatus',
        isGive: 'po.order.isgive',
        sendInfo: 'ai.sendinfo',
      }
    }
  },
  computed: {},
  mounted() {
    const id = this.$route.query.id
    if (!id || !id.length) {
      this.$router.go(-1)
    } else {
      this.loading = true
      poOutsInfo(id).then(response => {
        // console.log(response)
        this.order = response.data.poOut
        this.list2 = response.data.poOrderDetailGoods ? response.data.poOrderDetailGoods: []
        this.list3 = response.data.poOrders ? response.data.poOrders: []
        this.list5= response.data.poOrderDetails ? response.data.poOrderDetails: []
        const listB = []
        const listBB = []
        this.list3.forEach(valB => {
          const listnew = []
          this.list5.forEach(valBB => {
            if (valB.id === valBB.orderId) {
              listnew.push(valBB)
            }
          })
          if (listnew.length) {
            listBB.push(listnew)
            listB.push(valB)
          }
        })
        this.list3 = listB
        this.list5 = listBB
        this.list4 = response.data.poOutSends ? response.data.poOutSends: []
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    handleClick(row) {
      console.log(row)
      fetchPoOutSendsInfo(row.id).then(res=>{
        this.innerVisible = true
        this.order2 = res.data.poOut
        this.list8 = res.data.poOutGoods
        this.list9 = res.data.poOrderGoods
        console.log(res)
      }).catch(err=>{
        console.error(err)
      })
      // this.$router.push(`/order/outordetail?id=${row.id}`)
    },
    tableRowClassName({row}) {
      if (row.details && row.details.length) {
        return 'order-row'
      } else if (row.goodsCode === '合计') {
        return 'summary-row'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.out-detail-container {
  .btns {
    margin: 20px 0;
    text-align: center;
  }

  .pay-amt {
    font-weight: bold;
    color: #077e25;
  }

  .el-table {
    .order-row {
      .cell {
        text-align: left;
        font-weight: bold;
      }
    }


    .summary-row {
      color: #FF0000;
    }
  }
  ::v-deep .el-table__footer-wrapper tbody td {
    color: red !important;
    font-weight: bold;
  }
}
</style>
