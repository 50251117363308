<!--
  @文件名:    查看详情
  @file:    OrderShipView
  @author:  LX
  @date:    2021/4/28
  @time:    15:19
  @require:
-->
<template>
    <div class="vieweditmember-bussiness">
        <app-breadcrumb/>
        <el-form style="padding: 0 20px; font-size: 16px" v-loading="listLoading" label-position="right" label-width="150px" :model="listQuery">
            <h2 class="h2right">基本信息</h2>
            <hr style="border: 1px dashed ; width: 100%;height: 1px;"/>
            <div style="padding: 0 20px">
                <el-form-item  label="订单编号:" >
                    <span>{{ listQuery.orderNo }}</span>
                </el-form-item>
                <el-form-item  label="会员编号:" >
                    <span>{{ listQuery.memberNo }}</span>
                </el-form-item>
                <el-form-item  label="单据类型:" >
                    <span>{{ $lt(initDict.orderType, listQuery.orderType) }}</span>
                </el-form-item>
                <el-form-item  label="促销类型:" v-if="listQuery.promType">
                    <span>{{ $lt(initDict.promType, listQuery.promType) }}</span>
                </el-form-item>
                <el-form-item  label="注册时布局:" v-if="listQuery.linkType">
                    <span>{{ $lt(initDict.linkType, listQuery.linkType) }}</span>
                </el-form-item>
                <el-form-item  label="创建时间:">
                    <span>{{ listQuery.createdTime }}</span>
                </el-form-item>
                <el-form-item  label="审核时间:">
                    <span>{{ listQuery.checkTime }}</span>
                </el-form-item>
                <el-form-item  label="状态:">
                    <span>{{ $lt(initDict.status, listQuery.status) }}</span>
                </el-form-item>
            </div>
            <h2 class="h2right">付款方式</h2>
            <hr style="border: 2px dashed #000000; width: 100%;height: 1px;"/>
            <div style="padding: 0 20px">
                <el-form-item  label="商品总金额:">
                    <span>{{ listQuery.totalAmount }}</span>
                </el-form-item>
                <el-form-item  label="消费指数:">
                    <span>{{ listQuery.totalFv }}</span>
                </el-form-item>
                <el-form-item  label="换货积分:" v-if="listQuery.orderType === 27 || listQuery.orderType === 35">
                    <span>{{ listQuery.totalpointA0 }}</span>
                </el-form-item>
                <el-form-item  label="物流费:">
                    <span>{{ listQuery.sendFee }}</span>
                </el-form-item>
                <el-form-item  label="电子账户:"  v-if="listQuery.paymentMethod === 14">
                    <span>{{listQuery.totalAmount - listQuery.totalFc}}</span>
                </el-form-item>
                <el-form-item  label="赠送账户:" v-if="listQuery.paymentMethod === 14">
                    <span>{{ listQuery.totalFc }}</span>
                </el-form-item>
                <el-form-item  label="合计:" v-if="listQuery.paymentMethod !== 14">
                    <span>{{listQuery.totalAmount + listQuery.sendFee}}</span>
                </el-form-item>
                <el-form-item  label="" v-if="listQuery.orderType === 27 || listQuery.orderType === 35">
                    <span>{{listQuery.totalpointA0}}积分</span>
                </el-form-item>
                <el-form-item  label="付款方式:">
                    <span>{{ $lt(initDict.paymentMethod, listQuery.paymentMethod) }}</span>
                </el-form-item>
            </div>
            <h2 class="h2right">商品清单</h2>
            <hr style="border: 2px dashed #000000; width: 100%;height: 1px;"/>
            <el-table :key="list.id" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;margin-top: 20px">
                <el-table-column :label="$t('商品编码')" prop="goodsCode" align="center" />
                <el-table-column :label="$t('商品名称')" prop="goodsName" align="center" />
                <el-table-column :label="$t('单价')" prop="f$" align="center" />
                <el-table-column :label="$t('订购数量')" prop="quantity" align="center" />
                <el-table-column :label="$t('总金额')" prop="totalF$" align="center" />
                <el-table-column :label="$t('是否赠品')" align="center">
                    <template slot-scope="{row}">
                        <span>{{ $lt(initDict.isGift, row.isGive) }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-button class="el-icon-back" type="info" @click="returnbtn">返回</el-button>
            </el-form>
    </div>
</template>
<script>
import { fetchOrderDetail } from '@/api/order/index'
    export default {
        name: "OrderShipView",
        data() {
            return {
                listLoading: false,
                listQuery: {},
                list: [],
                initDict: {
                    isGift: 'po.order.isgive',
                    status: 'po.order.status',
                    paymentMethod: 'po.order.payment.cn',
                    orderType: 'po.allordertype.cn',
                    promType: 'po.order.prom.type',
                    linkType: 'mimember.distype'
                }
            }
        },
        created() {
            if (this.$route.query.id) {
                this.getOrderDetail()
            } else {
                this.returnbtn()
            }
        },
        methods: {
            getOrderDetail() {
                this.listLoading = true
                fetchOrderDetail({ id: this.$route.query.id }).then(res => {
                    this.listQuery = res.data
                    this.list = res.data.details
                    this.listLoading = false
                }).catch(() => {
                    this.listLoading = false
                })
            },
            returnbtn() {
                this.$router.go(-1)
            }
        }
    }
</script>
<style scoped lang="scss">
    .vieweditmember-bussiness {
        span {
            float: left;
            font-size:16px;
            margin-left: 5px;
        }
        .h2right {
            float: left;
            color: #F39F43;
        }
        ::v-deep .el-form-item__label {
            font-size: 18px;
            font-weight: 600;
        }
        ::v-deep .el-form-item {
            margin: 0;
        }
    }

</style>
