<!--
  @文件名:    原生表格
  @file:    OriginalTable
  @author:
  @date:    2021/5/11 0011
  @time:    17:44
  @require:
-->
<template>
  <div ref="OriginalTable" class="OriginalTable">
    <table border="1" style="border-collapse: collapse;border: 1px solid #333;width: 100%;">
      <tr>
        <th v-if="noShow" style="border: 1px solid #333;">No</th>
        <th v-for="(item, index) in columnName" :key="index" style="border: 1px solid #333;">{{ item }}</th>
      </tr>
      <tr v-for="(item2, index2) in tableData" :key="index2">
        <td v-if="noShow" style="border: 1px solid #333;">{{ index2 + 1 }}</td>
        <td v-for="(item3, index3) in columnSet" :key="index3" style="border: 1px solid #333;">
          <span
            v-if="Array.isArray(item3)"
            :class="bolderBigger.includes(item3[1]) ? 'BolderBigger': 'default'"
          >
            {{ $lt(item3[0], item2[item3[1]]) }}
          </span>
          <span
            v-else
            :class="bolderBigger.includes(item3) ? 'BolderBigger': 'default'"
          >
            {{ item2[item3] }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  name: 'OriginalTable',
  directives: {},
  components: {},
  props: {
    'columnName': { type: Array, required: true },
    'columnSet': { type: Array, required: true },
    'tableData': { type: Array, required: true },
    'noShow': { type: Boolean, required: false, default: false },
    'bolderBigger': {
      type: Array, required: false, default: function() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  // 监听属性 类似于data概念
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  created() {

  },
  mounted() {
    // console.log(this.bolderBigger, '挂载完成')
  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
    // console.log(this.styleStr)
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {}
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  text-align: center;
}

.BolderBigger {
  font-size: 18px;
  font-weight: 600;
}
</style>
