<template>
  <div>
    <app-breadcrumb />
    <div class="page-container">
      <div v-if="activeIndex===0">
        <el-form label-width="140px" :rules="rules" size="small" ref="listQuery" :model="listQuery">
          <el-form-item :label="$t('经销商编号')" prop="memberNo">
            <el-input style="max-width: 200px"  v-model.trim="listQuery.memberNo"  clearable :placeholder="$t('')" />
          </el-form-item>
          <el-form-item :label="$t('服务码')" prop="formCode">
            <el-input style="max-width: 200px" v-model.trim="listQuery.formCode" clearable :placeholder="$t('')" />
          </el-form-item>
          <el-form-item>
            <el-button :loading="btnloading" type="primary" icon="el-icon-right" @click="onNext('listQuery')">下一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack('listQuery')">上一步</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeIndex===1">
        <el-form label-width="140px"  size="small" >
          <el-form-item :label="$t('姓名')" >
            {{listQuery2.memberName}}
          </el-form-item>
          <el-form-item :label="$t('省')" >
            {{listQuery2.addrProvince}}
          </el-form-item>
          <el-form-item :label="$t('市')" >
            {{listQuery2.addrCity}}
          </el-form-item>
          <el-form-item :label="$t('区')" >
            {{listQuery2.addrDistrict}}
          </el-form-item>
          <el-form-item :label="$t('地址')" >
            {{listQuery2.storeAddr}}
          </el-form-item>
          <el-form-item :label="$t('证件号')" >
            {{listQuery2.paperNo}}
          </el-form-item>
          <el-form-item :label="$t('手机')" >
            {{listQuery2.mobile}}
          </el-form-item>
          <el-form-item>
            <el-button size="small" :loading="btnloading" type="primary" icon="el-icon-right" @click="onNext1">下一步</el-button>
            <el-button size="small" type="default" icon="el-icon-close" @click="onBack1">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="activeIndex===2">
        <el-form label-width="140px" :rules="rules1" size="small" ref="listQuery1" :model="listQuery1">
          <el-form-item :label="$t('推荐人编号')" prop="recommendNo">
            <el-input style="max-width: 200px" v-model.trim="listQuery1.recommendNo" clearable :placeholder="$t('')" @change="handleNoCheck1"/>
            <span class="memberNoPromptcsss">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNoPrompt1 }}</span>
          </el-form-item>
          <el-form-item :label="$t('安置人编号')" prop="linkNo">
            <el-input style="max-width: 200px" v-model.trim="listQuery1.linkNo" clearable :placeholder="$t('')" @change="handleNoCheck2" />
            <span class="memberNoPromptcsss">&nbsp;&nbsp;&nbsp;&nbsp;{{ memberNoPrompt2 }}</span>
            <p v-show="memberNoPrompt3show" class="memberNoPromptcsss">该网体下已有({{memberNoPrompt3}})个节点人</p>
          </el-form-item>
          <el-form-item>
            <el-button :loading="btnloading" type="primary" icon="el-icon-right" @click="onNext2('listQuery1')">下一步</el-button>
            <el-button type="default" icon="el-icon-close" @click="onBack2('listQuery1')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {memberCheck, saveWxMember} from "../../../api/order/authentication";
import { searchMember, getLinkNumber } from '@/api/agent'
export default {
  name: "Authentication",
  data(){
    return{
      btnloading: false,
      listLoading: false,
      listQuery: {
      },
      rules:{
        memberNo: [{ required: true, message: '请填写经销商编号', trigger: ['change','blue'] }],
        formCode: [{ required: true, message: '请填写服务码', trigger: ['change','blue'] }],
      },
      listQuery2: {
      },
      listQuery1: {
      },
      rules1:{
        recommendNo: [{ required: true, message: '请填写推荐人编号', trigger: ['change','blue'] }],
        linkNo: [{ required: true, message: '请填写安置人编号', trigger: ['change','blue'] }],
      },
      activeIndex:0,
      tableKey: 0,
      list: null,
      total: 0,
      memberNoPrompt1:null,
      memberNoPrompt2:null,
      memberNoPrompt3:null,
      memberNoPrompt3show:null
    }
  },
  watch: {
    "listQuery.memberNo": function (val) {
      this.listQuery.memberNo = val.toUpperCase()
    },
    "listQuery1.recommendNo": function (val) {
      this.listQuery1.recommendNo = val.toUpperCase()
    },
    "listQuery1.linkNo": function (val) {
      this.listQuery1.linkNo = val.toUpperCase()
    }
  },
  methods:{
    handleNoCheck1(val) {
      if (val) {
        searchMember({userCode: val.trim().toUpperCase()}).then(res => {
          this.memberNoPrompt1 = res.data[0].userName
        }).catch(() => {
          this.memberNoPrompt1 = '请输入正确的推荐人编号'
        })
      } else {
        this.memberNoPrompt1 = ''
      }
    },
    handleNoCheck2(val) {
      if (val) {
        searchMember({userCode: val.trim().toUpperCase()}).then(res => {
          this.memberNoPrompt2 = res.data[0].userName
        }).catch(() => {
          this.memberNoPrompt2 = '请输入正确的安置人编号'
        })
        getLinkNumber({userCode: val.trim().toUpperCase()}).then (res => {
          this.memberNoPrompt3show = true
          this.memberNoPrompt3 = res.data
        }).catch(() => {
          this.memberNoPrompt3show = false
        })
      } else {
        this.memberNoPrompt2 = ''
        this.memberNoPrompt3show = false
      }
    },
    onNext(listQuery){
      this.$refs[listQuery].validate((valid) => {
        if (valid) {
          this.btnloading = true
          this.listLoading = true
          memberCheck({...this.listQuery}).then(res=>{
            this.listQuery2 = res.data
            this.listLoading =false
            this.activeIndex = 1
            this.btnloading =false
          }).catch(err=>{
            this.btnloading = false
            this.listLoading =false
            console.error(err)
          })
        } else {
          return false;
        }
      });
    },
    onBack(listQuery) {
      this.$refs[listQuery].resetFields();
      this.$router.go(-1)
    },
    onNext1(){
      this.activeIndex = 2
    },
    onBack1() {
      this.activeIndex = 0
    },
    onNext2(listQuery1){
      this.$refs[listQuery1].validate((valid) => {
        if (valid) {
          this.btnloading = true
          // this.listQuery1.memberNo = this.listQuery.memberNo
          //'memberNo':this.listQuery1.memberNo,'recommendNo':this.listQuery1.recommendNo,'linkNo':this.listQuery1.linkNo
          const obj = {
            'memberNO': this.listQuery.memberNo,
            'recommendNo':this.listQuery1.recommendNo,
            'linkNo':this.listQuery1.linkNo
          }
          saveWxMember(obj).then(res=>{
            console.log(res)
            this.btnloading =false
            this.$router.push({path:'/member/index'})
          }).catch(err=>{
            this.btnloading =false
            console.error(err)
          })
        } else {
          return false;
        }
      });
    },
    onBack2(listQuery1) {
      this.$refs[listQuery1].resetFields();
      this.activeIndex = 1
    },
    toUpperCase(){
      if(this.listQuery.memberNo){
        this.listQuery.memberNo = this.listQuery.memberNo.toUpperCase()
      }
    }
  }
}
</script>

<style scoped>
.memberNoPromptcsss {
  color: red;
}
</style>
