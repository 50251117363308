<template>
  <div>
    <div class="breadcrumb-header"></div>
    <div class="breadcrumb-content">
      <img :src="require('@/assets/images/icon_list.png')" class="header-icon">
      <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in modules" :key="index">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  computed: {
    modules() {
      return this.$route.matched.map(ele => ele.meta.title)
    }
  }
}
</script>

<style lang="scss">
.breadcrumb-header {
  height: 10px;
  background-image: linear-gradient(to right, #F09531, #FFC788);
}
.breadcrumb-content {
  display: flex;
  align-items: center;
  height: 48px;
  margin: 10px 20px;
  padding: 0 10px;
  background-color: #F4F4F4;
  .header-icon {
    width: 32px;
    height: 32px;
  }
  .breadcrumb {
    padding-left: 10px;
    .el-breadcrumb__inner {
      font-size: 14px !important;
      font-weight: bold !important;
      color: #266aad !important;
    }
    .el-breadcrumb__separator {
      font-size: 16px;
    }
  }
}
</style>