import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/index.vue'

Vue.use(VueRouter)

import Account from './module/account'
import Order from './module/order'
import Member from './module/member'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    meta: {
      title: '登录'
    },
    hidden: true
  },
  {
    path: '/pay/return-page',
    component: () => import('@/views/pay/index'),
    meta: {
      title: '支付结果'
    },
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    meta: {
      title: '404 Page Not Found'
    },
    hidden: true
  },
  {
    path: '/forget-password',
    component: () => import('@/views/login/forget-password'),
    meta: {
      title: '忘记密码'
    },
    hidden: true
  },
  {
    path: '/two-password',
    component: () => import('@/views/twopassword/index'),
    name: 'TwoPassword',
    meta: { title: '支付密码设置' },
    hidden: true
  },
  {
    path: '/print',
    component: () => import('@/views/print/index'),
    name: 'Print',
    meta: {
      title: 'print'
    },
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/home/index'),
        name: 'Home',
        meta: { title: '首页' }
      }
    ]
  }
]

export const asyncRoutes = [
  ...Account,
  ...Order,
  ...Member
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
