<!--
  @文件名:    打印
  @file:    Print
  @author:
  @date:    2021/4/18 0018
  @time:    18:56
  @require:
-->
<template>
  <div class="Print" style="background: #fff;width: 100%;height: 100%">
    <div id="Print"></div>
    <el-button class="Btnprint" type="default" size="small" @click="print">打印</el-button>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  name: "Print",
  directives: {},
  components: {},
  props: [],
  data() {
    return {}
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  created() {

  },
  mounted() {
    document.getElementById('Print').innerHTML = sessionStorage.getItem('str')
  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
  methods: {
    print() {
      window.print()
    }
  }
}
</script>
<style scoped>
#Print > div.OriginalTable > table {
  border-collapse: collapse !important;
  border: 1px solid #333;
  width: 100%;
}

div.OriginalTable /deep/ td {
  text-align: center;
  border: 1px solid #333;
}

div.OriginalTable /deep/ .BolderBigger {
  font-size: 18px;
  font-weight: 600;
}
@media print {
  .Btnprint {
    display: none;
  }
}
</style>
