<template>
  <div>
    <app-breadcrumb />
    <div class="page-container recharge">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" size="mini">
          <el-form-item label="申请日期" >
            <!-- 时间起始 -->
            <el-date-picker
                    v-model="listQuery.startTime"
                    :picker-options="this.start_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="开始日期"
            />&nbsp;&nbsp; - &nbsp;&nbsp;
            <!-- 时间结束 -->
            <el-date-picker
                    v-model="listQuery.endTime"
                    :picker-options="this.end_Date"
                    type="date"
                    style="width: 130px;"
                    value-format="yyyy-MM-dd"
                    placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="listQuery.status" placeholder="状态" clearable>
              <el-option v-for="item in statusList" :key="item.code" :label="$lt(initDict.status, item.code)" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="handleApply">我要充值</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="list" v-loading="loading" fit stripe style="width: 100%" size="mini" border>
<!--        <el-table-column label="操作" width="50" align="center">-->
<!--          <template slot-scope="{row}">-->
<!--            <div class="opts">-->
<!--              <el-tooltip v-if="row.status===1" class="item" effect="dark" content="删除" placement="top">-->
<!--                <i class="button el-icon-delete" @click="handleDelete(row)"/>-->
<!--              </el-tooltip>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="充值单号" prop="inputNo" width="80" align="center" />
        <el-table-column label="申请数额" prop="money" width="80" align="center" />
        <el-table-column label="支付金额" prop="money" width="80" align="center" />
        <el-table-column label="充值方式" width="100" align="center">
          <template slot-scope="{row}"><span>{{ $lt(initDict.transType, row.tranType) }}</span></template>
        </el-table-column>
        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="{row}"><span>{{ $lt(initDict.status, row.status) }}</span></template>
        </el-table-column>
<!--        <el-table-column label="备注" prop="remark" align="center" />-->
        <el-table-column label="申请时间" prop="createdTime" align="center" />
        <el-table-column label="入账时间" prop="recheckeTime" align="center" />
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { fetchRecharges, deleteRecharge } from '@/api/account/recharge'
export default {
  name: 'AccountRecharge',
  components: { Pagination },
  data() {
    return {
      loading: false,
      list: [],
      total: 0,
      selectDates: undefined,
      listQuery: {
        page: 1,
        limit: 10,
        status: undefined
      },
      initDict: {
        status: 'fi_ac_input_status_cn',
        transType: 'trans_type_cn'
      }
    }
  },
  computed: {
    statusList() {
      return this.$ll(this.initDict.status)
    }
  },
  mounted() {
  },
  methods: {
    getList() {
      if(!this.listQuery.endTime || !this.listQuery.startTime){
        return this.$message.error('请输入开始时间和结束时间')
      }else{
        this.loading = true
        const fromDate = this.listQuery.startTime
        const toDate = this.listQuery.endTime
        fetchRecharges({ ...this.listQuery, fromDate, toDate }).then(res => {
          this.list = res.data.records
          this.total = +res.data.total || 0
          this.loading = false
        }).catch(err => {
          console.warn(err)
          this.loading = false
        })
      }
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    // convertToLateDate(){
    //  let tiem = parseInt(new Date().getTime())
    // },
    handleApply() {
      this.$router.push('/account/recharge-apply')
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该申请, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteRecharge({ id: row.id }).then(() => {
          this.$message.success('充值记录已删除')
          const index = this.list.findIndex(ele => ele.id === row.id)
          this.list.splice(index, 1)
        })
      })
    }
  }
}
</script>

<style>

</style>
