<!--
  @文件名:    支付密码
  @file:    TwoPassword
  @author:  LX
  @date:    2021/9/7
  @time:    9:36
  @require:
-->
<template>
    <div>
        <app-breadcrumb/>
        <div class="page-container twopassword">
            <div class="filter">
                <h3 style="color: #ff5a4d">检测到您当前的账户未设置支付密码, 请及时设置以免造成不必要的损失!</h3>
                <el-form :model="listQuery" ref="passwordForm" :rules="rules" label-width="120px" size="mini">
                    <el-form-item label="支付密码:" prop="newPassword">
                        <el-input v-model.trim="listQuery.newPassword" show-password style="width:170px;" placeholder="请设置支付密码"/>
                        <span style="color: crimson;margin-left: 10px">请输入6-16位</span>
                    </el-form-item>
                    <el-form-item label="重复支付密码:" prop="complexPassword">
                        <el-input v-model.trim="listQuery.complexPassword" show-password style="width:170px;" placeholder="请重复输入支付密码"/>
                        <span style="color: crimson;margin-left: 10px">请输入6-16位</span>
                    </el-form-item>
                    <el-form-item label="短信验证码:" prop="smsCode">
                        <div class="smscode">
                            <el-input v-model.trim="listQuery.smsCode" placeholder="请填写短信验证码"  style="width:170px;" />
                            <el-button type="defualt" :loading="sendLoading" :disabled="send" @click="sendSms">{{ send ? `${time}秒后重发` : '发送验证码' }}</el-button>
                        </div>
                    </el-form-item>
                    <el-row style="margin-left: 12px">
                        <el-button
                            type="default"
                            icon="el-icon-close"
                            @click="cancelBtn"
                        >
                            取消
                        </el-button>
                        <el-button
                            :loading="btnloading"
                            type="primary"
                            icon="el-icon-check"
                            @click="saveBtn"
                        >
                            保存
                        </el-button>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { paymentPasswordSave } from '@/api/home'
import { memberFirstLoginCode } from "@/api/auth"
export default {
name: 'TwoPassword',
  data() {
      const validatePass = (rule, value, callback) => {
          if (!value || !value.length) {
              callback(new Error('请填写新密码'))
          } else if (value.length < 6 || value.length > 16) {
              callback(new Error('密码长度为6~16位'))
          } else {
              callback()
          }
      }
      const validatePass2 = (rule, value, callback) => {
          if (!value || !value.length) {
              callback(new Error('请再次填写新密码'))
          } else if (value.length < 6 || value.length > 16) {
              callback(new Error('密码长度为6~16位'))
          } else if (value !== this.listQuery.newPassword) {
              callback(new Error('两次输入密码不一致'))
          } else {
              callback()
          }
      }
      const validateSmsCode = (rule, value, callback) => {
          if (!value || !value.length) {
              callback(new Error('请填写短信验证码'))
          } else if (value.length !== 6) {
              callback(new Error('请输入6位的验证码'))
          } else {
              callback()
          }
      }
    return {
        model:{},
        listQuery: {
            page: 1,
            limit: 10,
            status: undefined
        },
        time: 60,
        btnloading: false,
        send: false,
        sendLoading: false,
        complexPassword: '', // 重复密码
        rules: {
            newPassword: [{ required: true, validator: validatePass, trigger: 'blur' }],
            complexPassword: [{ required: true, validator: validatePass2, trigger: 'blur' }],
            smsCode: [{ required: true, validator: validateSmsCode, trigger: 'blur' }],
        },
    }
  },
    methods: {
    sendSms() {
    this.sendLoading = true
      memberFirstLoginCode({ userCode: '' }).then(() => {
        this.sendLoading = false
        this.send = true
        const timer = setInterval(() => {
            this.time--
            if (this.time <= 0) {
                this.send = false
                this.time = 60
                clearInterval(timer)
            }
        }, 1000)
    }).catch(err => {
        console.warn(err)
        this.sendLoading = false
    })
},
    saveBtn() {
        this.$refs['passwordForm'].validate((valid) => {
            if (valid) {
                this.btnLoading = true
                this.model = {
                    newPassword: this.listQuery.newPassword,
                    smsCode: this.listQuery.smsCode
                }
                paymentPasswordSave(this.model).then(() => {
                    this.$message.success('保存成功')
                    this.btnLoading = false
                    this.$router.go(-1)
                }).catch(err => {
                    this.btnLoading = false
                    console.error(err)
                })
            }
        })
    },
    cancelBtn() {
        this.$router.push({path: '/index'})
    }
  }
}
</script>
<style scoped lang="scss">
 .twopassword{
    .smscode {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-button {
            margin-left: 10px;
            width: 120px;
        }
    }
}
</style>
