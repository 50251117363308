<!--CN-N000098-24
健康素养明星套组
中国区套组 买这个套组送
CN-F016-01-00苣多糖压片糖果或者CN-F017-01-00髓多肽压片糖果
二选一-->

<template>
  <div>
    <p>购买套组健康素养明星套组（CN-N000098-24）每一套可选择一个赠品 </p>
    <el-table
        border
        :data="list"
        header-align="center"
        style="width: 100%">
      <el-table-column  prop="code" align="center" :label="$t('BiGoods.goodsCode')"></el-table-column>
      <el-table-column  prop="name" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column  prop="pv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column  prop="proPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column label="购买数量" prop="quantity" width="200" align="center">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="CN_N000098_24_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "TZCN19508",
  data(){
    return{
      tableKey:0,
      giftlist:[],
      list:[
        {
          name:'苣多糖压片糖果',
          code:'CN-F016-01-00',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'是',
          id:'-125'
        },{
          name:'髓多肽压片糖果',
          code:'CN-F017-01-00',
          quantity: 0,
          point:0,
          price:0,
          proPrice:0,
          pv:0,
          isGive:1,
          gift:'是',
          id:'-126'
        }
      ],
    }
  },
  props: {
    CNN00009824_num: {
      type: Number,
      default: 0
    }
  },
  watch:{
    'CNN00009824_num': function (val) {
      if(val===0){
        this.list[0].quantity = 0
        this.list[1].quantity = 0
      }
    },
  },
  methods:{
    CN_N000098_24_Change(){
      let num = 0
      this.list.forEach(v=>{
        num += v.quantity
      })
      if(num !== this.CNN00009824_num ){
        this.$message.error('所选赠品数量与购买套组(CN-N000098-24)数量不符')
      }
      this.$emit('CN_N000098_24_Change', this.list)
      // console.log(this.HuXi_19508_list)
    }
  }
}
</script>

<style scoped>

</style>
