import { asyncRoutes, constantRoutes } from '@/router'

function hasPermission(modules, route) {
  if (!route.children) {
    return modules.some(module => module.name === route.name)
  } else {
    return modules.some(module => route.children.some(child => module.name === child.name))
  }
}

export function filterAsyncRoutes(routes, modules) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(modules, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, modules)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, modules) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, modules).concat([{ path: '*', redirect: '/404', hidden: true }])
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
