<template>
  <div class="app-wrapper">
    <navbar />
    <div class="main-container">
      <app-main />
    </div>
    <app-footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import AppMain from './components/AppMain'
import AppFooter from './components/Footer'
export default {
  name: 'Layout',
  components: { Navbar, AppMain, AppFooter }
}
</script>

<style>

</style>